/* eslint-disable @typescript-eslint/init-declarations */

import { cloneDeep } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProxyResource } from "~/client/resources";
import { Permission } from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, Sensitive } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ParseHelper from "~/utils/ParseHelper";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import InfrastructureLayout, { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

interface ProxyProps extends RouteComponentProps<ProxyRouteParams> {
    create?: boolean;
}

interface ProxyPropsInner extends ProxyProps {
    initialData: InitialData;
}

interface ProxyRouteParams {
    proxyId: string;
}

interface ProxyState extends FormBaseComponentState<ProxyResource> {
    deleted: boolean;
    newId: string | null;
}

interface InitialData {
    model: ProxyResource;
}

const Title = "Proxies";
const ProxyLayoutFormPage = FormPage<InitialData>();
const ProxyLayout: React.FC<ProxyProps> = (props: ProxyProps) => {
    return (
        <ProxyLayoutFormPage
            title={Title}
            load={async () => {
                let model: ProxyResource;

                if (props.create) {
                    model = {
                        Id: "",
                        SpaceId: "",
                        Links: { "": "" },
                        ProxyType: "HTTP",
                        Name: "",
                        Host: "",
                        Port: 80,
                        Username: "",
                        Password: {
                            HasValue: false,
                            NewValue: undefined,
                        },
                    };
                } else {
                    model = await repository.Proxies.get(props.match.params.proxyId);
                }

                return { model };
            }}
            renderWhenLoaded={(data) => <ProxyLayoutInner initialData={data} {...props} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class ProxyLayoutInner extends FormBaseComponent<ProxyPropsInner, ProxyState, ProxyResource> {
    constructor(props: ProxyPropsInner) {
        super(props);
        this.state = {
            model: props.initialData.model,
            cleanModel: cloneDeep(props.initialData.model),
            deleted: false,
            newId: null,
        };
    }

    render() {
        const title = this.props.create ? "Create Proxy" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("proxy", this.handleDeleteConfirm, { permission: Permission.ProxyDelete }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const saveText: string = this.state.newId ? "Proxy created" : "Proxy details updated";
        return (
            <InfrastructureLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Proxies"}
                    breadcrumbPath={routeLinks.infrastructure.proxies.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.ProxyCreate : Permission.ProxyEdit }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.proxies.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.proxy(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your proxy")}
                                help="A short, memorable, unique name for this proxy. Example: DMZ Proxy."
                            >
                                <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Proxy name" validate={required("Please enter a proxy name")} error={this.getFieldError("Name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Host"
                                title="Proxy Host"
                                summary={this.state.model.Host ? Summary.summary(this.state.model.Host) : Summary.placeholder("Please enter the DNS hostname of the proxy server")}
                                help="DNS hostname of the proxy server."
                            >
                                <Text value={this.state.model.Host} onChange={(Host) => this.setModelState({ Host })} label="Proxy host" validate={required("Please enter a hostname")} error={this.getFieldError("Host")} />
                                <div>
                                    Examples:
                                    <ul>
                                        <li>
                                            <code>10.0.0.1</code>
                                        </li>
                                        <li>
                                            <code>web01.local</code>
                                        </li>
                                        <li>
                                            <code>web01.mynetwork.com</code>
                                        </li>
                                    </ul>
                                </div>
                                <Callout title="Note" type={CalloutType.Information}>
                                    Octopus only supports the use of HTTP proxies, there is currently no support for SOCKS proxies.
                                </Callout>
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Port"
                                title="Proxy Port"
                                summary={this.state.model.Port ? Summary.summary(this.state.model.Port) : Summary.placeholder("Please enter a port for your proxy")}
                                help="The TCP port of the proxy server."
                            >
                                <Text
                                    value={this.state.model.Port ? this.state.model.Port.toString() : ""}
                                    onChange={(x) => this.setModelState({ Port: ParseHelper.safeParseInt(x) })}
                                    label="Proxy port"
                                    validate={required("Please enter a port number")}
                                    error={this.getFieldError("Port")}
                                    type="number"
                                />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Credentials"
                                title="Credentials"
                                summary={
                                    this.state.model.Username
                                        ? Summary.summary(`Credentials have been entered; username is ${this.state.model.Username}`)
                                        : Summary.placeholder("Add authentication details if your proxy server requires authentication")
                                }
                                help="Leave blank if your proxy server does not require authentication."
                            >
                                <Text value={this.state.model.Username} onChange={(Username) => this.setModelState({ Username })} label="Proxy login" />
                                <Sensitive value={this.state.model.Password} onChange={(Password) => this.setModelState({ Password })} label="Proxy password" />
                            </ExpandableFormSection>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = !this.state.model.Id;
            const result = await repository.Proxies.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.Proxies.del(this.state.model);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
}

export default ProxyLayout;
