import type { ActionProperties } from "~/client/resources/actionProperties";
import type { ActionTemplateParameterResource } from "~/client/resources/actionTemplateParameterResource";
import type { ResourceWithId, Resource } from "~/client/resources/resource";
import { FeedType } from "./feedResource";
import type { NamedResource } from "./namedResource";
import type { PackageReference } from "./packageReference";
import type { ProcessType } from "./processType";
import type { StepPackageInputs } from "./stepPackageInputs";

export interface ActionTemplateResource extends ResourceWithId {
    Name: string;
    Description: string;
    ActionType: string;
    Version: number;
    CommunityActionTemplateId: string;
    Properties: ActionProperties;
    Parameters: ActionTemplateParameterResource[];
    Packages: PackageReference[];
    Inputs?: StepPackageInputs;
    StepPackageVersion?: string;
}

export interface ActionUpdateResource extends Resource {
    Version: number;
    DefaultPropertyValues: ActionProperties;
    Overrides: ActionProperties;
    ActionsToUpdate: ActionsUpdateProcessResource[];
}

export interface ActionsUpdateProcessResource extends Resource {
    ProcessType: ProcessType;
    ProcessId: string;
    ActionIds: string[];
    GitRef?: string;
}

export interface ActionTemplateUsageResource extends ResourceWithId {
    ActionTemplateId: string;
    DeploymentProcessId: string;
    ProcessType: ProcessType;
    ProcessId: string;
    RunbookId: string;
    ActionId: string;
    RunbookName: string;
    ActionName: string;
    StepId: string;
    StepName: string;
    ProjectId: string;
    ProjectName: string;
    ProjectSlug: string;
    Version: string;
    Release?: string;
    Branch?: string;
}

export interface ActionUpdateResultResource {
    Id: string;
    Outcome: ActionUpdateOutcome;
    NamesOfNewParametersMissingDefaultValue: string[];
    ManualMergeRequiredReasonsByPropertyName: {};
    RemovedPackageUsages: ActionUpdateRemovedPackageUsage[];
}

export enum ActionUpdateOutcome {
    Success = "Success",
    ManualMergeRequired = "ManualMergeRequired",
    DefaultParamterValueMissing = "DefaultParamterValueMissing",
    RemovedPackageInUse = "RemovedPackageInUse",
}

export interface ActionUpdateRemovedPackageUsage {
    PackageReference: string;
    UsedBy: ActionUpdatePackageUsedBy;
    UsedById: string;
    UsedByName: string;
}

export enum ActionUpdatePackageUsedBy {
    ProjectVersionStrategy = "ProjectVersionStrategy",
    ProjectReleaseCreationStrategy = "ProjectReleaseCreationStrategy",
    ChannelRule = "ChannelRule",
}

export interface ActionTemplateCategoryResource extends NamedResource {
    DisplayOrder: number;
}

export enum ActionHandlerCategory {
    Script = "Script",
    Package = "Package",
    Azure = "Azure",
    Aws = "Aws",
    GoogleCloud = "Google",
    Certificate = "Certificate",
    Docker = "Docker",
    JavaAppServer = "JavaAppServer",
    Kubernetes = "Kubernetes",
    Terraform = "Terraform",
    WindowsServer = "WindowsServer",
    Atlassian = "Atlassian",
    Other = "Other",
    BuiltInStep = "BuiltInStep",
    Community = "Community",
    CommunitySubCategory = "CommunitySubCategory",
    StepTemplate = "StepTemplate",
}

export function getFeedTypesForPackageParameter(actionType: string, packageReference: PackageReference): FeedType[] {
    return packageReference?.Properties?.Purpose == "DockerImageReference" ? getFeedTypesForDockerImageReferences() : getFeedTypesForActionType(actionType);
}

function getFeedTypesForDockerImageReferences(): FeedType[] {
    return [FeedType.Docker, FeedType.AwsElasticContainerRegistry];
}

function getFeedTypesForActionType(actionType: string): FeedType[] {
    switch (actionType) {
        case "Octopus.DockerRun":
            return getFeedTypesForDockerImageReferences();
        case "Octopus.HelmChartUpgrade":
            return [FeedType.Helm];
        case "Octopus.JavaArchive":
        case "Octopus.TomcatDeploy":
        case "Octopus.WildFlyDeploy":
            return [FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven];
        case "Octopus.TentaclePackage":
        case "Octopus.TransferPackage":
            return [FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.GitHub];
    }

    return [];
}
