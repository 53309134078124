/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AuditArchiveResource } from "~/client/resources/auditArchiveResource";
import type { Client } from "../client";
import { BasicRepository } from "./basicRepository";
import type { ListArgs } from "./basicRepository";

type AuditArchiveListArgs = ListArgs;

export class AuditArchiveRepository extends BasicRepository<AuditArchiveResource, AuditArchiveResource, AuditArchiveListArgs> {
    constructor(client: Client) {
        super("ArchivedEventFiles", client);
    }
}
