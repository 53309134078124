import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import type { ProjectResource } from "~/client/resources";
import { toGitBranch } from "~/client/resources/versionControlledResource";
import type { GitRef } from "~/client/resources/versionControlledResource";
import type { SpecifiedGitRef } from "../components/ProjectsRoutes/BranchSpecifier";

function lastAccessedGitRefStore() {
    const LocalStorageKey = "octoLastAccessedGitRef";

    const buildKey = (projectIdOrSlug: string) => `${LocalStorageKey}-${projectIdOrSlug}`;

    const save = async (project: ProjectResource, gitRef: SpecifiedGitRef): Promise<void> => {
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Can store GitRef for Git Projects");
        }

        localStorage.setItem(buildKey(project.Id), gitRef);
    };

    const get = (project: ProjectResource): GitRef => {
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Can only retrieve stored GitRef for Git Projects");
        }

        const key = buildKey(project.Id);
        const storedGitRef = localStorage.getItem(key) ?? toGitBranch(project.PersistenceSettings.DefaultBranch);
        return storedGitRef;
    };

    return {
        save,
        get,
    };
}

const lastAccessedGitRef = lastAccessedGitRefStore();

export { lastAccessedGitRef };
