/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from "classnames";
import { debounce } from "lodash";
import * as React from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import { uploadLogo } from "~/client/repositories/logoUpload";
import type { ResourceWithId } from "~/client/resources";
import { client } from "~/clientInstance";
import InternalNavLink from "~/components/Navigation/InternalNavLink/InternalNavLink";
import type { LogoEditorSettings } from "~/components/form/LogoEditor/LogoEditor";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import styles from "./style.module.less";

interface LogoProps {
    url: string;
    size?: string;
    isDisabled?: boolean;
    editLink?: string;
    className?: string;
}

class Logo extends React.Component<LogoProps> {
    private static defaultProps: Partial<LogoProps> = {
        size: "3.1rem",
    };

    // This is static because we want it to be debounced so that when we have lots of Logos calling it we are not rechecking it for every single one
    private static recheckVisibility = debounce(() => {
        forceCheck();
    }, 800);

    // We need to force a recheck because LazyLoad only rechecks on scrolling, but because our screens support filtering, that bypasses the scrolling event
    componentDidUpdate() {
        Logo.recheckVisibility();
    }

    render() {
        if (!this.props.url) {
            return <div />;
        }
        const resolvedUrl = this.props.url.startsWith("~") || this.props.url.startsWith("/") ? client.resolve(this.props.url) : this.props.url;
        const logoImage = (
            <LazyLoad once={true} height={this.props.size}>
                <img
                    src={resolvedUrl}
                    style={{
                        maxWidth: this.props.size,
                        maxHeight: this.props.size,
                    }}
                    className={this.props.isDisabled ? styles.disabled : null}
                    alt="Logo"
                />
            </LazyLoad>
        );

        return (
            <div className={classNames(styles.logo, this.props.className)} style={{ width: this.props.size, height: this.props.size, minWidth: this.props.size }}>
                {this.props.editLink ? (
                    <ToolTip content="Edit logo" position="right" style={{ width: "100%", height: "100%" }}>
                        <InternalNavLink to={this.props.editLink} className={styles.logoImageLink}>
                            {logoImage}
                        </InternalNavLink>
                    </ToolTip>
                ) : (
                    logoImage
                )}
            </div>
        );
    }
}

async function saveLogo(model: ResourceWithId, logo: LogoEditorSettings) {
    // Important: Don't change the order of the calls
    // We upload the logo first so that when we do the model save we get back a new url for logo
    if (logo.file) {
        return uploadLogo(model, logo.file);
    } else if (logo.reset) {
        return uploadLogo(model, null!);
    }
}

export { saveLogo };
export default Logo;
