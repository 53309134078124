import { Theme as DesignSystemTheme } from "@octopusdeploy/design-system-components";
import React from "react";
import { useInitializeThemeInStorageEffect, useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import Theme from "./Theme";

type ThemeFromLocalStorageProps = React.PropsWithChildren<{}>;

export function ThemeFromLocalStorage({ children }: ThemeFromLocalStorageProps) {
    const palette = useThemePaletteType();
    useInitializeThemeInStorageEffect();

    return (
        <Theme palette={palette}>
            <DesignSystemTheme themeName={palette}>{children}</DesignSystemTheme>
        </Theme>
    );
}
