import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import type { Client } from "~/client/client";
import { importFromDynamicExpression } from "~/client/importFromDynamicExpression";
import type { MigrateStepPackageInputsResponse, StepPackage, StepPackageDetailsResponse, StepUI } from "~/client/resources/stepPackage";
import type { StepPackageInputs } from "~/client/resources/stepPackageInputs";

export class StepPackageRepository {
    constructor(private readonly client: Client) {}

    async getStepPackage(stepPackageId: string): Promise<StepPackage> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/${stepPackageId}/latest-version`);
        if (!response) {
            throw new Error(`No step package was found with id ${stepPackageId}.`);
        }

        return await this.resolveStepPackageDetails(response);
    }

    async getStepPackageByIdAndVersion(stepPackageId: string, stepPackageVersion: string): Promise<StepPackage> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/${stepPackageId}/${stepPackageVersion}`);
        if (!response) {
            throw new Error(`No step package was found with id ${stepPackageId} and version ${stepPackageVersion}.`);
        }

        return await this.resolveStepPackageDetails(response);
    }

    async migrateStepPackageInputs(stepPackageId: string, stepPackageVersion: string, inputs: StepPackageInputs, targetMajorVersion: number) {
        return await this.client.post<MigrateStepPackageInputsResponse>(`/api/steps/${stepPackageId}/${stepPackageVersion}/migrate-inputs`, { inputs, targetMajorVersion });
    }

    async resolveStepPackageDetails(stepPackage: StepPackageDetailsResponse): Promise<StepPackage> {
        const schema = await this.client.get<InputJsonSchema>(stepPackage.Links.Schema);
        const stepUI = await this.resolveStepPackageUI(stepPackage);

        return {
            name: stepPackage.Name,
            description: stepPackage.Description,
            version: stepPackage.Version,
            stepUI: stepUI,
            schema: schema,
            prerelease: stepPackage.Prerelease,
            releaseNotesUrl: stepPackage.ReleaseNotesUrl,
            executionLocation: stepPackage.ExecutionLocation,
            requiresTargetRole: stepPackage.RequiresTargetRole,
            targetDiscoveryCloudConnectionTypes: stepPackage.TargetDiscoveryCloudConnectionTypes,
        };
    }

    async resolveStepPackageUI(stepPackage: StepPackageDetailsResponse): Promise<unknown> {
        const stepUIModule = await importFromDynamicExpression(stepPackage.Links.StepUI);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const convertedStepUIModule = stepUIModule as { default: StepUI };
        return convertedStepUIModule.default;
    }
}
