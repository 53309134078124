import type { OctopusTheme as DesignSystemTheme } from "@octopusdeploy/portal-design-system-tokens";
import { darkTheme, lightTheme } from "@octopusdeploy/portal-design-system-tokens";
import { baseColors } from "~/theme/baseColors";

export interface OctopusTheme extends DesignSystemTheme {
    // Constants
    whiteConstant: string;
    blackConstant: string;
    cyanConstant: string;
    transparent: string;

    //Shadows - these colours don't change between themes
    shadow40: string;
    shadow60: string;
    shadow20: string;
    shadow12: string;
    shadow14: string;
    shadow25: string;
    shadow22: string;
    shadow34: string;

    // Paper
    paper0: string;
    paper1: string;
    paper2: string;
    paper3: string;

    // Text
    primaryText: string;
    secondaryText: string;
    ternaryText: string;
    highlightText: string;
    highlightTextBackground: string;

    // Text links
    linkText: string;
    linkTextHover: string;
    sideMenuHover: string;
    sideMenuNestedText: string;
    sidebarMenuSelectedText: string;

    // Nav
    navBackground: string;
    navItemHover: string;
    navItemActive: string;
    navText: string;
    navTextHover: string;
    navTextActive: string;
    spaceMenuBackground: string;
    spaceMenuText: string;

    // Tabs
    tabActiveIndicator: string;

    // Buttons
    primaryButtonBackground: string;
    primaryButtonText: string;

    secondaryButtonBackground: string;
    secondaryButtonText: string;

    ternaryButton: string;
    ternaryButtonHover: string;
    categoryButtonBackground: string;

    disabledButtonBorder: string;
    disabledButtonBackground: string;
    disabledButtonText: string;

    createReleaseBackground: string;
    createReleaseText: string;

    deleteButtonText: string;
    deleteButtonBackground: string;

    cancelButtonBackground: string;
    cancelButtonText: string;

    subtleButtonBackground: string;
    subtleButtonBorder: string;

    // Paging
    pagingButtonBackground: string;
    pagingButtonBackgroundHover: string;
    pagingButtonBackgroundActive: string;

    // Dashboard
    tenantCount: string;

    // Section
    SectionBodyText: string;
    groupHeaderBackground: string;
    groupHeader: string;
    statusBarBackground: string;
    statusBarItemBackground: string;

    // Backgrounds & dividers
    primaryBackground: string;
    disabledBackground: string;
    secondaryBackground: string;
    ternaryBackground: string;
    hover: string;
    divider: string;
    secondaryDivider: string;
    logoBackground: string;
    stepLogoBackground: string;
    parentStepBackground: string;
    loadingIndicator: string;
    loadingIndicatorBackground: string;
    userOnboardingHelpPanelBackground: string;

    // Cards
    cardBackground: string;
    cardHover: string;
    cardFooterBuiltIn: string;
    cardFooterInstalled: string;
    cardFooterCommunity: string;

    // Form
    focus: string;
    selectBorder: string;

    // Chips
    chipBackground: string;
    chipHighlight: string;
    chipText: string;
    chipIcon: string;
    chipDeleteButton: string;
    avatarBackground: string;

    // Code Editor hints
    hintHighlight: string;
    hintActive: string;
    placeholder: string;
    placeholderActive: string;

    // Icon Editor
    iconEditorIconHover: string;
    iconEditorColorPaletteInnerBorder: string;
    iconEditorColorPaletteHover: string;
    iconEditorColorPaletteSelected: string;

    // Callouts
    info: string;
    infoConstant: string;
    infoBackground: string;
    infoHeaderText: string;
    infoText: string;
    infoHighlight: string;
    infoBorder: string;

    success: string;
    successConstant: string;
    successBackground: string;
    successTaskLogBackground: string;
    successHeaderText: string;
    successText: string;
    successHighlight: string;
    successBorder: string;

    danger: string;
    dangerConstant: string;
    dangerBackground: string;
    dangerTaskLogBackground: string;
    dangerHeaderText: string;
    dangerText: string;
    dangerHighlight: string;
    dangerBorder: string;

    alert: string;
    alertBackground: string;
    alertConstant: string;
    alertHeaderText: string;
    alertText: string;
    alertHighlight: string;
    alertBorder: string;

    sunshineText: string;

    darkBackground: string;
    darkText: string;

    auditHighlight: string;
    codeHighlight: string;
    codeText: string;
    codeTextBright: string;

    featureText: string;
    featureBackground: string;
    featureBorder: string;

    //difs
    diffAddedBackground: string;
    diffWordAddedBackground: string;
    diffRemovedBackground: string;
    diffWordRemovedBackground: string;
    diffInfoBackground: string;
    diffHighlight: string;

    // Form validation
    errorText: string;

    // icons
    iconNavy: string;
    iconDark: string;
    iconLight: string;
    iconNeutral: string;

    // Tooltip
    tooltipText: string;
    tooltipBackground: string;

    // Primary color
    mainBackground: string;
    mainText: string;

    // Will be superceeded
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryDarkest: string;
    primaryLightest: string;
    secondaryDark: string;

    // Images
    imgNavy: string;
    imgCyan: string;
    imgCyan50: string;
    imgWhite: string;
    imgBlueGrey: string;
    imgLightGrey: string;
    imgGrey: string;
    imgLight: string;
    imgDark: string;
    imgGreen: string;
    imgDanger: string;
}

export const LightTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    whiteConstant: baseColors.white,
    blackConstant: baseColors.black,
    cyanConstant: baseColors.cyan,
    transparent: baseColors.transparent,

    //Shadows - these colours don't change between themes
    shadow60: baseColors.shadow60,
    shadow40: baseColors.shadow40,
    shadow34: baseColors.shadow34,
    shadow25: baseColors.shadow25,
    shadow22: baseColors.shadow22,
    shadow20: baseColors.shadow20,
    shadow14: baseColors.shadow14,
    shadow12: baseColors.shadow12,

    paper0: baseColors.white,
    paper1: baseColors.white, // Paper background
    paper2: baseColors.white, // Card background
    paper3: baseColors.white, // Card hover

    // Text
    primaryText: baseColors.grey70,
    secondaryText: baseColors.grey50,
    ternaryText: baseColors.grey60,
    highlightText: baseColors.highlightText,
    highlightTextBackground: baseColors.highlightTextBackground,

    // Text links
    linkText: baseColors.cyan,
    linkTextHover: baseColors.cyan50,
    sideMenuHover: baseColors.white,
    sideMenuNestedText: baseColors.grey60,
    sidebarMenuSelectedText: baseColors.cyan,

    // Nav
    navBackground: baseColors.cyan,
    navItemHover: baseColors.cyan30,
    navItemActive: baseColors.cyan50,
    navText: baseColors.white,
    navTextHover: baseColors.white,
    navTextActive: baseColors.white,
    spaceMenuBackground: baseColors.legacyNavy,
    spaceMenuText: baseColors.white,

    // Tabs
    tabActiveIndicator: baseColors.cyan,

    // Buttons
    primaryButtonBackground: baseColors.legacyGreen,
    primaryButtonText: baseColors.white,

    secondaryButtonBackground: baseColors.cyan20,
    secondaryButtonText: baseColors.cyan,

    ternaryButton: baseColors.cyan,
    ternaryButtonHover: baseColors.cyan50,
    categoryButtonBackground: baseColors.cyan,

    disabledButtonBorder: baseColors.grey30,
    disabledButtonBackground: baseColors.grey20,
    disabledButtonText: baseColors.grey50,

    createReleaseBackground: baseColors.legacyNavy,
    createReleaseText: baseColors.white,

    deleteButtonText: baseColors.white,
    deleteButtonBackground: baseColors.legacyRed,

    cancelButtonBackground: baseColors.legacyOrange,
    cancelButtonText: baseColors.white,

    subtleButtonBackground: baseColors.cyan10,
    subtleButtonBorder: baseColors.cyan20,

    // Paging
    pagingButtonBackground: baseColors.grey30,
    pagingButtonBackgroundHover: baseColors.grey50,
    pagingButtonBackgroundActive: baseColors.grey50,

    // Dashboard
    tenantCount: baseColors.white,

    // Section
    SectionBodyText: baseColors.grey70,
    groupHeaderBackground: baseColors.grey20,
    groupHeader: baseColors.grey70,
    statusBarBackground: "#ECF0F3",
    statusBarItemBackground: baseColors.white,

    // Backgrounds & dividers
    primaryBackground: baseColors.grey10,
    disabledBackground: baseColors.grey20,
    secondaryBackground: baseColors.grey20,
    ternaryBackground: baseColors.grey20,
    hover: baseColors.grey20,
    divider: baseColors.grey40,
    secondaryDivider: baseColors.grey30,
    logoBackground: baseColors.transparent,
    stepLogoBackground: baseColors.white,
    parentStepBackground: baseColors.cyan50,
    loadingIndicator: baseColors.cyan30,
    loadingIndicatorBackground: baseColors.grey30,
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",

    // Cards
    cardBackground: baseColors.white,
    cardHover: "rgba(158, 158, 158, 0.1)",
    cardFooterBuiltIn: baseColors.cyan,
    cardFooterInstalled: baseColors.grey40,
    cardFooterCommunity: baseColors.white,

    // Form
    focus: baseColors.cyan,
    selectBorder: baseColors.grey50,

    // Chips
    chipBackground: baseColors.grey30,
    chipHighlight: baseColors.cyan70,
    chipText: baseColors.grey70,
    chipIcon: baseColors.grey70,
    chipDeleteButton: baseColors.grey50,
    avatarBackground: baseColors.grey40,

    // Code Editor hints
    hintHighlight: baseColors.legacyBlue,
    hintActive: baseColors.cyan30,
    placeholder: baseColors.black,
    placeholderActive: baseColors.black,

    // Icon Editor
    iconEditorIconHover: baseColors.grey10,
    iconEditorColorPaletteInnerBorder: baseColors.white,
    iconEditorColorPaletteHover: baseColors.grey30,
    iconEditorColorPaletteSelected: baseColors.grey50,

    // Callouts
    info: baseColors.legacyBlue,
    infoConstant: baseColors.legacyBlue,
    infoBackground: baseColors.blue10,
    infoBorder: baseColors.legacyBlue,
    infoHeaderText: baseColors.legacyBlue,
    infoText: baseColors.legacyBlue,
    infoHighlight: baseColors.blue10,

    success: baseColors.legacyGreen,
    successConstant: baseColors.legacyGreen,
    successBackground: baseColors.green10,
    successTaskLogBackground: baseColors.green10,
    successBorder: baseColors.legacyGreen,
    successHeaderText: baseColors.legacyGreen,
    successText: baseColors.legacyGreen,
    successHighlight: baseColors.green10,

    danger: baseColors.legacyRed,
    dangerConstant: baseColors.legacyRed,
    dangerBackground: baseColors.red10,
    dangerTaskLogBackground: baseColors.red10,
    dangerBorder: baseColors.legacyRed,
    dangerHeaderText: baseColors.legacyRed,
    dangerText: baseColors.legacyRed,
    dangerHighlight: baseColors.red10,

    alert: baseColors.legacyOrange,
    alertBackground: baseColors.orange10,
    alertBorder: baseColors.legacyOrange,
    alertConstant: baseColors.legacyOrange,
    alertHeaderText: baseColors.legacyOrange,
    alertText: baseColors.legacyOrange,
    alertHighlight: baseColors.orange10,

    sunshineText: baseColors.legacyYellow,

    darkBackground: baseColors.grey70,
    darkText: baseColors.white,

    auditHighlight: baseColors.grey20,
    codeHighlight: baseColors.purple10,
    codeText: baseColors.legacyPurple,
    codeTextBright: baseColors.legacyPurple,

    featureText: baseColors.cyan,
    featureBackground: baseColors.cyan20,
    featureBorder: baseColors.transparent,

    //Diffs
    diffAddedBackground: baseColors.diffAddedBackground,
    diffWordAddedBackground: baseColors.diffWordAddedBackground,
    diffRemovedBackground: baseColors.diffRemovedBackground,
    diffWordRemovedBackground: baseColors.diffWordRemovedBackground,
    diffInfoBackground: baseColors.diffInfoBackground,
    diffHighlight: baseColors.grey20,

    // Form validation
    errorText: baseColors.red20,

    // icons
    iconNavy: baseColors.legacyNavy,
    iconDark: baseColors.legacyNavy,
    iconLight: baseColors.white,
    iconNeutral: baseColors.grey60,

    // Tooltip
    tooltipText: baseColors.white,
    tooltipBackground: baseColors.grey70,

    // Primary color
    mainBackground: baseColors.cyan,
    mainText: baseColors.cyan,

    // Will be superceeded
    primary: baseColors.cyan,
    primaryLight: baseColors.cyan30,
    primaryDark: baseColors.cyan50,
    primaryDarkest: baseColors.navy70,
    primaryLightest: baseColors.cyan10,
    secondaryDark: baseColors.cyan60,

    // Images
    imgNavy: baseColors.legacyNavy,
    imgCyan: baseColors.cyan,
    imgCyan50: baseColors.cyan50,
    imgWhite: baseColors.white,
    imgBlueGrey: baseColors.cyan10,
    imgLightGrey: baseColors.grey20,
    imgGrey: baseColors.grey10,
    imgLight: baseColors.white,
    imgDark: baseColors.legacyNavy,
    imgGreen: baseColors.legacyGreen,
    imgDanger: baseColors.legacyRed,
    ...lightTheme,
};

export const DarkTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    blackConstant: baseColors.black,
    whiteConstant: baseColors.cyan10,
    cyanConstant: baseColors.cyan40,
    transparent: baseColors.transparent,

    //Shadows - these colours don't change between themes
    shadow60: baseColors.shadow60,
    shadow40: baseColors.shadow40,
    shadow34: baseColors.shadow34,
    shadow25: baseColors.shadow25,
    shadow22: baseColors.shadow22,
    shadow20: baseColors.shadow20,
    shadow14: baseColors.shadow14,
    shadow12: baseColors.shadow12,

    // Paper
    paper0: baseColors.legacyNavy,
    paper1: baseColors.navy70,
    paper2: baseColors.navy60,
    paper3: baseColors.navy50,

    // Text
    primaryText: baseColors.navy10,
    secondaryText: baseColors.navy30,
    ternaryText: baseColors.navy20,
    highlightText: baseColors.highlightText,
    highlightTextBackground: baseColors.highlightTextBackground,

    // Text links
    linkText: baseColors.cyan30,
    linkTextHover: baseColors.white,
    sideMenuHover: baseColors.navy70,
    sideMenuNestedText: baseColors.cyan20,
    sidebarMenuSelectedText: baseColors.cyan40,

    // Nav
    navBackground: baseColors.legacyNavy,
    navItemHover: baseColors.cyan60,
    navItemActive: baseColors.cyan40,
    navText: baseColors.navy20,
    navTextHover: baseColors.white,
    navTextActive: baseColors.cyan10,
    spaceMenuBackground: baseColors.cyan60,
    spaceMenuText: baseColors.white,

    // Tabs
    tabActiveIndicator: baseColors.cyan,

    // Buttons
    primaryButtonBackground: baseColors.green50,
    primaryButtonText: baseColors.green10,

    secondaryButtonBackground: baseColors.legacyNavy,
    secondaryButtonText: baseColors.cyan,

    ternaryButton: baseColors.cyan30,
    ternaryButtonHover: baseColors.cyan10,

    categoryButtonBackground: baseColors.cyan,

    disabledButtonBorder: baseColors.navy30,
    disabledButtonBackground: baseColors.navy60,

    disabledButtonText: baseColors.navy30,

    createReleaseBackground: baseColors.cyan40,
    createReleaseText: baseColors.cyan10,

    deleteButtonText: baseColors.red10,
    deleteButtonBackground: baseColors.red40,

    cancelButtonBackground: baseColors.orange30,
    cancelButtonText: baseColors.orange10,

    subtleButtonBackground: baseColors.navy50,
    subtleButtonBorder: baseColors.navy40,

    // Paging
    pagingButtonBackground: baseColors.cyan60,
    pagingButtonBackgroundHover: baseColors.cyan,
    pagingButtonBackgroundActive: baseColors.cyan,

    // Dashboard
    tenantCount: baseColors.grey70,

    // Section
    SectionBodyText: baseColors.navy20,
    groupHeaderBackground: baseColors.navy50,
    groupHeader: baseColors.navy20,
    statusBarBackground: baseColors.navy90,
    statusBarItemBackground: baseColors.navy70,

    // Backgrounds & dividers
    primaryBackground: baseColors.legacyNavy,
    disabledBackground: "rgba(153, 153, 153, 0.7)",
    secondaryBackground: baseColors.navy50,
    ternaryBackground: baseColors.legacyNavy,

    hover: "rgba(15, 36, 52, 0.9)",
    divider: baseColors.navy40,
    secondaryDivider: baseColors.navy30,
    logoBackground: baseColors.navy10,
    stepLogoBackground: baseColors.cyan10,
    parentStepBackground: baseColors.cyan50,
    loadingIndicator: baseColors.cyan40,
    loadingIndicatorBackground: baseColors.navy50,
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",

    // Cards
    cardBackground: baseColors.navy70,
    cardHover: "rgba(5, 59, 98, 0.5)", //cyan60
    cardFooterBuiltIn: baseColors.cyan,
    cardFooterInstalled: baseColors.legacyNavy, //paper0,
    cardFooterCommunity: baseColors.navy50, // paper3,

    // Form
    focus: baseColors.cyan,
    selectBorder: baseColors.grey50,

    // Chips
    chipBackground: baseColors.cyan70,
    chipHighlight: baseColors.cyan30,
    chipText: baseColors.navy10,
    chipIcon: baseColors.navy10,
    chipDeleteButton: baseColors.grey30,
    avatarBackground: baseColors.cyan50,

    // Code Editor hints
    hintHighlight: baseColors.red20,
    hintActive: baseColors.cyan40,
    placeholder: baseColors.white,
    placeholderActive: baseColors.white,

    // Icon Editor
    iconEditorIconHover: baseColors.grey10,
    iconEditorColorPaletteInnerBorder: baseColors.white,
    iconEditorColorPaletteHover: baseColors.grey30,
    iconEditorColorPaletteSelected: baseColors.grey50,

    // Callouts
    info: baseColors.legacyBlue,
    infoConstant: baseColors.legacyBlue,
    infoBackground: baseColors.blue30,
    infoBorder: baseColors.blue20,
    infoHeaderText: baseColors.blue20,
    infoText: baseColors.blue20,
    infoHighlight: "rgba(0, 0, 0, 0)",

    success: baseColors.legacyGreen,
    successConstant: baseColors.legacyGreen,
    successBackground: baseColors.green60,
    successTaskLogBackground: baseColors.legacyNavy,
    successBorder: baseColors.green40,
    successHeaderText: baseColors.green20, //For use on successBackground
    successText: baseColors.green30, //For use on navy background
    successHighlight: "rgba(0, 0, 0, 0)",

    danger: baseColors.legacyRed,
    dangerConstant: baseColors.legacyRed,
    dangerBackground: baseColors.red60,
    dangerTaskLogBackground: baseColors.legacyNavy,
    dangerBorder: baseColors.red50,
    dangerHeaderText: baseColors.red30, //For use on dangerBackground
    dangerText: baseColors.red20, //For use on navy background
    dangerHighlight: "rgba(0, 0, 0, 0)",

    alert: baseColors.legacyOrange,
    alertBackground: baseColors.orange40,
    alertBorder: baseColors.orange20,
    alertConstant: baseColors.legacyOrange,
    alertHeaderText: baseColors.orange20, //For use on alertBackground
    alertText: baseColors.orange30, //For use on navy background
    alertHighlight: "rgba(0, 0, 0, 0)",

    sunshineText: baseColors.legacyYellow,

    darkBackground: baseColors.white,
    darkText: baseColors.legacyNavy,

    auditHighlight: baseColors.navy60,

    codeHighlight: baseColors.purple40,
    codeText: baseColors.purple20,
    codeTextBright: baseColors.purple30,

    featureText: baseColors.cyan10,
    featureBackground: baseColors.cyan70,
    featureBorder: baseColors.cyan10,

    //Audit diffs
    diffAddedBackground: baseColors.diffAddedBackground,
    diffWordAddedBackground: baseColors.diffWordAddedBackground,
    diffRemovedBackground: baseColors.diffRemovedBackground,
    diffWordRemovedBackground: baseColors.diffWordRemovedBackground,
    diffInfoBackground: baseColors.diffInfoBackground,
    diffHighlight: baseColors.grey20,

    // Form validation
    errorText: baseColors.red20,

    // icons
    iconNavy: baseColors.legacyNavy,
    iconDark: baseColors.cyan20,
    iconLight: baseColors.legacyNavy,
    iconNeutral: baseColors.grey30,

    // Tooltip
    tooltipText: baseColors.grey70,
    tooltipBackground: baseColors.white,

    // Primary color
    primary: baseColors.cyan30,
    mainBackground: baseColors.cyan40,
    mainText: baseColors.cyan30,

    primaryLight: baseColors.navy40,
    primaryDark: baseColors.navy10,
    primaryDarkest: baseColors.legacyNavy,
    primaryLightest: baseColors.navy10,
    secondaryDark: baseColors.cyan,

    // Images
    imgNavy: baseColors.legacyNavy,
    imgCyan: baseColors.cyan,
    imgCyan50: baseColors.cyan50,
    imgWhite: baseColors.white,
    imgBlueGrey: baseColors.cyan70,
    imgLightGrey: baseColors.navy30,
    imgGrey: baseColors.navy50,
    imgLight: baseColors.legacyNavy,
    imgDark: baseColors.white,
    imgGreen: baseColors.legacyGreen,
    imgDanger: baseColors.legacyRed,
    ...darkTheme,
};
