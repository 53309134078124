/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import UserApiKeysList from "~/areas/users/UserApiKeys/UserApiKeysList";
import type { AuthenticationProviderElement, IdentityMetadataResource } from "~/client/authentication/authenticationProviderElement";
import type { TeamMembership, LicenseStatusResource } from "~/client/resources";
import { PermissionsMode } from "~/client/resources";
import type { ClaimsBasedIdentity } from "~/client/resources/identityResource";
import { Permission } from "~/client/resources/permission";
import type { UserResource } from "~/client/resources/userResource";
import { builtInEveryoneTeamId } from "~/client/wellKnownIds";
import { client, repository, session } from "~/clientInstance";
import { TeamChip } from "~/components/Chips";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import buildValueList from "~/components/EventFilter/buildValueList";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { RestrictedPermissionsCallout } from "~/components/RestrictedPermissionsCallout";
import { Section } from "~/components/Section/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, Checkbox, FormSectionHeading, Summary, ExpandableFormSection, Note } from "~/components/form";
import { required } from "~/components/form/Validators";
import { useQueryStringParam } from "~/hooks/useQueryStringParam";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import ProjectedTeamsList, { ProjectedTeamListItem } from "../ProjectedTeamsList/ProjectedTeamsList";
import { ProviderGroups } from "./ProviderGroups";
import UserChangePasswordDialog from "./UserChangePasswordDialog";
import { isOctopusIdEnabled } from "./identityProviders";

export function UserCreate() {
    return <UserEditInternal userId={null} isNew={null} />;
}

export function UserEdit({ userId }: { userId: string }) {
    const [isNewValue] = useQueryStringParam("isNew");
    const isNew = isNewValue === "true" ?? false;

    return <UserEditInternal userId={userId} isNew={isNew} />;
}

interface UserEditInternalProps {
    userId: string | null;
    isNew: boolean | null;
}

interface UserEditModel {
    displayName: string;
    username: string;
    emailAddress: string;
    isActive: boolean;
    isService: boolean;
    original: UserResource;
    identities: ClaimsBasedIdentity[];
    password?: string;
    confirmPassword?: string;
}

interface UserEditState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
    deleted: boolean;
    newId?: string;
    enabledAuthenticationProviders: AuthenticationProviderElement[] | null;
    enabledProvidersMetadata?: IdentityMetadataResource[] | null;
    canCurrentUserEditIdentitiesForUser?: boolean;
    userTeams: TeamMembership[];
    licenseStatus?: LicenseStatusResource;
    isServiceAccountOnly: boolean;
    isOctoIdEnabled: boolean;
}

class UserEditInternal extends FormBaseComponent<UserEditInternalProps, UserEditState, UserEditModel> {
    private isViewingAuthenticatedUser = this.currentAuthenticatedUserId() === this.currentUserId();

    constructor(props: UserEditInternalProps) {
        super(props);

        this.state = {
            user: null!,
            model: null!,
            deleted: false,
            cleanModel: null!,
            canCurrentUserEditIdentitiesForUser: true,
            enabledAuthenticationProviders: null, // Start as null so we can only show no providers once it loads.
            enabledProvidersMetadata: null,
            userTeams: [],
            isServiceAccountOnly: false,
            isOctoIdEnabled: false,
        };
    }

    currentUserId(): string | null {
        return this.props.userId;
    }

    currentAuthenticatedUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const userId = this.currentUserId();
                const user = userId ? await repository.Users.get(userId) : null!;

                const configDoc = await repository.UserIdentityMetadata.authenticationConfiguration(userId);
                const metadataDoc = await repository.UserIdentityMetadata.all();

                const isOctoIdEnabled = await isOctopusIdEnabled();
                const isServiceAccountOnly = isOctoIdEnabled && user === null;

                // Instead of the team.MemberUserIds, we use the .getTeams endpoint so we include external security groups.
                const userTeams = user ? await repository.TeamMembership.getForUser(user, true) : [];
                const licenseStatus = await repository.Licenses.getCurrentStatus();

                const isCreateUser = !userId;
                this.setState({
                    user,
                    model: this.buildModel(user!, isServiceAccountOnly),
                    cleanModel: this.buildModel(user!, isServiceAccountOnly),
                    enabledAuthenticationProviders: configDoc.AuthenticationProviders || [],
                    canCurrentUserEditIdentitiesForUser: isCreateUser || configDoc.CanCurrentUserEditIdentitiesForUser,
                    enabledProvidersMetadata: metadataDoc.Providers,
                    userTeams,
                    licenseStatus,
                    isServiceAccountOnly,
                    isOctoIdEnabled,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    buildModel(user: UserResource, isServiceAccount: boolean = false): UserEditModel {
        return user
            ? {
                  displayName: user.DisplayName,
                  username: user.Username,
                  emailAddress: user.EmailAddress!,
                  isActive: user.IsActive,
                  isService: user.IsService,
                  original: user,
                  identities: user.Identities,
              }
            : {
                  displayName: null!,
                  username: null!,
                  emailAddress: null!,
                  isActive: true,
                  isService: isServiceAccount,
                  original: null!,
                  identities: [],
              };
    }

    handleSaveClick = async () => {
        const user: UserResource = {
            ...this.state.user,
            DisplayName: this.state.model!.displayName,
            EmailAddress: this.state.model!.emailAddress,
            Username: this.state.model!.username,
            IsService: this.state.model!.isService,
            IsActive: this.state.model!.isActive,
            // Password cannot be supplied for service accounts, better UX to just drop it here
            // in case their password manager populated a field
            Password: this.state.model!.isService ? null! : this.state.model!.password,
            Identities: this.state.model!.identities,
        };

        if (!this.state.model!.isService && !this.isConfirmPasswordCorrect()) {
            this.setValidationErrors("The password do not match", { setPassword: "Please retype the password" });
            return false;
        }

        if (this.state.model!.username === null || this.state.model!.username.trim().length === 0) {
            this.setValidationErrors("There was no username given", { username: "Please provide a username" });
            return false;
        }

        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(result),
                model: this.buildModel(result),
                newId: this.currentUserId() ? null! : result.Id,
            });
        });
    };

    render() {
        return (
            <FormPaperLayout
                title={this.determineTitle()}
                titleLogo={<Avatar avatarLink={this.state.user && this.state.user.Links && this.state.user.Links.Avatar} isService={this.state.user && this.state.user.IsService} size={40} />}
                breadcrumbTitle={"Users"}
                breadcrumbPath={routeLinks.configuration.users.root}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={this.isViewingAuthenticatedUser ? undefined : { permission: [Permission.AdministerSystem, Permission.UserEdit] }}
                expandAllOnMount={this.isCreateUser()}
                onSaveClick={this.handleSaveClick}
                overFlowActions={this.createOverflowMenuItems()}
                secondaryAction={this.changePasswordButton()}
            >
                {this.showCloudInviteHelp() && (
                    <Callout title="Inviting a user?" type={CalloutType.Information}>
                        You can do that from <ExternalLink href="OctopusCloudAccount">your Octopus account</ExternalLink>. Learn more about <ExternalLink href="OctopusCloudInvites">user invites for Octopus Cloud</ExternalLink>.
                    </Callout>
                )}
                {this.state.deleted && <InternalRedirect to={routeLinks.configuration.users.root} />}
                {this.state.newId && <InternalRedirect to={routeLinks.configuration.user(this.state.newId, true)} />}

                {this.state.model && (
                    <TransitionAnimation>
                        {this.userOnlyBelongsToTheEveryoneTeam() && (
                            <PermissionCheck permission={Permission.TeamView}>
                                <Callout type={CalloutType.Information} title={"Assign this user to teams"}>
                                    To manage permissions for this user, <InternalLink to={routeLinks.configuration.teams.root()}>assign them to one or more teams</InternalLink>.
                                    {this.userIsANewActiveDirectoryUser() && (
                                        <> Collating user groups from Active Directory can take up to a few minutes. Teams linked to an Active Directory group the user is in may be missing until this process is finished.</>
                                    )}
                                </Callout>
                            </PermissionCheck>
                        )}

                        <RestrictedPermissionsCallout isVisible={this.state.licenseStatus?.PermissionsMode === PermissionsMode.Restricted ?? false} />

                        {this.renderUserNameAndDisplayName()}

                        {this.renderServiceAccountOption()}

                        {this.renderEmail()}

                        {this.renderIsActive()}

                        {/* Passwords, then API keys, then external logins */}
                        <FormSectionHeading title="Logins" />

                        {this.renderPasswordSection()}

                        {this.renderApiSection()}

                        {this.renderLoginOptions()}

                        {this.state.user && this.state.userTeams.length > 0 && (
                            <>
                                <FormSectionHeading title="Teams" />
                                {this.renderTeamsSection()}
                            </>
                        )}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    determineTitle() {
        return this.isCreateUser() ? "New User" : (this.state.model && this.state.model.displayName) || "User Details";
    }

    renderUserNameAndDisplayName() {
        return [
            // Note there's a bug in the old portal that let you create a user without a username, some of the extra checks/messages here are to show that's the case
            <ExpandableFormSection
                key="username"
                errorKey={"username"}
                title="Username"
                focusOnExpandAll
                summary={Summary.summary(this.state.model!.username ? this.state.model!.username : "No username specified yet.")}
                help={this.isCreateUser() ? "Enter a username the user authenticates with." : "The username that the user authenticates with."}
            >
                {this.state.model!.original === null ? (
                    <Text value={this.state.model!.username} onChange={(username) => this.setModelState({ username })} label="Username" validate={required("Please enter a username")} autoFocus={true} />
                ) : (
                    this.state.model!.username || "no user name"
                )}
            </ExpandableFormSection>,
            <ExpandableFormSection
                key="displayName"
                errorKey="displayName"
                title="Display Name"
                summary={Summary.summary(this.state.model!.displayName ? this.state.model!.displayName : "No user display name specified yet.")}
                help="Enter a display name for the user. This does not need to be unique."
            >
                <Text value={this.state.model!.displayName} onChange={(displayName) => this.setModelState({ displayName })} label="Display name" validate={required("Please enter a display name")} />
            </ExpandableFormSection>,
        ];
    }

    renderPasswordSection() {
        return (
            this.isCreateUser() &&
            !this.state.model!.isService && (
                <ExpandableFormSection
                    errorKey={"setPassword"}
                    title={"Password"}
                    summary={Summary.summary(this.state.model!.password ? "The user's password." : "Optional. Set a password for this user.")}
                    help={this.state.model!.password ? "The user's password." : "Optional. Set a password for this user."}
                >
                    <Section>
                        <Text value={this.state.model!.password!} type={"password"} onChange={(password) => this.setModelState({ password })} label="Password" autoComplete="new-password" />
                    </Section>
                    <Section>
                        <Text
                            value={this.state.model!.confirmPassword!}
                            type={"password"}
                            onChange={(confirmPassword) => this.setModelState({ confirmPassword })}
                            label="Confirm password"
                            validate={this.isConfirmRequired()!}
                            autoComplete="new-password"
                        />
                    </Section>
                </ExpandableFormSection>
            )
        );
    }

    changePasswordButton() {
        const usernamePasswordProviderExists = this.state.enabledAuthenticationProviders && this.state.enabledAuthenticationProviders.find((p) => p.Name === "Octopus") !== null;

        return (
            this.state.model &&
            this.state.model.original &&
            this.state.model.original.CanPasswordBeEdited &&
            (this.isViewingAuthenticatedUser || isAllowed({ permission: [Permission.AdministerSystem, Permission.UserEdit] })) &&
            usernamePasswordProviderExists && (
                <OpenDialogButton label="Change Password">
                    <UserChangePasswordDialog userId={this.state.model.original.Id} />
                </OpenDialogButton>
            )
        );
    }

    handleDeleteConfirm = async () => {
        const result = await repository.Users.del(this.state.model!.original);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    renderServiceAccountOption() {
        return (
            this.isCreateUser() && (
                <ExpandableFormSection
                    errorKey={"isService"}
                    title="Service Account"
                    summary={Summary.summary(this.state.model!.isService ? "This is a service account" : "This is not a service account.")}
                    help={"A service account can log in using API keys only. After creating the user you'll need to add some API keys before the account can be used."}
                >
                    <Checkbox disabled={this.state.isServiceAccountOnly} value={this.state.model!.isService} onChange={(isService) => this.setModelState({ isService })} label="The user is a service account" />
                </ExpandableFormSection>
            )
        );
    }

    renderEmail() {
        return (
            !this.state.model!.isService && (
                <ExpandableFormSection
                    errorKey="EmailAddress"
                    title="Email Address"
                    summary={Summary.summary(this.state.model!.emailAddress ? this.state.model!.emailAddress : "No user email specified yet.")}
                    help={this.state.model!.emailAddress ? "The user's email address." : "Enter an email address."}
                >
                    <Text value={this.state.model!.emailAddress} onChange={(emailAddress) => this.setModelState({ emailAddress })} label="Email address" />
                </ExpandableFormSection>
            )
        );
    }

    renderLoginOptions() {
        return (
            <ProviderGroups
                userIdentities={this.state.model!.identities}
                enabledAuthenticationProviders={this.state.enabledAuthenticationProviders!}
                canCurrentUserEditIdentitiesForUser={this.state.canCurrentUserEditIdentitiesForUser!}
                enabledProvidersMetadata={this.state.enabledProvidersMetadata!}
                isServiceAccount={this.state.model!.isService}
                onChange={(identities) => this.setModelState({ identities })}
            />
        );
    }

    renderApiSection() {
        return (
            this.state.model!.original && (
                <ExpandableFormSection errorKey="ApiKeys" title="Api Keys" summary={Summary.summary("The user's API keys")} help="API keys can be used to access the Octopus Deploy REST API.">
                    <UserApiKeysList user={this.state.model!.original} doBusyTask={this.doBusyTask} />
                </ExpandableFormSection>
            )
        );
    }

    renderIsActive() {
        return (
            !this.isCreateUser() && (
                <ExpandableFormSection
                    errorKey="isActive"
                    title="Is Active"
                    summary={Summary.summary(this.state.model!.isActive ? "This user is active and can log in" : "This user has been deactivated and cannot use Octopus Server")}
                    help="Inactive users remain in the database but cannot use the Octopus Server."
                >
                    <Checkbox value={this.state.model!.isActive} onChange={(isActive) => this.setModelState({ isActive })} label="Is active" />
                </ExpandableFormSection>
            )
        );
    }

    renderTeamsSection() {
        if (!this.state.userTeams || !this.state.user) {
            return null;
        }
        return (
            <ExpandableFormSection errorKey="Teams" title="Teams" summary={this.teamsSummary(this.state.userTeams)} help="This user is a member of the following teams in this space.">
                <Note>
                    User permissions are governed by <InternalLink to={routeLinks.configuration.teams.root()}>team membership</InternalLink>.
                </Note>
                <ProjectedTeamsList
                    items={this.state.userTeams}
                    onFilter={null!}
                    onRow={(x) => {
                        return <ProjectedTeamListItem projectedTeam={x} />;
                    }}
                    onRowRedirectUrl={(x) => {
                        return routeLinks.configuration.team(x.TeamId, "members");
                    }}
                />
            </ExpandableFormSection>
        );
    }

    private showCloudInviteHelp() {
        return this.isCreateUser() && this.state.isOctoIdEnabled;
    }

    private teamsSummary(selectedTeams: TeamMembership[]) {
        if (selectedTeams && selectedTeams.length > 0) {
            const knownTeamChips = selectedTeams.map((t) => <TeamChip key={t.TeamId} team={t} />);
            return Summary.summary(<div>Assigned to teams {buildValueList(knownTeamChips)}</div>);
        } else {
            return Summary.placeholder("No teams assigned");
        }
    }

    private userOnlyBelongsToTheEveryoneTeam(): boolean {
        const userTeams = this.state.userTeams;
        return this.state.user && userTeams.length === 1 && userTeams[0].TeamId === builtInEveryoneTeamId;
    }

    private userIsANewActiveDirectoryUser(): boolean {
        const isNew = this.props.isNew ?? false;
        const isActiveDirectory = this.state.user && this.state.user.Identities.findIndex((i) => i.IdentityProviderName === "Active Directory") !== -1;
        return isNew && isActiveDirectory;
    }

    private createOverflowMenuItems() {
        const items = [];

        if (!this.isCreateUser() && this.state.model) {
            items.push(
                OverflowMenuItems.deleteItemDefault(
                    "user",
                    this.handleDeleteConfirm,
                    null!,
                    null!,
                    <div>
                        Have a look at the Octopus <ExternalLink href="PrivacyPolicy">Privacy Policy</ExternalLink> for the full details of categories of data that will remain.
                    </div>
                )
            );

            items.push(
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForUser(this.currentUserId()!), {
                    permission: Permission.EventView,
                    wildcard: true,
                })
            );
        }

        if (this.state.model && this.state.user && this.state.user.Id) {
            items.push(
                OverflowMenuItems.navItem("Test Permissions", routeLinks.configuration.testPermission(this.state.user.Id), {
                    permission: [Permission.TeamEdit, Permission.UserView],
                })
            );
            items.push(OverflowMenuItems.downloadItem("Download user data", this.state.model.username + "-user.json", client.resolveLinkTemplate("Users", { id: this.state.user.Id })));
        }

        if (this.isViewingAuthenticatedUser || isAllowed({ permission: [Permission.AdministerSystem, Permission.UserEdit] })) {
            items.push(
                OverflowMenuItems.confirmActionItem("Revoke Sessions", "Do you wish to revoke all active sessions of this user?", async () => {
                    await repository.Users.revokeSessions(this.state.user);
                })
            );
        }

        return items;
    }

    private isCreateUser() {
        return this.state.model && this.state.model.original === null;
    }

    private isConfirmPasswordCorrect() {
        if (!this.state.model!.password) {
            return true;
        }

        return this.isCreateUser() && this.state.model!.password && this.state.model!.password.localeCompare(this.state.model!.confirmPassword!) === 0;
    }

    private isConfirmRequired() {
        return this.isConfirmPasswordCorrect() ? null : required("Passwords don't match");
    }
}
