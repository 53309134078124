/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { GreenTickIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/GreenTickIcon";
import { PauseIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseIcon";
import { getAuditStreamType, createNewAuditStreamConfiguration } from "~/areas/configuration/components/AuditLayout/AuditStream/utils";
import type { AuditStreamConfigurationResource } from "~/client/resources/auditStreamConfigurationResource";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import type { OctopusTheme } from "~/components/Theme/index";
import { useOctopusTheme } from "~/components/Theme/index";
import { Text } from "~/components/form/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";

interface DeleteAuditStreamDialogInternalState {
    providerInput: string;
}

interface DeleteAuditStreamDialogInternalProps extends DeleteAuditStreamDialogProps {
    theme: OctopusTheme;
    trackAction: AnalyticTrackedActionDispatcher;
}

class DeleteAuditStreamDialogInternal extends DataBaseComponent<DeleteAuditStreamDialogInternalProps, DeleteAuditStreamDialogInternalState> {
    constructor(props: DeleteAuditStreamDialogInternalProps) {
        super(props);
        this.state = {
            providerInput: "",
        };
    }

    async handleDelete() {
        const { onUpdate, trackAction } = this.props;
        const auditStreamConfiguration = createNewAuditStreamConfiguration(this.props.auditStreamConfiguration.Id, false, null, null, this.props.auditStreamConfiguration.Links);
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Delete,
                resource: "Audit Stream",
            };
            await trackAction("Delete Audit Stream", ev, async () => {
                await repository.AuditStreamConfiguration.modify(auditStreamConfiguration);
                onUpdate({ ...auditStreamConfiguration }, "Stream deleted successfully");
                return true;
            });
        });
        return false;
    }

    cancel() {
        this.props.close();
        return true;
    }

    render() {
        const { auditStreamConfiguration, open, theme } = this.props;
        const auditStreamType = getAuditStreamType(auditStreamConfiguration.StreamConfigurationResource!);
        return (
            <DeleteDialog
                open={open}
                title={"Are you sure you want to delete this stream?"}
                renderContent={() => (
                    <React.Fragment>
                        <p>
                            Status:{" "}
                            {auditStreamConfiguration.Active ? (
                                <>
                                    <GreenTickIcon />{" "}
                                    <strong>
                                        <span style={{ color: theme.success }}>Streaming to {auditStreamType}</span>
                                    </strong>
                                </>
                            ) : (
                                <>
                                    <PauseIcon />{" "}
                                    <strong>
                                        <span style={{ color: theme.danger }}>{auditStreamType} streaming is paused</span>
                                    </strong>
                                </>
                            )}
                        </p>
                        {auditStreamConfiguration.Description && (
                            <p>
                                Description: <strong>{auditStreamConfiguration.Description}</strong>
                            </p>
                        )}
                        <Callout title={"This is a destructive action"} type={CalloutType.Danger}>
                            This will disconnect Octopus from your specified {auditStreamType} endpoint. You will have to re-configure once deleted.
                        </Callout>
                        <div>
                            <p>
                                Please type in the name of the provider (<strong>{auditStreamType}</strong>) to confirm.
                            </p>
                            <Text accessibleName={"Provider input"} value={this.state.providerInput} onChange={(providerInput) => this.setState({ providerInput })} />
                        </div>
                    </React.Fragment>
                )}
                onDeleteClick={async () => this.handleDelete()}
                onClose={() => this.cancel()}
                deleteButtonDisabled={this.state.providerInput !== auditStreamType}
            />
        );
    }
}

export interface DeleteAuditStreamDialogProps {
    open: boolean;
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}

export function DeleteAuditStreamDialog(props: DeleteAuditStreamDialogProps) {
    const theme = useOctopusTheme();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <DeleteAuditStreamDialogInternal {...props} theme={theme} trackAction={trackAction} />;
}
