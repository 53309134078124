import configurationPageIds from "~/areas/configuration/components/ConfigurationRoutes/configurationPageIds";
import dashboardPageIds from "~/areas/dashboard/DashboardOverview/dashboardPageIds";
import { RawAccountTypeDetailsMap } from "~/areas/infrastructure/InfrastructureDetails";
import infrastructurePageIds from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructurePageIds";
import libraryPageIds from "~/areas/library/components/LibraryRoutes/libraryPageIds";
import projectPageIds from "~/areas/projects/components/ProjectsRoutes/projectPageIds";
import taskPageIds from "~/areas/tasks/components/TaskRoutes/taskPageIds";
import tenantPageIds from "~/areas/tenants/TenantsRoutes/tenantPageIds";
import userProfilePageIds from "~/areas/users/UserProfileRoutes/userProfilePageIds";
import { Permission } from "~/client/resources/permission";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import routeLinks from "~/routeLinks";
import type IPageWrapper from "~/utils/pageId";
import { pageId } from "~/utils/pageId";

const dashboardPages: IPageWrapper[] = [dashboardPageIds.root, dashboardPageIds.configure];

const libraryPageIdsInstance = libraryPageIds();
const libraryPages: IPageWrapper[] = [
    libraryPageIdsInstance.root,
    libraryPageIdsInstance.feeds,
    libraryPageIdsInstance.feedCreate,
    libraryPageIdsInstance.stepTemplates.root,
    libraryPageIdsInstance.stepTemplates.builtIn,
    libraryPageIdsInstance.stepTemplates.community,
    libraryPageIdsInstance.lifecycles,
    libraryPageIdsInstance.lifecyclesCreate,
    libraryPageIdsInstance.certificates.root,
    libraryPageIdsInstance.certificates.archive,
    libraryPageIdsInstance.certificates.create,
    libraryPageIdsInstance.variableSets.root,
    libraryPageIdsInstance.tagSets.root,
    libraryPageIdsInstance.tagSets.create,
    libraryPageIdsInstance.builtInRepository.root,
    libraryPageIdsInstance.buildInformationRepository.root,
    libraryPageIdsInstance.scriptModules.root,
    libraryPageIdsInstance.scriptModules.create,
];

const configurationPageIdsInstance = configurationPageIds();
const configurationPages: IPageWrapper[] = [
    configurationPageIdsInstance.root,
    configurationPageIdsInstance.userInvites,
    configurationPageIdsInstance.auditBaseRoute,
    configurationPageIdsInstance.auditArchive,
    configurationPageIdsInstance.backup,
    configurationPageIdsInstance.features,
    configurationPageIdsInstance.license,
    configurationPageIdsInstance.smtp,
    configurationPageIdsInstance.nodes.root,
    configurationPageIdsInstance.nodes.serverSettings,
    configurationPageIdsInstance.thumbprint,
    configurationPageIdsInstance.maintenance,
    configurationPageIdsInstance.letsEncrypt.root,
    configurationPageIdsInstance.letsEncrypt.configure,
    configurationPageIdsInstance.testPermissions,
    configurationPageIdsInstance.subscriptions.root,
    configurationPageIdsInstance.subscriptions.create,
    configurationPageIdsInstance.users.root,
    configurationPageIdsInstance.users.create,
    configurationPageIdsInstance.spaces.root,
    //configurationPageIdsInstance.spaces.create, // Excluding as an edge case - our UI creates these via a popover.
    configurationPageIdsInstance.teams.root,
    configurationPageIdsInstance.roles.root,
    configurationPageIdsInstance.roles.create,
    configurationPageIdsInstance.diagnostics.root,
    configurationPageIdsInstance.diagnostics.logs.root,
    configurationPageIdsInstance.diagnostics.logs.autoDeploy,
    configurationPageIdsInstance.diagnostics.logs.scheduledDeploy,
    configurationPageIdsInstance.diagnostics.logs.machineCleanup,
    configurationPageIdsInstance.diagnostics.logs.subscription,
    configurationPageIdsInstance.settings.root,
    configurationPageIdsInstance.performance,
];

const taskPageIdsInstance = taskPageIds();
const taskPages: IPageWrapper[] = [taskPageIdsInstance.tasks.root, taskPageIdsInstance.tasks.console];

const infrastructurePagesIdsInstance = infrastructurePageIds();
const infrastructurePages: () => IPageWrapper[] = () => {
    const results = [
        infrastructurePagesIdsInstance.root,
        infrastructurePagesIdsInstance.overview,
        infrastructurePagesIdsInstance.environments.root,
        //infrastructurePagesIdsInstance.environments.create, // Excluding as an edge case - our UI creates these via a popover.
        infrastructurePagesIdsInstance.machines.root,
        infrastructurePagesIdsInstance.machines.create,
        infrastructurePagesIdsInstance.proxies.root,
        infrastructurePagesIdsInstance.proxies.create,
        infrastructurePagesIdsInstance.machinePolicies.root,
        infrastructurePagesIdsInstance.machinePolicies.create,
        infrastructurePagesIdsInstance.accounts.root,
        //infrastructurePagesIdsInstance.accounts.create, // Excluding as an edge case - our UI creates these via a popover.
        infrastructurePagesIdsInstance.workerMachines.root,
        infrastructurePagesIdsInstance.workerMachines.create,
        infrastructurePagesIdsInstance.workerPools.root,
        //infrastructurePagesIdsInstance.workerPools.create, // Excluding as an edge case - our UI creates these via a popover.
    ];

    // Inject our account types dynamically.
    const accountTypeDetailsMap = RawAccountTypeDetailsMap;
    accountTypeDetailsMap.forEach((accountTypeDetails) => {
        if (accountTypeDetails.types.length === 0) {
            return null;
        }
        const accountCreateRoute = {
            pathname: routeLinks.infrastructure.accounts.create,
            search: convertQueryToQueryString({ accountType: accountTypeDetails.types[0] }),
        };
        results.push(
            pageId({
                area: "Infrastructure",
                id: `Infrastructure.AccountsNew.${accountTypeDetails.name}`,
                name: `Add New ${accountTypeDetails.name} ${accountTypeDetails.name.endsWith("Account") ? "" : "Account"}`,
                routeLink: `${accountCreateRoute.pathname}${accountCreateRoute.search}`,
                routePermission: {
                    permission: Permission.AccountCreate,
                    wildcard: true,
                },
            })
        );
    });

    return results;
};

const projectPageIdsInstance = projectPageIds();
const projectPages = (): IPageWrapper[] => {
    const pages = [projectPageIdsInstance.projects.root];
    pages.push(projectPageIdsInstance.importExport.root);
    pages.push(projectPageIdsInstance.importExport.import);
    pages.push(projectPageIdsInstance.importExport.export);
    return pages;
};

const tenantPageIdsInstance = tenantPageIds();
const tenantPages: IPageWrapper[] = [tenantPageIdsInstance.tenants];

const userProfilePageIdsInstance = userProfilePageIds();
const userProfilePages: IPageWrapper[] = [userProfilePageIdsInstance.me, userProfilePageIdsInstance.logins, userProfilePageIdsInstance.details, userProfilePageIdsInstance.apiKeys, userProfilePageIdsInstance.activity];

export const pagesToIncludeInSearch = () => {
    return [...dashboardPages, ...libraryPages, ...configurationPages, ...taskPages, ...infrastructurePages(), ...projectPages(), ...tenantPages, ...userProfilePages];
};
