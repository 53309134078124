import type { Client } from "../client";
import type { RouteArgs } from "../resolver";
import type { ResourceCollection } from "../resources";
import type { NewDynamicEnvironmentResource, DynamicEnvironmentResource } from "../resources/dynamicEnvironmentResource";

export type GetManyDynamicEnvironmentsResponseV1 = {
    Environments: ResourceCollection<DynamicEnvironmentResource>;
};

export type GetDynamicEnvironmentByIdResponseV1 = {
    Environment: DynamicEnvironmentResource;
};

export type CreateDynamicEnvironmentResponseV1 = {
    Id: string;
};

export class DynamicEnvironmentRepository {
    protected client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    async create(resource: NewDynamicEnvironmentResource, args?: RouteArgs): Promise<DynamicEnvironmentResource> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id = (await this.client.post<CreateDynamicEnvironmentResponseV1>(this.client.getLink("DynamicEnvironmentsCreateV1"), resource, args!)).Id;
        return (await this.client.get<GetDynamicEnvironmentByIdResponseV1>(this.client.getLink("DynamicEnvironments"), { id })).Environment;
    }

    async getMany(args?: RouteArgs): Promise<ResourceCollection<DynamicEnvironmentResource>> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return (await this.client.get<GetManyDynamicEnvironmentsResponseV1>(this.client.getLink("DynamicEnvironments"), args!)).Environments;
    }

    async del(id: string, args?: RouteArgs): Promise<unknown> {
        const path = this.client.getLink("DynamicEnvironments");
        return await this.client.del(path, null, { id });
    }
}
