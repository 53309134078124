/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

// Allows other parties to register 'plugins' that provide the view and edit experience for
// custom deployment actions and conventions.
//
//   var module = angular.module('myPlugin', [], function(pluginRegistry) {
//      pluginRegistry.registerDeploymentAction("Octopus.TentaclePackage", {
//        title: "Deploy a NuGet package",               // Display text for users adding the step
//        summary: "Deploys a NuGet package...",         // Help text to show
//        summaryTemplateUrl: "area/foo/bar.html",       // Template for a short summary on the process overview page
//        summaryLink: function (scope, element) {       // Angular directive-style link function
//          var action = scope.action;                   // Get the action we are rendering
//          var step = scope.step;                       // Get the step we are rendering
//          var jQueryElement = element;                 // The HTML element for the bar.html we just inserted
//        },
//        editTemplate: "area/bar/baz.html"              // Template for editing
//      });
//
//      pluginRegistry.registerFeature("Octopus.Features.CustomPackageDirectory", {
//        isEnabled: function(action, step) {  },
//        enable: function(action, step) {  },
//        disable: function(action, step) {  },
//        editTemplateUrl: "area/foo/bar.html",
//        editLink: function(scope, element) { }
//      });
//   });

import type { StepUI } from "@octopusdeploy/step-ui";
import type * as React from "react";
import type { RunOn, StoredAction, StoredStep, TargetRoles } from "~/areas/projects/components/Process/types";
import type { ActionExecutionLocation, ActionTemplateParameterResource, DeploymentActionResource, GitRefResource, IProcessResource, PackageRequirement, WorkerPoolResource } from "~/client/resources";
import type { ActionProperties } from "~/client/resources/actionProperties";
import type { PackageReference, PackageReferenceProperties } from "~/client/resources/packageReference";
import type { CloudConnectionType } from "~/client/resources/stepPackage";
import type { StepPackageInputs } from "~/client/resources/stepPackageInputs";
import { repository } from "~/clientInstance";
import { jiraStepPackage } from "~/components/Actions/jira/JiraServiceDeskChangeRequestStepUI";
import { sampleStepPackage } from "~/components/Actions/sample/SampleStepPackage";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { StepPackage, UnknownStepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
import { asUnknownStepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
import { createStepPackagePluginAdapter } from "~/components/StepPackageEditor/StepPackagePluginAdapter/StepPackagePluginAdapter";
import Environment from "~/environment";
import type { FieldErrors } from "../DataBaseComponent/Errors";

export interface Features {
    optional?: string[];
    initial?: string[];
    permanent?: string[];
}

export interface BoundFieldProps {
    localNames?: string[];
    projectId?: string;
    gitRef?: GitRefResource | undefined;
}

// If you need access to the Step add a new child object here to scope
// the details you need. This won't be supplied in the Template area
export interface AdditionalActions {
    packageAcquisition: {
        stepPackageRequirement?: PackageRequirement;
        onStepPackageRequirementChanged(value: PackageRequirement): void;
    };
    stepTargetRoles: string;
    actionType: string;
}

export interface ActionEditProps<T = ActionProperties, P = PackageReferenceProperties> extends BoundFieldProps {
    inputs?: StepPackageInputs;
    properties: T;
    packages: Array<PackageReference<P>>;
    plugin: ActionPlugin;
    runOn?: RunOn; // Will not be supplied if an action-template, as the execution location is not chosen
    additionalActions?: AdditionalActions;
    errors: FieldErrors | undefined; // only used for shouldComponentUpdate
    busy: Promise<any> | boolean | undefined;
    expandedByDefault: boolean;
    parameters?: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setInputs?(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<T>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference<P>>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn?(): void;
    getProcessResource?(): Readonly<IProcessResource>; // Will not be supplied if an action-template, as no process exists
    inputDependencies?: StepInputDependencies;
}

export interface ActionPlugin {
    actionType: string;
    summary: (action: ActionProperties, targetRolesAsCSV: string, packages: Array<PackageReference<{}>>, workerPool?: WorkerPoolResource, workerPoolVariable?: string | null) => React.ReactNode;
    canHaveChildren: (step: StoredStep) => boolean;
    canBeChild: boolean;
    executionLocation: ActionExecutionLocation;
    edit: any;
    canRunOnWorker?: boolean;
    hasPackages?: (action: StoredAction) => boolean;
    targetRoleOption: (action: StoredAction | DeploymentActionResource) => TargetRoles;
    features?: Features;
    canRunInContainer?: boolean;
    getInitialProperties?: () => ActionProperties;
    getInitialInputsAndPackages?: () => { inputs: StepPackageInputs; packages: PackageReference[] };
    stepPackage?: StepPackage<unknown>;
    version?: string | undefined;
    releaseNotesUrl?: string;
    targetDiscoveryCloudConnectionProviders?: () => Array<CloudConnectionType>;
}

export interface FeaturePlugin {
    description: string;
    featureName: string;
    priority: number;
    title: string;
    edit: any;
    enable?(properties: ActionProperties): void;
    disable?(properties: ActionProperties): void;
    preSave?(properties: ActionProperties): void;
    validate?(properties: ActionProperties, errors: {}): void;
}

export class ActionPluginRegistry {
    private registeredActions: Map<string, ActionPlugin> = new Map();
    private registeredFeatures: Map<string, FeaturePlugin> = new Map();

    registerAction(registration: ActionPlugin) {
        this.registeredActions.set(registration.actionType, registration);
    }

    registerFeature(registration: FeaturePlugin) {
        this.registeredFeatures.set(registration.featureName, registration);
    }

    getAction = async (actionType: string, version?: string): Promise<ActionPlugin> => {
        const legacyActionPlugin = this.registeredActions.get(actionType);
        if (legacyActionPlugin) {
            return (await getLegacyStepPackagePluginIfExists(actionType)) ?? legacyActionPlugin;
        }

        return await getStepPackagePlugin(actionType, version);
    };

    // todo-step-ui. This function needs to return the step package plugins too. Ideally we should remove this function entirely, because we don't want to load all step plugins from the API at once.
    getAllActions(): ActionPlugin[] {
        return Array.from(this.registeredActions.values());
    }

    getFeature(featureName: string): FeaturePlugin {
        const feature = this.registeredFeatures.get(featureName);
        if (!feature) {
            throw new Error(`There is no plugin registered for ${featureName} feature type.`);
        }
        return feature;
    }

    getAllFeatures(): FeaturePlugin[] {
        return Array.from(this.registeredFeatures.values()).sort((x) => x.priority);
    }

    hasFeaturesForAction(actionType: string): boolean {
        // New step packages don't use features - only legacy actions have features
        const actionPlugin = this.registeredActions.get(actionType);
        if (!actionPlugin) {
            // This can occur if the action is actually a step package, rather than a legacy action
            return false;
        }
        const featuresForAction = this.getAllFeatures().filter((f) => actionPlugin.features && actionPlugin.features.optional && actionPlugin.features.optional.includes(f.featureName));
        return featuresForAction.length > 0;
    }

    // todo-step-ui: Is there a way to get rid of this function entirely, or alternatively make it work with step package plugins?
    hasAction(actionType: string) {
        return this.registeredActions.has(actionType);
    }
}

async function getLegacyStepPackagePluginIfExists(actionType: string): Promise<ActionPlugin | null> {
    const stepPackage = getStepImplementedInPortalInDevelopmentMode(actionType);
    if (stepPackage) {
        return createStepPackagePluginAdapter(stepPackage, actionType);
    }
    return null;
}

async function getStepPackagePlugin(actionType: string, version: string | undefined): Promise<ActionPlugin> {
    const stepPackage = getStepImplementedInPortalInDevelopmentMode(actionType) ?? (await getRemoteStepPackage(actionType, version));
    return createStepPackagePluginAdapter(stepPackage, actionType);
}

function getStepImplementedInPortalInDevelopmentMode(actionType: string): UnknownStepPackage | null {
    if (!Environment.isInDevelopmentMode()) {
        return null;
    }
    return getStepImplementedInPortal(actionType);
}

export async function getRemoteStepPackage(actionType: string, version: string | undefined): Promise<UnknownStepPackage> {
    const stepPackage = version === undefined ? await repository.StepPackageRepository.getStepPackage(actionType) : await repository.StepPackageRepository.getStepPackageByIdAndVersion(actionType, version);
    return {
        name: stepPackage.name,
        description: stepPackage.description,
        version: stepPackage.version,
        releaseNotesUrl: stepPackage.releaseNotesUrl,
        executionLocation: stepPackage.executionLocation,
        requiresTargetRole: stepPackage.requiresTargetRole,
        stepUI: stepPackage.stepUI as StepUI<unknown>,
        inputSchema: stepPackage.schema,
        isLegacyStep: false,
        targetDiscoveryCloudConnectionTypes: stepPackage.targetDiscoveryCloudConnectionTypes,
    };
}

export function getStepImplementedInPortal(actionType: string): UnknownStepPackage | null {
    switch (actionType) {
        case "Octopus.JiraIntegration.ServiceDeskAction":
            return asUnknownStepPackage(jiraStepPackage);
        case "sample-step":
            return asUnknownStepPackage(sampleStepPackage);
        default:
            return null;
    }
}

const pluginRegistry = new ActionPluginRegistry();

export default pluginRegistry;
