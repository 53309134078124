import React from "react";
import type { ThemePaletteType } from "~/theme/index";

interface ActionField {
    "Action Type": string;
}

interface ActionMetaField {
    "Action Meta"?: string;
}

interface DocumentUrlField {
    "Document URL"?: string;
}

interface DurationField {
    "Duration (ms)": string;
}

interface ErrorCodeField {
    "Error Code"?: string;
}

interface ErrorMessageField {
    "Error Messages"?: string;
}

interface LinkLocationField {
    "Link Location": string;
}

interface LinkLabelField {
    "Link Label": string;
}

interface OnboardingField {
    "Onboarding Type": string;
}

interface PageAreaField {
    "Page Area": string;
}

interface PageField {
    "Page Name": string;
}

export interface PageFields extends PageAreaField, PageField {}

interface ResourceField {
    "Resource Type": string;
}

interface StatusField {
    Status: string;
}

interface StepCategoryField {
    "Step Category": string;
}

interface StepTemplateField {
    "Step Template"?: string;
}

interface TargetField {
    "Target Type"?: string;
}

interface GitRefType {
    "Git Ref Type"?: string;
}

interface isCaCenabledField {
    "Is CaC Enabled"?: string;
}

interface isDefaultBranchField {
    "Is Default Branch"?: string;
}
interface commitMessageField {
    "Commit Message"?: string;
}

interface ActionResultField {
    Status?: string;
}

interface LogoField {
    "Logo Type": string;
}

interface ProjectField {
    "Project Id"?: string;
}

interface StepIdField {
    "Step Id"?: string;
}

interface InsightsInputField {
    "Insights Input Field"?: string;
}

interface InsightsCadenceField {
    Cadence?: string;
}
interface CloudProviderField {
    "Cloud Provider": string;
}
interface InitialCommitBranchField {
    "Initial Commit Branch": boolean;
}
interface ProtectedBranchesField {
    "Protected Branches": boolean;
}
interface IsProtectedBranchField {
    "Is Protected Branch"?: string;
}
interface CommitBranchField {
    "Commit Branch"?: string;
}
interface PlanField {
    Plan?: string;
}
interface SiemProviderField {
    "SIEM Provider"?: string;
}

export interface PageFields extends PageField, PageAreaField {}

export interface ViewFields extends ResourceField, ProjectField {}

// Fields for an event performed
export interface ActionedFields extends ActionField, PageAreaField, PageField, ResourceField, TargetField, StepTemplateField, ActionResultField, isDefaultBranchField, GitRefType, ProjectField, commitMessageField, PlanField {}

// Fields indicating an Action undertaken
export interface ActionFields
    extends ActionField,
        ActionMetaField,
        DurationField,
        PageAreaField,
        PageField,
        ResourceField,
        StatusField,
        TargetField,
        StepTemplateField,
        isCaCenabledField,
        ProjectField,
        isDefaultBranchField,
        GitRefType,
        commitMessageField,
        IsProtectedBranchField,
        CommitBranchField,
        SiemProviderField {}

export interface ActionResultFields extends ActionField, ActionResultField {}

export interface LinkFields extends DocumentUrlField, LinkLabelField, LinkLocationField, PageAreaField, PageField {}

export interface StepFields extends ActionField, PageAreaField, PageField, ResourceField, StepCategoryField, StepTemplateField, ProjectField {}

export interface StepEditorFields extends ActionField, PageAreaField, PageField, ResourceField, StepTemplateField, ProjectField, StepIdField {}

export interface StatusFields extends ActionField, ErrorCodeField, ErrorMessageField, ResourceField, StatusField {}

export interface OnboardingFields extends OnboardingField, PageAreaField, PageField, LinkLabelField, LinkLocationField {}

export interface OtherActionFields extends PageAreaField, PageField {}

export interface LogoFields extends ActionField, ResourceField, LogoField {}

export interface InsightsFields extends ActionField, PageAreaField, InsightsInputField, InsightsCadenceField {}

export interface CloudConnectionsFields extends ActionField, ResourceField, CloudProviderField {}

export interface ConfigureGitFields extends ActionField, ResourceField, InitialCommitBranchField {}
export interface UpdateGitSettingsFields extends ActionField, ResourceField, ProtectedBranchesField {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignInFields {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignOutFields {}

export type AnalyticFields = ActionFields | ActionResultFields | ActionedFields | LinkFields | SignInFields | SignOutFields | StepFields | StatusFields | OnboardingFields | ViewFields | OtherActionFields;

export interface AnalyticSession {
    track(name: string, fields: AnalyticFields): void;
    end(): void;
    anonymize(value: string): string;
    setTheme(theme: ThemePaletteType): void;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Analytic Session Context
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const AnalyticSessionContext = React.createContext<AnalyticSession | undefined>(undefined);

interface AnalyticSessionProps {
    session: AnalyticSession | undefined;
}

export function AnalyticSessionProvider(props: React.PropsWithChildren<AnalyticSessionProps>) {
    return <AnalyticSessionContext.Provider value={props.session}>{props.children}</AnalyticSessionContext.Provider>;
}

export function useAnalyticSession(): AnalyticSession {
    const session = React.useContext(AnalyticSessionContext);

    if (!session) {
        throw new Error("The analytics session context has not been initialized. Please provide a valid analytics session first.");
    }

    return session;
}
