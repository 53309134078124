import { sortBy } from "lodash";
import * as React from "react";
import type { EnvironmentResource } from "~/client/resources";
import type { ReferenceDataItem } from "~/client/resources/referenceDataItem";
import { ChipIcon, EnvironmentChip, ContextualMissingChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";

export const EnvironmentTypedMultiSelect = MultiSelect<ReferenceDataItem>();

interface EnvironmentMultiSelectBase extends FormFieldProps<string[]> {
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
    accessibleName?: string;
    onRemove?: (id: string) => void;
    fallbackLabel?: string;
    fallbackDescription?: string;
    lookupCollection?: ReferenceDataItem[];
}

interface EnvironmentMultiSelectWithEnviromentResources extends EnvironmentMultiSelectBase {
    environments: EnvironmentResource[];
    items?: never;
}

interface EnvironmentMultiSelectWithoutEnvironmentResources extends EnvironmentMultiSelectBase {
    environments?: never;
    items: ReferenceDataItem[];
}

type EnvironmentMultiSelectProps = EnvironmentMultiSelectWithEnviromentResources | EnvironmentMultiSelectWithoutEnvironmentResources;

export const EnvironmentMultiSelect: React.FC<EnvironmentMultiSelectProps> = (props) => {
    let computedItems: ReferenceDataItem[] = [];

    if (props.environments) {
        computedItems = sortBy(props.environments, (x) => x.SortOrder).map((e) => ({ Id: e.Id, Name: e.Name }));
    } else if (props.items) {
        computedItems = props.items;
    }

    const chipRenderer = (r: EnvironmentResource | SelectItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.lookupCollection ?? computedItems}
                lookupId={r.Id}
                getIdFromElement={(element: ReferenceDataItem) => element.Id}
                render={(item: ReferenceDataItem) => <EnvironmentChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} environmentName={item.Name} />}
                renderFallback={<ContextualMissingChip lookupKey={r.Id} type={ChipIcon.Environment} text={props.fallbackLabel} description={props.fallbackDescription} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <EnvironmentTypedMultiSelect fieldName="environments" renderChip={chipRenderer} accessibleName={props.accessibleName} sortItems={false} items={computedItems} {...props} />;
};
