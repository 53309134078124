import { useState } from "react";
import * as React from "react";
import { DeleteAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/DeleteAuditStreamDialog";
import { EditAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/EditAuditStreamDialog";
import { GreenTickIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/GreenTickIcon";
import { PauseAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseAuditStreamDialog";
import { PauseIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseIcon";
import type { AuditStreamConfigurationResource } from "~/client/resources/auditStreamConfigurationResource";
import ActionButton, { IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { ActionButtonType } from "~/components/Button/index";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";

interface AuditStreamConfiguredButtonProps {
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}

export function AuditStreamConfiguredButton({ auditStreamConfiguration, onUpdate }: AuditStreamConfiguredButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const [openEdit, setOpenEdit] = useState(false);
    const [openPause, setOpenPause] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const pauseOrResume = auditStreamConfiguration.Active ? "Pause" : "Resume";

    const onEditClick = () => {
        setOpenEdit(true);
        menuState.onClose();
    };

    const onPauseClick = () => {
        setOpenPause(true);
        menuState.onClose();
    };

    const onDeleteClick = () => {
        setOpenDelete(true);
        menuState.onClose();
    };

    return (
        <React.Fragment>
            {auditStreamConfiguration.Active ? <GreenTickIcon /> : <PauseIcon />}
            <ActionButton type={ActionButtonType.Ternary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Stream Audit Log" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes} />
            <CustomMenu accessibleName={"Configure audit stream menu"} menuState={menuState} onClose={menuState.onClose}>
                <MenuItemButton key={"edit"} onClick={onEditClick}>
                    <div style={{ minWidth: "7.5rem" }}>Edit</div>
                </MenuItemButton>
                <MenuItemButton key={"pause"} onClick={onPauseClick}>
                    <div>{pauseOrResume}</div>
                </MenuItemButton>
                <MenuItemButton key={"delete"} onClick={onDeleteClick}>
                    <div>Delete</div>
                </MenuItemButton>
            </CustomMenu>
            <EditAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openEdit} close={() => setOpenEdit(false)} />
            <PauseAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openPause} close={() => setOpenPause(false)} />
            <DeleteAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openDelete} close={() => setOpenDelete(false)} />
        </React.Fragment>
    );
}
