import type { LogEvent, LoggingSink } from "@octopusdeploy/logging";
import { createAsyncLoggingSink, globalLogConfiguration } from "@octopusdeploy/logging";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { PropsWithChildren } from "react";
import environment from "~/environment";

export function LogEventCapturer(props: PropsWithChildren<{}>) {
    return environment.isInDevelopmentMode() ? <DevOnlyLogEventCapturer>{props.children}</DevOnlyLogEventCapturer> : <>{props.children}</>;
}

function DevOnlyLogEventCapturer(props: PropsWithChildren<{}>) {
    const [logEvents, setLogEvents] = useState<LogEvent[]>([]);

    const loggingSink: LoggingSink = useMemo(
        () => ({
            receiveLogEvents: (logEvents: LogEvent[]) => setLogEvents((prev) => [...prev, ...logEvents]),
        }),
        []
    );
    useSubscribeToLogEvents(loggingSink);
    const removeAllLogEventsCallback = useCallback(() => setLogEvents([]), []);

    return (
        <logEventsContext.Provider value={logEvents}>
            <removeAllLogEventsContext.Provider value={removeAllLogEventsCallback}>{props.children}</removeAllLogEventsContext.Provider>
        </logEventsContext.Provider>
    );
}

function useSubscribeToLogEvents(logSink: LoggingSink) {
    useEffect(() => {
        // Storing the log event involves setting the state of a react component.
        // We can't synchronously set the state of the react component here, because there are some situations where a log event is logged that would cause that to fail
        // For example, if a component errored in its `componentWillMount` function, then calling logger.error and `setLogEvents` in that call chain would result in an error like
        // "Cannot update a component from inside the function body of a different component"
        // Executing the setLogEvents call asynchronously ensures that the state will be set, regardless of the call site (as long as this component is still mounted)
        const asyncLoggingSink = createAsyncLoggingSink(logSink);
        const removeSink = globalLogConfiguration.attachSink(asyncLoggingSink);

        return () => {
            removeSink();
            asyncLoggingSink.cancelAnyPendingLogEvents();
        };
    }, [logSink]);
}

type RemoveAllLogEvents = () => void;

const logEventsContext = React.createContext<LogEvent[] | null>(null);
const removeAllLogEventsContext = React.createContext<RemoveAllLogEvents | null>(null);

export function useLogEvents() {
    const logEvents = useContext(logEventsContext);
    if (logEvents === null) {
        throw new Error(`useLogEvents not found. Ensure that the LogEventCapturer has been rendered somewhere above this component`);
    }
    return logEvents;
}

export function useRemoveAllLogEvents() {
    const removeAllLogEvents = useContext(removeAllLogEventsContext);
    if (removeAllLogEvents === null) {
        throw new Error(`useRemoveAllLogEvents not found. Ensure that the LogEventCapturer has been rendered somewhere above this component`);
    }
    return removeAllLogEvents;
}
