/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import SvgIcon from "@material-ui/core/SvgIcon";
import { logger } from "@octopusdeploy/logging";
import classnames from "classnames";
import * as React from "react";
import { ActionHandlerCategory } from "~/client/resources";
import type { ActionTemplateSearchResource } from "~/client/resources/actionTemplateSearchResource";
import Logo from "~/components/Logo/Logo";
import { withTheme } from "~/components/Theme";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./ActionTemplateCategory.module.less";
import ActionTemplateCategoryIconHelper from "./ActionTemplateCategoryIconHelper";

interface ActionTemplateCategoryProps {
    category: ActionHandlerCategory;
    name: string;
    templates: ActionTemplateSearchResource[];
    active: boolean;
    isBlurred?: boolean;
    isSelectable?: boolean;
    onCategorySelected: () => void;
}

const ActionTemplateCategory: React.StatelessComponent<ActionTemplateCategoryProps> = (props: ActionTemplateCategoryProps) => {
    let iconUrl: string | null = null;
    let iconSvgPath: JSX.Element | null = null;
    try {
        if (props.category === ActionHandlerCategory.CommunitySubCategory) {
            iconUrl = props.templates && props.templates.length > 0 ? props.templates[0].Links.Logo : null;
        } else {
            // We have custom SVG icons for our primary categories (that switch fill based on active state).
            iconSvgPath = ActionTemplateCategoryIconHelper.getCategorySvgElement(props.category, props.active);
        }
    } catch (err) {
        logger.info("Failed to find icon for {category}. Falling back to first template's logo.", { category: props.category, categoryName: props.name });
        iconUrl = props.templates && props.templates.length > 0 ? props.templates[0].Links.Logo : null;
        iconSvgPath = null;
    }

    return withTheme((theme) => (
        <li
            className={classnames(styles.actionCategory, props.active ? styles.active : styles.inactive, props.isSelectable ? styles.isFiltering : styles.isSelectable, props.isBlurred ? styles.blurred : null)}
            onClick={props.isSelectable ? undefined : props.onCategorySelected}
        >
            {iconUrl && <Logo url={iconUrl} size="3.125rem" />}
            {iconSvgPath && (
                <SvgIcon htmlColor={props.active ? theme.iconLight : theme.iconDark} viewBox="0 0 70 70" style={{ width: "3.125rem", height: "3.125rem", fill: props.active ? theme.iconLight : theme.iconDark }}>
                    {iconSvgPath}
                </SvgIcon>
            )}
            <div className={styles.categoryName}>
                <small>{props.name}</small>
            </div>
            {props.active && (
                <div className={styles.activeIcon}>
                    <ThirdPartyIcon iconType={ThirdPartyIconType.Cancel} color={theme.cyanConstant} />
                </div>
            )}
        </li>
    ));
};

ActionTemplateCategory.displayName = "ActionTemplateCategory";

export default ActionTemplateCategory;
