import { useRouteMatch } from "react-router";

export function useIsPageVersionControlled() {
    const match = useRouteMatch();

    if (!match) {
        return false;
    }

    // Todo @team-config-as-code: This is pretty hacky, and it'll fail when we change the parameter name.
    //  Would be good to centralise it, or find a better solution.
    const paramKeys = Object.keys(match.params);
    const isVersionControlledPage = paramKeys.includes("branchName");

    return isVersionControlledPage;
}
