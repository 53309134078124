import cn from "classnames";
import React from "react";
import { Permission } from "~/client/resources";
import { NavigationButton, NavigationButtonType } from "~/components/Button/index";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "~/components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import GettingStartedButton from "~/components/ProjectBasedActivation/GettingStartedButton";
import projectImgNoActionDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-project-noaction.svg";
import projectImgDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-project.svg";
import projectImgNoAction from "~/components/ProjectBasedActivation/assets/img-project-noaction.svg";
import projectImg from "~/components/ProjectBasedActivation/assets/lightimg-project.svg";
import styles from "~/components/ProjectBasedActivation/styles.module.less";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import routeLinks from "~/routeLinks";

interface DashboardOnboardingLayoutProps {
    canContinueOnboarding: boolean;
    onboardingProject?: { Name: string; Slug: string };
}
function DashboardOnboardingLayout({ canContinueOnboarding, onboardingProject }: DashboardOnboardingLayoutProps) {
    const isDarkMode = useThemePaletteType() === "dark";
    const image = isDarkMode ? projectImgDark : projectImg;
    return (
        <PermissionCheck permission={Permission.ProjectCreate} alternate={<NoActionDashboard />}>
            <div className={styles.dashboardOnboardingLayout}>
                <div className={styles.dashboardOnboardingText}>
                    {canContinueOnboarding && onboardingProject !== undefined ? (
                        <div>
                            <p>Continue where you left off:</p>
                            <div className={styles.continueOnboardingPanel}>
                                <div>
                                    <strong>{onboardingProject.Name}</strong>
                                </div>
                                <NavigationButton label="Continue Project Setup" href={`${routeLinks.project(onboardingProject.Slug).overview}?newlyCreatedProject=true`} type={NavigationButtonType.Primary} />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1>Deploy your first application</h1>
                            <div>We'll guide you along the process of adding your first project through to releasing your first deployment.</div>
                            <GettingStartedButton />
                        </div>
                    )}
                </div>
                <img src={image} className={styles.dashboardOnboardingImage} alt={"Projects"} />
            </div>
        </PermissionCheck>
    );
}

const NoActionDashboard = () => {
    const [showFundamentals, setShowFundamentals] = React.useState(false);
    const isDarkMode = useThemePaletteType() === "dark";

    const image = isDarkMode ? projectImgNoActionDark : projectImgNoAction;

    return (
        <div className={styles.dashboardOnboardingLayout}>
            <CustomDialog open={showFundamentals} close={() => setShowFundamentals(false)} render={(renderProps) => <FundamentalsGuideDialogLayout {...renderProps} />} />
            <div className={styles.dashboardOnboardingText}>
                <h1>Your deployment hub</h1>
                <div>Octopus captures critical information and presents it in an easy to understand dashboard, telling your team at a glance what's deployed where.</div>
                <h4>Getting started</h4>
                <div>
                    <ul className={styles.linkList}>
                        <li>
                            <ExternalVideoLink href="GettingStartedVideos">Tutorial videos</ExternalVideoLink>
                        </li>
                        <li>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowFundamentals(true);
                                }}
                            >
                                <em className={cn("fa", "fa-map", styles.icon)} /> Quick Guide
                            </a>
                        </li>
                    </ul>
                </div>
                <h4>Need help?</h4>
                <div>
                    Look out for the <ThirdPartyIcon iconType={ThirdPartyIconType.HelpOutline} style={{ verticalAlign: "middle" }} /> icon to access help and related resources.
                </div>
            </div>
            <img src={image} className={styles.dashboardOnboardingImage} alt={"Projects"} />
        </div>
    );
};

export default DashboardOnboardingLayout;
