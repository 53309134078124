import * as React from "react";
import { Action, Plan, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { EditAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/EditAuditStreamDialog";
import type { AuditStreamConfigurationResource } from "~/client/resources/auditStreamConfigurationResource";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
import { ActionButtonType } from "~/components/Button/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";

interface AuditStreamNotConfiguredButtonProps {
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}

export function AuditStreamNotConfiguredButton({ auditStreamConfiguration, onUpdate }: AuditStreamNotConfiguredButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();

    const handleClick = () => {
        dispatchAction("Stream Audit Log", { resource: "Audit Stream", action: Action.Add, plan: Plan.Enterprise });
    };

    return (
        <React.Fragment>
            <ActiveStatusIcon active={false} style={{ marginTop: "15px" }} />
            <OpenDialogButton
                type={ActionButtonType.Ternary}
                label="Stream Audit Log"
                wideDialog={true}
                onClick={handleClick}
                renderDialog={(renderProps) => <EditAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={renderProps.open} close={() => renderProps.closeDialog()} />}
            />
        </React.Fragment>
    );
}
