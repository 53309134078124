import type { DeploymentActionResource } from "~/client/resources/deploymentActionResource";
import { client } from "~/clientInstance";

export default function getActionLogoUrl(action: DeploymentActionResource | null) {
    if (!action || !action.Properties) {
        return "";
    }

    const templateId = action.Properties["Octopus.Action.Template.Id"];
    const version = action.Properties["Octopus.Action.Template.Version"];
    const stepPackageVersion = action.StepPackageVersion;

    if (stepPackageVersion !== undefined) {
        const majorVersion = stepPackageVersion.split(".")[0];
        return client.resolveLinkTemplate("ActionTemplateVersionedLogo", { typeOrId: action.ActionType, version: majorVersion });
    }

    return templateId ? client.resolveLinkTemplate("ActionTemplateVersionedLogo", { typeOrId: templateId, version }) : client.resolveLinkTemplate("ActionTemplateLogo", { typeOrId: action.ActionType });
}
