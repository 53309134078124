import type { TenantVariableResource } from "~/client/resources/tenantVariableResource";
import type { Client } from "../client";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";

type TenantVariableListArgs = {
    projectId?: string;
} & ListArgs;

type TenantVariableAllArgs = {
    projectId?: string;
} & AllArgs;

export class TenantVariableRepository extends BasicRepository<TenantVariableResource, TenantVariableResource, TenantVariableListArgs, TenantVariableAllArgs> {
    constructor(client: Client) {
        super("TenantVariables", client);
    }
}
