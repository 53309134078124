import * as React from "react";
import type { ProjectContextRepository } from "~/client/repositories/projectContextRepository";
import type { AccountResource, IProcessResource } from "~/client/resources";
import { ProcessType } from "~/client/resources";
import type { ActionTemplateSearchResource } from "~/client/resources/actionTemplateSearchResource";
import type { FeedResource } from "~/client/resources/feedResource";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks";
import { useBoundDispatch } from "~/utils/Reducers";
import type { ProcessContextModelState, ProcessPageSupportedActions } from "../types";
import type { ProcessActions, ProcessStateSelectors } from "./ProcessContextState";
import { actions } from "./ProcessContextState";

export interface ProcessContextLookupState {
    actionTemplates: ActionTemplateSearchResource[];
    feeds: FeedResource[];
    accounts: AccountResource[];
}

export type ProcessContextProps = {
    state: ProcessContextModelState;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
};

export const ProcessContext = React.createContext<ProcessContextProps | undefined>(undefined);

export const useProcessContext = () => {
    return useRequiredContext(ProcessContext, "Process");
};

export const useOptionalProcessContext = () => {
    return React.useContext(ProcessContext);
};

export const loadProcess = (projectContextRepository: ProjectContextRepository, processType: ProcessType, id: string): Promise<IProcessResource> => {
    if (processType === ProcessType.Deployment) {
        return projectContextRepository.DeploymentProcesses.get();
    } else if (processType === ProcessType.Runbook) {
        return repository.RunbookProcess.get(id);
    }
    throw new Error("Unknown scope provided");
};

export interface ProcessContextProviderSetup {
    lookupsState: ProcessContextLookupState;
    state: ProcessContextModelState;
    setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
}

type BoundActionsType = Omit<ReturnType<typeof useBoundProcessActions>, "setProcess" | "conflictDetected">;

export interface ProcessContextProviderSetupActions extends ProcessPageSupportedActions, BoundActionsType {
    saveOnServer: (projectContextRepository: ProjectContextRepository, process: IProcessResource, onError: (errors: Errors) => void, onSuccess: () => void) => Promise<IProcessResource | null>;
    refreshFromServer: () => Promise<boolean>;
    setProcess: (process: IProcessResource, updateCleanModel: boolean) => Promise<void>;
    conflictDetected: (serverProcess: IProcessResource, stagedProcess: IProcessResource) => Promise<void>;
}

export const useBoundProcessActions = (dispatch: React.Dispatch<ProcessActions>) => {
    return useBoundDispatch(dispatch, actions);
};

export interface WithProcessContextInjectedProps {
    processContext: ProcessContextProps;
}

export function withProcessContext<T>(Component: React.ComponentType<T & WithProcessContextInjectedProps>) {
    const WithProcessContext: React.FC<T> = (props) => {
        const context = useProcessContext();
        return <Component processContext={context} {...props} />;
    };
    return WithProcessContext;
}

export type WithOptionalProcessContextInjectedProps = Partial<WithProcessContextInjectedProps>;

export function withOptionalProcessContext<T>(Component: React.ComponentType<T & WithOptionalProcessContextInjectedProps>) {
    const WithOptionalProcessContext: React.FC<T> = (props) => {
        const context = useOptionalProcessContext();
        return <Component processContext={context} {...props} />;
    };
    return WithOptionalProcessContext;
}
