import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import type { GitRefDropDownMode } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import GitRefDropDown, { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { GitBranchResource, GitRefResource, ProjectResource, ResourceCollection, ValidateGitRefV2Response } from "~/client/resources/index";
import { ValidateGitRefV2ResponseType } from "~/client/resources/index";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { getGitRefType, GitRefType } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { getBranchesList, getFilteredBranchesList } from "~/utils/BranchHelpers/BranchHelpers";

interface GitRefSelectorProps {
    project: Readonly<ProjectResource>;
    initialGitRef: GitRef | undefined;
    onChange: (selectedGitRef: GitRef) => void;
    onCreateBranch?: (newBranchName: string, parentGitRef: GitRef) => Promise<GitBranchResource>;
    onError?: (error: Error) => void;
    gitError?: ValidateGitRefV2Response;
    disabled?: boolean;
    style?: "grey" | "white";
    mode?: GitRefDropDownMode;
    allowBranchCreation?: boolean;
}

interface GitRefSelectorInternalProps extends GitRefSelectorProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    items: GitRefOption[];
    totalItemCount: number;
    loadItems?: (refType: RefTypes, selectedGitRef: GitRef | undefined) => void;
}

function GitRefSelectorInternal(props: GitRefSelectorInternalProps) {
    const mapToRefTypes = (gitRefType: GitRefType): RefTypes => {
        switch (gitRefType) {
            case GitRefType.Branch:
                return RefTypes.Branches;
            case GitRefType.Tag:
                return RefTypes.Tags;
            case GitRefType.Commit:
                return RefTypes.Commits;
            case GitRefType.Unknown:
                // We used to throw an error here, but that would cause pages to crash if we ever had a
                // gitRef passed in that was not fully qualified. Instead, we will just default to branches
                // to keep the UI working
                return RefTypes.Branches;
        }
    };

    const { initialGitRef, project, onChange, onCreateBranch, loadItems } = props;

    const [refType, setRefType] = React.useState<RefTypes>(initialGitRef ? mapToRefTypes(getGitRefType(initialGitRef)) : RefTypes.Branches);
    const isFormDirty = useSelector((state: GlobalState) => state.formPaperLayout.dirty);
    const branchProtectionsAreEnabled = useEnabledFeatureToggle("BranchProtectionsFeatureToggle");

    useEffect(() => {
        if (loadItems) loadItems(refType, initialGitRef);
    }, [initialGitRef, loadItems, refType]);

    const search = async (searchTerm: string): Promise<GitRefOption[]> => {
        try {
            if (refType === RefTypes.Tags) {
                const tagResources = await repository.Projects.searchTags(project, searchTerm);
                return tagResources.Items.map((t) => ({
                    text: t.Name,
                    value: t.CanonicalName,
                    canWrite: false,
                }));
            } else {
                // Fallback to branches
                return getFilteredBranchesList(project, searchTerm);
            }
        } catch (e) {
            if (props.onError) props.onError(e);
            return [];
        }
    };

    const onCreateBranchLocal = async (newBranchName: string, baseGitRef: GitRef): Promise<void> => {
        // If no 'onCreateBranch' handler has been given via props, and somehow
        // we've ended up in here, then something has gone wrong.
        if (!onCreateBranch) throw new Error("onCreateBranch was called, but no handler was given via props");

        const gitRefResource = await onCreateBranch(newBranchName, baseGitRef);
        onChange(gitRefResource.CanonicalName);
    };

    const onRefTypeChanged = (refType: RefTypes) => setRefType(refType);

    // noinspection JSArrowFunctionBracesCanBeRemoved
    const onRequestRefresh = async (selectedGitRef: GitRef): Promise<void> => {
        if (loadItems) {
            loadItems(refType, selectedGitRef);
        }
    };

    const connectionFailed = props.gitError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed;

    return (
        <>
            <GitRefDropDown
                isBusySearching={props.busy !== null && props.busy !== undefined}
                style={props.style}
                value={initialGitRef}
                items={props.items}
                totalItems={props.totalItemCount}
                onChange={(gitRefOption: GitRefOption) => onChange(gitRefOption.value)}
                onRequestRefresh={onRequestRefresh}
                onFilterChanged={search}
                disabled={props.disabled || connectionFailed}
                onCreateBranch={onCreateBranchLocal}
                projectId={project.Id}
                refType={refType}
                onRefTypeChanged={onRefTypeChanged}
                mode={props.mode}
                allowBranchCreation={props.allowBranchCreation}
                disableBranchCreation={isFormDirty || !initialGitRef}
                errorMessage={connectionFailed ? "Connection error" : props.initialGitRef ? undefined : "Unknown git ref"}
                branchProtectionsAreEnabled={branchProtectionsAreEnabled}
            />
        </>
    );
}

interface GitRefSelectorState extends DataBaseComponentState {
    project: Readonly<ProjectResource>;
    items: GitRefOption[];
    totalCount: number;
}

export class GitRefSelector extends DataBaseComponent<GitRefSelectorProps, GitRefSelectorState> {
    constructor(props: GitRefSelectorProps) {
        super(props);
        this.state = {
            project: props.project,
            items: [],
            totalCount: 0,
        };
    }

    static getDerivedStateFromProps(props: GitRefSelectorProps, state: GitRefSelectorState) {
        return {
            ...state,
            project: props.project,
        };
    }

    loadItems = (refType: RefTypes, selectedGitRef: GitRef | undefined): void => {
        this.doBusyTask(async () => {
            try {
                let resources: ResourceCollection<GitRefResource> | null = null;
                let items: GitRefOption[] = [];

                if (refType === RefTypes.Branches) {
                    const branchResources: ResourceCollection<GitBranchResource> = await repository.Projects.getBranches(this.state.project);
                    resources = branchResources;
                    items = await getBranchesList(this.state.project, branchResources.Items, selectedGitRef);
                } else if (refType === RefTypes.Tags) {
                    resources = await repository.Projects.getTags(this.state.project);
                    items = resources.Items.map((t) => ({
                        text: t.Name,
                        value: t.CanonicalName,
                        canWrite: false,
                    }));
                }

                this.setState({
                    ...this.state,
                    items,
                    totalCount: resources?.TotalResults ?? 0,
                });
            } catch (e) {
                if (this.props.onError) this.props.onError(e);
            }
        });
    };

    render() {
        return <GitRefSelectorInternal items={this.state.items} totalItemCount={this.state.totalCount} doBusyTask={this.doBusyTask} loadItems={this.loadItems} errors={this.errors} busy={this.state.busy} {...this.props}></GitRefSelectorInternal>;
    }
}
