import { color } from "@octopusdeploy/portal-design-system-tokens";

const blue10 = "#E6F1FA";
export const baseColors = {
    // Base colors
    transparent: "transparent",
    cyan: "#0D80D8",
    legacyNavy: "#0F2535",
    legacyGreen: "#00B065",
    octopusBlue: {
        50: "#ecf5fb",
        100: "#c1dff6",
        200: "#97c9f0",
        300: "#6db3e9",
        400: "#4ea3e5",
        500: "#2f93e0",
        600: "#2a8bdc",
        700: "#2380d8",
        800: "#1d76d3",
        900: "#1264cb",
        A100: "#fafcff",
        A200: "#c7deff",
        A400: "#94bfff",
        A700: "#7ab0ff",
    },

    // Tints
    cyan10: "#E9EDF2",
    cyan20: "#ecf5fb",
    cyan30: "#86CAFF", //86CAFF
    cyan40: "#3679AC",
    cyan50: "#0A67B1",
    cyan60: "#053B62",
    cyan70: "#3C5A71",

    navy10: "#E3EEF6",
    navy20: "#C3CEDC",
    navy30: "#8B9EAC",
    navy40: "#6D7D87",
    navy50: "#0E3655", //324553
    navy60: "#13354F", //273B49
    navy70: "#112E44", //1B303F
    navy90: "#07121A", //1B303F

    grey10: "#FAFAFA",
    grey20: "#F2F2F2",
    grey30: "#E0E0E0",
    grey40: "#BCBCBC",
    grey50: "#9e9e9e",
    grey60: "#666666",
    grey70: "#333333",

    green10: "#F1F9F1",
    green20: "#A9D4A9",
    green30: "#5EAE5E",
    green40: "#497F49",
    green50: "#449744",
    green60: "#23513D",
    green70: "#173628",

    // Additional colors
    legacyRed: "#db4437",
    red10: "#f8e8e6", //f8e8e6
    red20: "#D8655E",
    red30: "#F2827B",
    red40: "#C14136",
    red50: "#8F5652",
    red60: "#4C2D35",
    red70: "#311A20",

    legacyOrange: "#fb8c00",
    orange10: "#fcf1e2",
    orange20: "#DAA25B",
    orange30: "#DC953A",
    orange40: "#423113",

    legacyPurple: "#441c7f",
    purple10: "#f4ebf9",
    purple20: "#C8B3D3",
    purple30: "#BD90FF",
    purple40: "#4E3574",

    legacyBlue: "#0878D1",
    blue10,
    blue20: "#A0CFF4",
    blue30: "#0C3D63",

    legacyYellow: "#DECB6B",
    highlightText: "#FFFF99",
    highlightTextBackground: "black",

    octopusGreen: {
        50: "#e9f6ea",
        100: "#c8e8cb",
        200: "#a4d9a8",
        300: "#7fca85",
        400: "#63be6a",
        500: "#48b350",
        600: "#41ac49",
        700: "#38a340",
        800: "#309a37",
        900: "#218b27",
        A100: "#c8ffca",
        A200: "#95ff9a",
        A400: "#62ff69",
        A700: "#48ff51",
    },

    // Color object for #48b350 along with shades and accent colors
    diffAddedBackground: "#e6ffed",
    diffWordAddedBackground: "#acf2bd",
    diffRemovedBackground: "#ffeef0",
    diffWordRemovedBackground: "#fdb8c0",
    diffInfoBackground: blue10,

    shadow40: "rgba(0, 0, 0, 0.4)",
    shadow60: "rgba(0, 0, 0, 0.6)",
    shadow20: "rgba(0, 0, 0, 0.2)",
    shadow12: "rgba(0, 0, 0, 0.12)",
    shadow14: "rgba(0, 0, 0, 0.14)",
    shadow25: "rgba(0, 0, 0, 0.25)",
    shadow22: "rgba(0, 0, 0, 0.22)",
    shadow34: "rgba(0, 0, 0, 0.34)",
    ...color.scale,
} as const;
