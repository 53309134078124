import * as React from "react";
import type { ActionProperties } from "~/client/resources/actionProperties";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ExpandableFormSection } from "~/components/form";
import { default as Summary } from "~/components/form/Sections/Summary";
import { BoundSensitive } from "~/components/form/Sensitive/Sensitive";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import type { WildflyProperties } from "./wildflyProperties";

interface WildflyConnectionActionEditProps<T = ActionProperties> extends ActionEditProps<T> {
    applicationServerHostnameNote: JSX.Element;
    actionDescription: JSX.Element;
}

/**
 * A component that displays the connection details for a Wildfly server
 */
export default abstract class WildflyConnectionDetailsComponent extends BaseComponent<WildflyConnectionActionEditProps<WildflyProperties>, never> {
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.props.properties["WildFly.Deploy.Controller"]) {
                this.props.properties["WildFly.Deploy.Controller"] = "localhost";
            }
            if (!this.props.properties["WildFly.Deploy.Port"]) {
                this.props.properties["WildFly.Deploy.Port"] = "9990";
            }
            if (!this.props.properties["WildFly.Deploy.Protocol"]) {
                this.props.properties["WildFly.Deploy.Protocol"] = "remote+http";
            }
            if (this.props.properties["WildFly.Deploy.ServerType"] !== "Standalone" && this.props.properties["WildFly.Deploy.ServerType"] !== "Domain") {
                this.props.properties["WildFly.Deploy.ServerType"] = "Standalone";
            }
        });
    }

    render() {
        const properties = this.props.properties;
        const applicationServerErrorKey = "WildFly.Deploy.Controller|" + "WildFly.Deploy.Port|" + "WildFly.Deploy.Protocol|" + "WildFly.Deploy.User|" + "WildFly.Deploy.Password";

        return (
            <div>
                <ExpandableFormSection errorKey={applicationServerErrorKey} isExpandedByDefault={this.props.expandedByDefault} title="Application Server Details" summary={this.wildflySummary()} help="Specify the WildFly server details">
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["WildFly.Deploy.Controller"]}
                        onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Controller"]: x })}
                        label="Management host or IP"
                        error={this.props.getFieldError("WildFly.Deploy.Controller")}
                    />
                    <Note>
                        {this.props.applicationServerHostnameNote} This value is relative to the host that is executing the step. So if the Tentacle is on the same host as WildFly/Red Hat JBoss EAP, then this value may be something like{" "}
                        <em>localhost</em>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["WildFly.Deploy.Port"]}
                        onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Port"]: x })}
                        label="Management port"
                        error={this.props.getFieldError("WildFly.Deploy.Port")}
                    />
                    <Note>
                        This is the management port that the application server is listening to. For WildFly 10+ and Red Hat JBoss EAP 7+, the default is <em>9990</em>. For Red Hat JBoss EAP 6, the default is <em>9999</em>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["WildFly.Deploy.Protocol"]}
                        onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Protocol"]: x })}
                        label="Management protocol"
                        error={this.props.getFieldError("WildFly.Deploy.Protocol")}
                    />
                    <Note>
                        This is the management protocol that the application server is listening to. For WildFly 10+ and Red Hat JBoss EAP 7+, the default is <em>remote+http</em>, or <em>remote+https</em> if the management port is configured to use
                        HTTPS. For Red Hat JBoss EAP 6, the default is <em>remoting</em>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={properties["WildFly.Deploy.User"]} onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.User"]: x })} label="Management user" />
                    <Note>
                        The username to use with the management interface. This is typically configured via the
                        <em> add-user</em> script. By default WildFly does not require credentials for local connections (i.e. if the <em>Management Host or IP</em> is localhost or 127.0.0.1).
                    </Note>
                    <BoundSensitive
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["WildFly.Deploy.Password"]}
                        onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Password"]: x })}
                        label="Management password"
                    />
                    <Note>
                        The password to use with the management interface. This is typically configured via the
                        <em> add-user</em> script. By default WildFly does not require credentials for local connections (i.e. if the <em>Management Host or IP</em> is localhost or 127.0.0.1).
                    </Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private wildflySummary() {
        const properties = this.props.properties;
        if (properties["WildFly.Deploy.Controller"]) {
            return Summary.summary(
                <span>
                    <span>{this.props.actionDescription} an application via the management interface listening on</span>
                    {properties["WildFly.Deploy.Protocol"] && <strong>&nbsp;{properties["WildFly.Deploy.Protocol"] + `://`}</strong>}
                    <strong>{properties["WildFly.Deploy.Controller"]}</strong>
                    {properties["WildFly.Deploy.Port"] && <strong>:{properties["WildFly.Deploy.Port"]}</strong>}
                    {properties["WildFly.Deploy.User"] && (
                        <span>
                            &nbsp;with user <strong>{properties["WildFly.Deploy.User"]}</strong>
                        </span>
                    )}
                </span>
            );
        }
        return Summary.placeholder(<span>Specify the management hostname, port, protocol and credentials</span>);
    }
}
