/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { capitalize } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { GreenTickIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/GreenTickIcon";
import { PauseIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseIcon";
import { getAuditStreamType, createNewAuditStreamConfiguration } from "~/areas/configuration/components/AuditLayout/AuditStream/utils";
import type { AuditStreamConfigurationResource } from "~/client/resources/auditStreamConfigurationResource";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import Dialog from "~/components/Dialog/Dialog";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import type { OctopusTheme } from "~/components/Theme/index";
import { useOctopusTheme } from "~/components/Theme/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";

interface PauseAuditStreamDialogInternalProps {
    open: boolean;
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
    theme: OctopusTheme;
    trackAction: AnalyticTrackedActionDispatcher;
}

class PauseAuditStreamDialogInternal extends DataBaseComponent<PauseAuditStreamDialogInternalProps> {
    constructor(props: PauseAuditStreamDialogInternalProps) {
        super(props);
        this.state = {};
    }

    async handlePauseOrResume() {
        const { onUpdate, trackAction, close } = this.props;
        const action = this.pauseOrResume();
        const auditStreamType = getAuditStreamType(this.props.auditStreamConfiguration.StreamConfigurationResource!);

        const auditStreamConfiguration = this.props.auditStreamConfiguration;
        const newAuditStreamConfiguration = createNewAuditStreamConfiguration(
            auditStreamConfiguration.Id,
            !auditStreamConfiguration.Active,
            auditStreamConfiguration.Description,
            auditStreamConfiguration.StreamConfigurationResource,
            auditStreamConfiguration.Links
        );
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: action === "pause" ? Action.Pause : Action.Resume,
                resource: "Audit Stream",
            };
            const actionName = action ? "Pause Audit Stream" : "Resume Audit Stream";
            await trackAction(actionName, ev, async () => {
                await repository.AuditStreamConfiguration.modify(newAuditStreamConfiguration);
                onUpdate({ ...newAuditStreamConfiguration }, `${auditStreamType} streaming is ${action}d`);
                close();
                return true;
            });
        });
        return false;
    }

    pauseOrResume() {
        return this.props.auditStreamConfiguration.Active ? "pause" : "resume";
    }

    cancel() {
        this.props.close();
        return true;
    }

    render() {
        const { auditStreamConfiguration, open, theme } = this.props;
        const action = this.pauseOrResume();
        const auditStreamType = getAuditStreamType(auditStreamConfiguration.StreamConfigurationResource!);
        return (
            <Dialog open={open}>
                <SaveDialogLayout
                    title={`Are you sure you want to ${action} this stream?`}
                    busy={this.state.busy}
                    saveButtonDisabled={false}
                    onSaveClick={async () => this.handlePauseOrResume()}
                    saveButtonLabel={`${capitalize(action)} Stream`}
                    onCancelClick={() => this.cancel()}
                    busyButtonLabel={action === "pause" ? "Pausing..." : "Resuming..."}
                >
                    <p>
                        Status:{" "}
                        {auditStreamConfiguration.Active ? (
                            <>
                                <GreenTickIcon />{" "}
                                <strong>
                                    <span style={{ color: theme.success }}>Streaming to {auditStreamType}</span>
                                </strong>
                            </>
                        ) : (
                            <>
                                <PauseIcon />{" "}
                                <strong>
                                    <span style={{ color: theme.danger }}>{auditStreamType} streaming is paused</span>
                                </strong>
                            </>
                        )}
                    </p>
                    {auditStreamConfiguration.Description && (
                        <p>
                            Description: <strong>{auditStreamConfiguration.Description}</strong>
                        </p>
                    )}
                    <Callout title={`This will ${action} Octopus streaming to your specified ${auditStreamType} endpoint`} type={CalloutType.Information}>
                        You can {action} streaming at any time.
                    </Callout>
                </SaveDialogLayout>
            </Dialog>
        );
    }
}

export interface PauseAuditStreamDialogProps {
    open: boolean;
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}

export function PauseAuditStreamDialog(props: PauseAuditStreamDialogProps) {
    const theme = useOctopusTheme();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <PauseAuditStreamDialogInternal {...props} theme={theme} trackAction={trackAction} />;
}
