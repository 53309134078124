/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ProxyResource } from "~/client/resources";
import type { Client } from "../client";
import { BasicRepository } from "./basicRepository";

export class ProxyRepository extends BasicRepository<ProxyResource, any> {
    constructor(client: Client) {
        super("Proxies", client);
    }
}
