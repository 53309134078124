import * as React from "react";
import type { CertificateConfigurationResource } from "~/client/resources";
import { Permission } from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button/NavigationButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Note } from "~/components/form";
import ServerThumbprint from "../../../../components/ServerThumbprint/ServerThumbprint";
import routeLinks from "../../../../routeLinks";

interface ThumbprintState extends DataBaseComponentState {
    certificate?: CertificateConfigurationResource;
}

export class Thumbprint extends DataBaseComponent<{}, ThumbprintState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const certificate = await repository.CertificateConfiguration.global();
            this.setState({ certificate });
        });
    }

    render() {
        const sidebar = (
            <div>
                <h4>Octopus → Windows Azure</h4>
                <p>
                    Octopus authenticates with Windows Azure using Management Certificates. Grant this Octopus Server rights to your Azure Subscriptions by creating an{" "}
                    {isAllowed({ permission: Permission.AccountEdit, wildcard: true }) ? <InternalLink to={routeLinks.infrastructure.accounts.root}>Account</InternalLink> : <span>Account</span>} for each one.
                </p>
                <PermissionCheck permission={Permission.AccountEdit} wildcard={true}>
                    <NavigationButton href={routeLinks.infrastructure.accounts.root} label="Manage Accounts" />
                </PermissionCheck>
            </div>
        );

        const cert = this.state.certificate;

        const body = cert && (
            <Section>
                <Note>The server thumbprint below is used to identify this Octopus Server to any Tentacles when executing a deployment. You'll need the thumbprint when manually adding new Tentacle deployment targets to an environment.</Note>
                <p>
                    The server certificate uses the <strong>{cert.SignatureAlgorithm}</strong> algorithm.
                    <ServerThumbprint thumbprint={cert.Thumbprint} />
                </p>
            </Section>
        );

        return (
            <PaperLayout title="Thumbprint" busy={this.state.busy} errors={this.errors}>
                <SidebarLayout sideBar={sidebar}>{body}</SidebarLayout>
            </PaperLayout>
        );
    }
}

export default Thumbprint;
