import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import type { StepPackageLinks } from "./actionTemplateSearchResource";
import type { StepPackageInputs } from "./stepPackageInputs";

export type StepUI = unknown;

export enum ExecutionLocation {
    ServerOrWorker = "ServerOrWorker",
    ServerOrWorkerOrTarget = "ServerOrWorkerOrTarget",
}

export interface StepPackage {
    name: string;
    description: string;
    version: string;
    stepUI: StepUI;
    schema: InputJsonSchema;
    prerelease: boolean;
    releaseNotesUrl?: string;
    executionLocation: ExecutionLocation;
    requiresTargetRole: boolean;
    targetDiscoveryCloudConnectionTypes: Array<CloudConnectionType>;
}

export type MigrateStepPackageInputsBody = {
    targetMajorVersion: number;
    inputs: StepPackageInputs;
};

export type MigrateStepPackageInputsResponse = {
    StepPackageId: string;
    StepPackageVersion: string;
    Inputs: StepPackageInputs;
};

export type StepPackageDetailsResponse = {
    Name: string;
    Description: string;
    Version: string;
    Prerelease: boolean;
    ReleaseNotesUrl?: string;
    ExecutionLocation: ExecutionLocation;
    RequiresTargetRole: boolean;
    Links: StepPackageLinks;
    TargetDiscoveryCloudConnectionTypes: Array<CloudConnectionType>;
};

export type CloudConnectionType = "Aws" | "Azure";
