/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { cloneDeep, isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import type {
    EnvironmentResource,
    ChannelResource,
    ProjectResource,
    CronTriggerScheduleResource,
    DaysPerMonthTriggerScheduleResource,
    TriggerResourceTyped,
    NewTriggerResourceTyped,
    TriggerResource,
    TenantResource,
    ServerTimezoneResource,
    LifecycleResource,
    RunbookResource,
    ContinuousDailyTriggerScheduleResource,
    TriggerScheduleResource,
    GitPersistenceSettings,
    ScopedDeploymentActionResource,
    ScheduleIntervalResource,
} from "~/client/resources";
import {
    Permission,
    DayOfWeek,
    DeployLatestReleaseActionResource,
    DeployNewReleaseActionResource,
    TriggerFilterType,
    TriggerActionType,
    isExistingTriggerResource,
    TenantedDeploymentMode,
    RunRunbookActionResource,
    TriggerActionCategory,
    OnceDailyTriggerScheduleResource,
    PersistenceSettingsType,
} from "~/client/resources";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, MarkdownEditor, Note, RadioButton, required, Select, StringRadioButtonGroup, Summary, Text, UnstructuredFormSection } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper/StringHelper";
import type { GitRef } from "../../../../../client/resources/versionControlledResource";
import { toGitBranch } from "../../../../../client/resources/versionControlledResource";
import GitRefFormSection from "../../Releases/Edit/GitRefFormSection";
import { DeployLatestReleaseActionEditor, DeployNewReleaseActionEditor, RunRunbookActionEditor } from "./ActionEditors";
import { DeployLatestReleaseWithNoLifecycleActionEditor } from "./ActionEditors/DeployLatestReleaseWithNoLifecycleActionEditor";
import { CronExpressionScheduledTriggerEditor, DailyScheduledTriggerEditor, DaysPerMonthScheduledTriggerEditor } from "./ScheduleEditors";

type Model =
    | TriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>
    | NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>
    | NewTriggerResourceTyped<TriggerScheduleResource, RunRunbookActionResource>;

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

enum OtherTriggerFilterType {
    DailyOnceOrContinuous = "DailyOnceOrContinuous",
}

type CombinedTriggerFilterType = TriggerFilterType | OtherTriggerFilterType;

interface EditState extends OptionalFormBaseComponentState<Model> {
    runbooks: RunbookResource[];
    environments: EnvironmentResource[];
    channels: ChannelResource[];
    tenants: TenantResource[];
    project: ProjectResource;
    channelId?: string;
    redirectTo?: string;
    interval: ScheduleIntervalResource;
    timezones: ServerTimezoneResource[];
    lifecycle: LifecycleResource | null;
    filterType: CombinedTriggerFilterType;
}

export type EditScheduledTriggerRouteProps = RouteComponentProps<ProjectRouteParams & { triggerId: string; runbookId: string }>;

export interface EditScheduledTriggerModeProps {
    create: boolean;
    triggerActionCategory: TriggerActionCategory;
}

export type EditScheduledTriggerProps = EditScheduledTriggerModeProps & EditScheduledTriggerRouteProps & GlobalConnectedProps & WithProjectContextInjectedProps;

class EditScheduledTrigger extends FormBaseComponent<EditScheduledTriggerProps, EditState, Model> {
    public static defaultProps: Partial<EditScheduledTriggerProps> = {
        triggerActionCategory: TriggerActionCategory.Deployment,
    };

    channelNameMap: any = {};
    constructor(props: EditScheduledTriggerProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            runbooks: null!,
            environments: [],
            channels: [],
            tenants: [],
            project: null!,
            interval: null!,
            timezones: [],
            lifecycle: null,
            filterType: null!,
        };
    }

    async componentDidMount() {
        let newTrigger: NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource> = null!;
        let loadTrigger = false;

        const { model: project } = this.props.projectContext.state;

        let timezones: ServerTimezoneResource[] = [];
        await this.doBusyTask(async () => {
            timezones = await repository.ServerStatus.getTimezones();
        });

        this.setState(
            {
                timezones,
            },
            async () => {
                if (this.props.create) {
                    const triggerScheduleResource = this.createDefaultOnceDailyTriggerSchedule();

                    const triggerActionResource = new DeployLatestReleaseActionResource();
                    if (this.props.triggerActionCategory === TriggerActionCategory.Runbook) {
                        triggerActionResource.ActionType = TriggerActionType.RunRunbook;
                        const runbookId = this.props.match.params.runbookId;
                        (triggerActionResource as any as RunRunbookActionResource).RunbookId = runbookId;
                    }

                    newTrigger = {
                        ProjectId: project.Id,
                        Name: null!,
                        Description: null!,
                        IsDisabled: false,
                        Filter: triggerScheduleResource,
                        Action: triggerActionResource,
                    };
                } else {
                    loadTrigger = true;
                }

                await this.doBusyTask(
                    async () => {
                        let channels: ChannelResource[] = [];

                        const trigger: any = loadTrigger ? await repository.ProjectTriggers.get(this.props.match.params.triggerId) : null!;

                        if (isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })) {
                            channels = await this.loadChannels(project, trigger);
                        }

                        if (channels) {
                            channels.forEach((channel) => {
                                this.channelNameMap[channel.Id] = channel;
                            });
                        }

                        // Channel selection is not mandatory so an existing trigger when multiple channel options are available may not have one selected.
                        const triggerHasSelectedChannel = !!(trigger && trigger.Action && trigger.Action.ChannelId);
                        const selectedChannelOnTrigger = triggerHasSelectedChannel && channels.length > 0 && channels.find((c) => c.Id === trigger.Action.ChannelId);

                        // Even tho we have a selectedChannelOnTrigger but it may not have a lifecycle selection.
                        const lifecycleId = (selectedChannelOnTrigger && selectedChannelOnTrigger.LifecycleId) || project.LifecycleId;
                        const lifecycle = isAllowed({ permission: Permission.LifecycleView, wildcard: true }) ? await repository.Lifecycles.get(lifecycleId) : null!;

                        const filterType = this.getFilterType(newTrigger, trigger)!;

                        this.setState({
                            model: newTrigger || trigger,
                            runbooks: await repository.Runbooks.all({ projectIds: [project.Id] }),
                            environments: await repository.Environments.all(),
                            channels,
                            tenants: this.props.isMultiTenancyEnabled ? await repository.Tenants.all({ projectId: project.Id }) : await Promise.resolve([]),
                            project,
                            lifecycle: lifecycle ? await repository.Lifecycles.preview(lifecycle) : null!,
                            cleanModel: cloneDeep(newTrigger || trigger),
                            filterType,
                        });
                    },
                    { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) }
                );
            }
        );
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const title = this.props.create ? "New Scheduled Trigger" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.state.model && isExistingTriggerResource(this.state.model)) {
            const model: TriggerResource = this.state.model;
            overFlowActions.push(
                OverflowMenuItems.item(this.state.model && this.state.model.IsDisabled ? "Enable" : "Disable", () => (this.state.model && this.state.model.IsDisabled ? this.enableTrigger() : this.disableTrigger()), {
                    permission: Permission.TriggerEdit,
                    project: this.state.project && this.state.project.Id,
                })
            );
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("scheduled trigger", () => this.deleteTrigger(model), { permission: Permission.TriggerDelete, project: this.state.project && this.state.project.Id }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const saveText: string = this.props.create ? "Scheduled trigger created" : "Scheduled trigger details updated";

        const hasTenants = this.props.isMultiTenancyEnabled && this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && this.state.tenants.length > 0;
        const showActionType = this.props.triggerActionCategory === TriggerActionCategory.Deployment;
        const breadcrumbTitle = this.props.triggerActionCategory === TriggerActionCategory.Deployment ? "Triggers" : "Operations Triggers";
        const breadcrumbPath = this.props.triggerActionCategory === TriggerActionCategory.Deployment ? routeLinks.project(this.props.match.params.projectSlug).triggers : routeLinks.project(this.props.match.params.projectSlug).operations.triggers;

        let secondaryAction: React.ReactElement | undefined;
        if (this.state.model && this.props.triggerActionCategory === TriggerActionCategory.Runbook) {
            const action = this.state.model.Action as any as RunRunbookActionResource;
            if (action.RunbookId) {
                const runbookRoute = routeLinks.project(this.props.match.params.projectSlug).operations.runbook(action.RunbookId).root;
                secondaryAction = <NavigationButton label="Go to Runbook" href={runbookRoute} type={NavigationButtonType.Ternary} disabled={!isEqual(this.state.model, this.state.cleanModel)} />;
            }
        }

        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.errors}
                title={title}
                breadcrumbTitle={breadcrumbTitle}
                breadcrumbPath={breadcrumbPath}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{
                    permission: this.props.create ? Permission.TriggerCreate : Permission.TriggerEdit,
                    project: this.state.project && this.state.project.Id,
                }}
                onSaveClick={() => this.saveTrigger()}
                overFlowActions={overFlowActions}
                hideExpandAll={true}
                saveText={saveText}
                secondaryAction={secondaryAction}
            >
                {this.state.model && (
                    <TransitionAnimation>
                        <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create} />

                        {this.state.cleanModel && this.state.cleanModel.IsDisabled && (
                            <UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={"This trigger is currently disabled"} />
                            </UnstructuredFormSection>
                        )}

                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your scheduled trigger")}
                            help="Enter a name for your scheduled trigger."
                        >
                            <Text
                                value={this.state.model.Name || ""}
                                onChange={(Name) => this.setModelState({ Name })}
                                label="Scheduled trigger name"
                                validate={required("Please enter a scheduled trigger name")}
                                error={this.getFieldError("Name")}
                                autoFocus={true}
                            />
                            <Note>
                                A short, memorable, unique name for this scheduled trigger. Example: <i>Promote latest release in Development to Staging Environment at 9am every day</i>
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Description"
                            title="Description"
                            summary={this.state.model.Description ? Summary.summary(this.state.model.Description) : Summary.placeholder("Please enter a description for your trigger")}
                            help="Enter a description for your trigger."
                        >
                            <MarkdownEditor label="Trigger description" value={this.state.model.Description} onChange={(Description) => this.setModelState({ Description })} />
                        </ExpandableFormSection>
                        <FormSectionHeading title="Trigger Action" />
                        {showActionType && (
                            <ExpandableFormSection errorKey="ActionType" title="Action" summary={this.getActionTypeSummary()} help="Select the action that the trigger should take when executed.">
                                <StringRadioButtonGroup value={this.state.model.Action.ActionType} onChange={this.onTriggerActionTypeChange}>
                                    <RadioButton value={TriggerActionType.DeployLatestRelease} label="Deploy latest release" key={TriggerActionType.DeployLatestRelease} />
                                    <Note>
                                        If the same environment is selected for both source and destination the latest successfully deployed release in the source environment will be re-deployed to the destination environment, if different
                                        environments are selected the latest successfully deployed release in the source environment will be promoted to the destination environment
                                    </Note>
                                    <RadioButton value={TriggerActionType.DeployNewRelease} label="Deploy new release" key={TriggerActionType.DeployNewRelease} />
                                    <Note>A new release will be created and deployed to the selected environment</Note>
                                    {this.props.triggerActionCategory === TriggerActionCategory.Runbook && (
                                        <>
                                            <RadioButton value={TriggerActionType.RunRunbook} label="Run a Runbook" key={TriggerActionType.RunRunbook} />
                                            <Note>Runs a published snapshot of a Runbook on selected environments</Note>
                                        </>
                                    )}
                                </StringRadioButtonGroup>
                            </ExpandableFormSection>
                        )}
                        {this.state.project.PersistenceSettings.Type === PersistenceSettingsType.VersionControlled && this.state.model.Action.ActionType === TriggerActionType.DeployNewRelease && (
                            <ExpandableFormSection errorKey="GitRef" title="Git Branch" focusOnExpandAll summary={this.buildBranchSummary()} help={this.buildBranchHelp()}>
                                <GitRefFormSection
                                    value={(this.state.model.Action as DeployNewReleaseActionResource).VersionControlReference.GitRef ?? this.props.projectContext.state.gitRef?.CanonicalName}
                                    onChange={async (gitRef: GitRef) => {
                                        if (gitRef) await this.onGitRefChange({ VersionControlReference: { GitRef: gitRef } });
                                    }}
                                    canResetToDefaultBranch={false}
                                />
                            </ExpandableFormSection>
                        )}
                        {this.state.channels.length > 1 && this.state.model.Action.ActionType !== TriggerActionType.RunRunbook && (
                            <ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={this.buildChannelSummary()} help={this.buildChannelHelp()}>
                                <Select
                                    allowClear={true}
                                    items={this.state.channels.map((c) => {
                                        return { text: c.Name, value: c.Id };
                                    })}
                                    value={(this.state.model.Action as ScopedDeploymentActionResource).ChannelId}
                                    onChange={async (channelId) => {
                                        await this.onChannelChange({ ChannelId: channelId! }, async () => {
                                            await this.updateLifecycleForNonVcsTriggerActions(channelId);
                                        });
                                    }}
                                />
                                <Note>The release deployed will be the latest from the specified channel, which is eligible for promotion to the destination environment according the channel's lifecycle.</Note>
                            </ExpandableFormSection>
                        )}
                        {hasTenants && (
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <ExpandableFormSection errorKey="TenantSelector" title="Tenants" focusOnExpandAll summary={this.buildTenantSummary()} help={this.buildTenantHelp()}>
                                    {/* We don't support tenant tag permissions yet, so the decision was to only support tenants, we can change our mind in the future */}
                                    <TenantMultiSelect items={this.state.tenants} onChange={(tenantIds) => this.setChildState2("model", "Action", { TenantIds: tenantIds, TenantTags: [] })} value={this.state.model.Action.TenantIds} />
                                    {/* <AdvancedTenantsAndTenantTagsSelector
                                        tenants={this.state.tenants}
                                        doBusyTask={this.doBusyTask}
                                        selectedTenantIds={this.state.model.Action.TenantIds}
                                        selectedTenantTags={this.state.model.Action.TenantTags}
                                        onChange={(tenantIds, tenantTags) => this.setChildState2("model", "Action", {TenantIds: tenantIds, TenantTags: tenantTags})} /> */}
                                </ExpandableFormSection>
                            </PermissionCheck>
                        )}
                        {this.state.model.Action.ActionType === TriggerActionType.DeployLatestRelease &&
                            (this.state.lifecycle ? (
                                <DeployLatestReleaseActionEditor
                                    action={this.state.model.Action as DeployLatestReleaseActionResource}
                                    allEnvironments={this.state.environments}
                                    lifecycle={this.state.lifecycle}
                                    onActionChange={this.scheduledTriggerActionChanged}
                                />
                            ) : (
                                <DeployLatestReleaseWithNoLifecycleActionEditor action={this.state.model.Action as DeployLatestReleaseActionResource} allEnvironments={this.state.environments} onActionChange={this.scheduledTriggerActionChanged} />
                            ))}
                        {this.state.model.Action.ActionType === TriggerActionType.DeployNewRelease && (
                            <DeployNewReleaseActionEditor
                                action={this.state.model.Action as DeployNewReleaseActionResource}
                                allEnvironments={this.state.environments}
                                lifecycle={this.state.lifecycle!}
                                onActionChange={this.scheduledTriggerActionChanged}
                            />
                        )}
                        {this.state.model.Action.ActionType === TriggerActionType.RunRunbook && (
                            <RunRunbookActionEditor
                                action={this.state.model.Action as RunRunbookActionResource}
                                environments={this.state.environments}
                                runbooks={this.state.runbooks}
                                onActionChange={this.scheduledTriggerActionChanged}
                                projectSlug={this.props.match.params.projectSlug}
                            />
                        )}

                        <FormSectionHeading title="Trigger Schedule" />
                        <ExpandableFormSection errorKey="ScheduleType" title="Schedule" summary={this.getScheduleTypeSummary()} help="Select the schedule that the trigger should run on.">
                            <StringRadioButtonGroup
                                value={this.state.filterType}
                                onChange={(scheduleTypeString: string) => {
                                    const scheduleType = scheduleTypeString as CombinedTriggerFilterType;
                                    this.setState({
                                        filterType: scheduleType,
                                    });
                                    if (scheduleType === OtherTriggerFilterType.DailyOnceOrContinuous) {
                                        const schedule = this.createDefaultOnceDailyTriggerSchedule();
                                        this.setChildState1("model", { Filter: schedule });
                                    } else {
                                        this.setChildState2("model", "Filter", { FilterType: scheduleType });
                                    }
                                }}
                            >
                                <RadioButton value={OtherTriggerFilterType.DailyOnceOrContinuous} label="Daily" key={OtherTriggerFilterType.DailyOnceOrContinuous} />
                                <Note>Allows you to configure a trigger that will run once, hourly or minutely daily</Note>
                                <RadioButton value={TriggerFilterType.DaysPerMonthSchedule} label="Days per month" key={TriggerFilterType.DaysPerMonthSchedule} />
                                <Note>Allows you to configure a trigger that will run on a specific date of the month or specific day of week of every month</Note>
                                <RadioButton value={TriggerFilterType.CronExpressionSchedule} label="Cron expression" key={TriggerFilterType.CronExpressionSchedule} />
                                <Note>Allows you to configure a trigger that will run according to the specific CRON expression</Note>
                            </StringRadioButtonGroup>
                        </ExpandableFormSection>
                        {this.state.filterType === OtherTriggerFilterType.DailyOnceOrContinuous && (
                            <DailyScheduledTriggerEditor
                                onceDailySchedule={this.state.model.Filter as OnceDailyTriggerScheduleResource}
                                onOnceDailyScheduleChange={this.scheduledTriggerScheduleChanged}
                                continuousDailySchedule={this.state.model.Filter as ContinuousDailyTriggerScheduleResource}
                                onContinuousDailyScheduleChange={this.scheduledTriggerScheduleChanged}
                                timezones={this.state.timezones}
                                triggerFilterType={this.state.model.Filter.FilterType}
                                onScheduleTypeChange={(type) => {
                                    this.setChildState2("model", "Filter", { FilterType: type });
                                }}
                            />
                        )}

                        {this.state.model.Filter.FilterType === TriggerFilterType.DaysPerMonthSchedule && (
                            <DaysPerMonthScheduledTriggerEditor schedule={this.state.model.Filter as DaysPerMonthTriggerScheduleResource} timezones={this.state.timezones} onScheduleChange={this.scheduledTriggerScheduleChanged} />
                        )}
                        {this.state.model.Filter.FilterType === TriggerFilterType.CronExpressionSchedule && (
                            <CronExpressionScheduledTriggerEditor schedule={this.state.model.Filter as CronTriggerScheduleResource} timezones={this.state.timezones} onScheduleChange={this.scheduledTriggerScheduleChanged} />
                        )}

                        {/* Disabling this as it needs more thought about usage of VariableView and VariableEdit permissions*/}
                        {/* <ExpandableFormSection errorKey="Variables"
                                               title="Variables"
                                               fillCardWidth={CardFill.FillRight}
                                               summary={this.buildVariableSummary()}
                                               help={this.buildVariableHelp()}>
                            <KeyValueEditList items={this.state.model.Action.Variables}
                                              name="Variable"
                                              separator="="
                                              onChange={val => this.setChildState2("model", "Action", {Variables: val})}
                                              valueLabel="Value"
                                              keyLabel="Variable name"
                                              hideBindOnKey={true}
                                              projectId={this.state.model.ProjectId}
                            />
                        </ExpandableFormSection> */}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private async loadChannels(project: ProjectResource, trigger: TriggerResource | undefined): Promise<ChannelResource[]> {
        return await this.loadChannelsFromProject(project);
    }

    private async loadChannelsFromProject(project: ProjectResource): Promise<ChannelResource[]> {
        const channelResourceCollection = await repository.Projects.getChannels(project);
        return channelResourceCollection.Items;
    }

    private getFilterType(newTrigger: NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>, trigger: NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>) {
        if (newTrigger) {
            return OtherTriggerFilterType.DailyOnceOrContinuous;
        } else if (trigger) {
            if (trigger.Filter.FilterType === TriggerFilterType.OnceDailySchedule || trigger.Filter.FilterType === TriggerFilterType.ContinuousDailySchedule) {
                return OtherTriggerFilterType.DailyOnceOrContinuous;
            } else {
                return trigger.Filter.FilterType;
            }
        }
    }

    private createDefaultOnceDailyTriggerSchedule(): OnceDailyTriggerScheduleResource {
        const serverLocalTimezones = this.state.timezones.filter((tz: any) => {
            return tz.IsLocal === true;
        });
        const triggerScheduleResource = new OnceDailyTriggerScheduleResource();
        triggerScheduleResource.Timezone = serverLocalTimezones.length > 0 ? serverLocalTimezones[0].Id : null!;
        const currentDate = new Date();
        triggerScheduleResource.StartTime = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9, 0, 0));
        triggerScheduleResource.DaysOfWeek = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];

        return triggerScheduleResource;
    }

    private getChannelNameGivenId = (channelId: string) => {
        const channel = this.channelNameMap[channelId];
        if (channel) {
            return channel.Name;
        }
        return channelId;
    };

    private buildBranchSummary = () => {
        switch (this.state.model!.Action.ActionType) {
            case TriggerActionType.DeployNewRelease:
                return (this.state.model!.Action as DeployNewReleaseActionResource).VersionControlReference
                    ? Summary.summary(
                          <span>
                              New releases will be created using the configuration on the <code>{(this.state.model!.Action as DeployNewReleaseActionResource).VersionControlReference.GitRef}</code> branch.
                          </span>
                      )
                    : Summary.summary(<span>No branch selected</span>);
        }
    };

    private buildBranchHelp = () => {
        switch (this.state.model!.Action.ActionType) {
            case TriggerActionType.DeployNewRelease:
                return "Select the branch to use when creating the release";
        }
    };

    private buildChannelSummary = () => {
        switch (this.state.model!.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                return (this.state.model!.Action as ScopedDeploymentActionResource).ChannelId
                    ? Summary.summary(
                          <span>
                              Latest release in channel <strong>{this.getChannelNameGivenId((this.state.model!.Action as ScopedDeploymentActionResource).ChannelId)}</strong> will be promoted
                          </span>
                      )
                    : Summary.placeholder("Latest release in project will be deployed");
            case TriggerActionType.DeployNewRelease:
                return (this.state.model!.Action as ScopedDeploymentActionResource).ChannelId
                    ? Summary.summary(
                          <span>
                              New release will be created in the <strong>{this.getChannelNameGivenId((this.state.model!.Action as ScopedDeploymentActionResource).ChannelId)}</strong> channel.
                          </span>
                      )
                    : Summary.summary(<span>New release will be created in the default channel.</span>);
        }
    };

    private buildChannelHelp = () => {
        let helpSummary = "";
        switch (this.state.model!.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                //helpSummary = "The channel to use when selecting the release to promote, or leave blank to use the latest release for the project.";
                helpSummary = "The channel to use when selecting the release to deploy, or leave blank to use the latest release for the project.";
                break;
            case TriggerActionType.DeployNewRelease:
                helpSummary = "The channel to use when creating the release.";
                break;
            default:
                helpSummary = "Select the channel to use when selecting the release, or leave blank to use the latest release for the project.";
                break;
        }
        return helpSummary;
    };

    private buildVariableSummary = () => {
        return Summary.placeholder("Define variables");
    };

    private buildVariableHelp = () => {
        return "Define variables that will be overridden in the deployment or substituted for prompted variables.";
    };

    private buildTenantSummary = () => {
        const showMissingTenants = true;
        return CommonSummaryHelper.tenantSummary(this.state.model!.Action.TenantIds, this.state.model!.Action.TenantTags, this.state.tenants, showMissingTenants);
    };

    private buildTenantHelp = () => {
        return "Select the Tenants to deploy the release to";
    };

    private getScheduleTypeSummary() {
        let summary = "";
        switch (this.state.filterType) {
            case OtherTriggerFilterType.DailyOnceOrContinuous:
                summary = "Runs daily";
                break;
            case TriggerFilterType.DaysPerMonthSchedule:
                summary = "Runs on a specific day of the month";
                break;
            case TriggerFilterType.CronExpressionSchedule:
                summary = "Runs according to a cron expression";
                break;
            default:
                return Summary.placeholder("Please select the triggers schedule");
        }
        return Summary.summary(summary);
    }

    private getActionTypeSummary() {
        let summary = "";
        switch (this.state.model!.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                summary = "Deploy the latest release";
                break;
            case TriggerActionType.DeployNewRelease:
                summary = "Create and deploy a new release";
                break;
            case TriggerActionType.RunRunbook:
                summary = "Run a Runbook";
                break;
            default:
                return Summary.placeholder("Please select the trigger Action");
        }
        return Summary.summary(summary);
    }

    private onChannelChange = async <K extends keyof ScopedDeploymentActionResource>(state: Pick<ScopedDeploymentActionResource, K>, callback?: () => void) => {
        this.setChildState2("model", "Action", state, callback);
    };

    private onGitRefChange = async <K extends keyof DeployNewReleaseActionResource>(state: Pick<DeployNewReleaseActionResource, K>, callback?: () => void) => {
        this.setChildState2("model", "Action", state, callback);
    };

    private onTriggerActionTypeChange = async (triggerActionTypeString: string) => {
        const triggerActionType = triggerActionTypeString as TriggerActionType;
        const newAction = this.getActionResourceFromActionType(triggerActionType);
        const isVersionControlled = this.state.project.PersistenceSettings.Type === PersistenceSettingsType.VersionControlled;

        if (isVersionControlled) {
            const defaultBranch: GitRef = toGitBranch((this.state.project.PersistenceSettings as GitPersistenceSettings).DefaultBranch);

            if (triggerActionType === TriggerActionType.DeployNewRelease) {
                (newAction as DeployNewReleaseActionResource).VersionControlReference = defaultBranch ? { GitRef: defaultBranch, GitCommit: undefined } : { GitRef: undefined, GitCommit: undefined };
            }
        }

        if (triggerActionType === TriggerActionType.DeployNewRelease && this.state.channels.length === 1) {
            (newAction as ScopedDeploymentActionResource).ChannelId = this.state.channels.find((c) => c.IsDefault)?.Id ?? "";
        }

        this.setChildState1("model", { Action: newAction }, async () => {
            isVersionControlled ? await this.updateLifecycleForVcsTriggerActions() : await this.updateLifecycleForNonVcsTriggerActions();
        });
    };

    private getActionResourceFromActionType = (triggerActionType: TriggerActionType) => {
        switch (triggerActionType) {
            case TriggerActionType.DeployLatestRelease:
                return new DeployLatestReleaseActionResource();
            case TriggerActionType.DeployNewRelease:
                return new DeployNewReleaseActionResource();
            case TriggerActionType.RunRunbook:
                return new RunRunbookActionResource();
            default:
                throw new Error("Invalid trigger action type " + triggerActionType);
        }
    };

    private scheduledTriggerScheduleChanged = (schedule: TriggerScheduleResource) => {
        this.setChildState1("model", { Filter: schedule });
    };

    private scheduledTriggerActionChanged = (action: ScopedDeploymentActionResource | RunRunbookActionResource) => {
        this.setChildState1("model", { Action: action });
    };

    private updateLifecycleForNonVcsTriggerActions = async (channelId: string = null!) => {
        const lifecycle = await repository.Lifecycles.get(channelId ? this.channelNameMap[channelId].LifecycleId : this.state.project.LifecycleId);
        const preview = await repository.Lifecycles.preview(lifecycle);
        this.setState({
            lifecycle: preview,
        });
    };

    private updateLifecycleForVcsTriggerActions = async (channelId?: string) => {
        if (!channelId) {
            const lifecycleId = this.state.project.LifecycleId;
            const lifecycle = await repository.Lifecycles.get(lifecycleId);
            const preview = await repository.Lifecycles.preview(lifecycle);
            this.setState({
                lifecycle: preview,
            });
            return;
        }

        const channel = this.state.channels.find((c) => c.Id === channelId);
        if (channel) {
            // If channel found but it has null lifecycleId, then set it to the default lifecycle for the project
            const lifecycleId = channel.LifecycleId ?? this.state.project.LifecycleId;
            const lifecycle = await repository.Lifecycles.get(lifecycleId);
            const preview = await repository.Lifecycles.preview(lifecycle);
            this.setState({
                lifecycle: preview,
            });
        } else {
            // If channel wasn't found, then set the Lifecycle to null as the user needs to create the channel
            this.setState({
                lifecycle: null,
            });
        }
    };

    private async saveTrigger() {
        await this.doBusyTask(async () => {
            const result = (await repository.ProjectTriggers.save(this.state.model!)) as TriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>;
            if (this.props.create) {
                const redirectTo =
                    this.props.triggerActionCategory === TriggerActionCategory.Deployment
                        ? routeLinks.project(this.props.match.params.projectSlug).trigger(result, result.Action.ActionType)
                        : routeLinks.project(this.props.match.params.projectSlug).operations.trigger(result, result.Action.ActionType);
                this.setState({ redirectTo });
            } else {
                this.setState({
                    model: result,
                    cleanModel: cloneDeep(result),
                });
            }
        });
    }

    private async enableTrigger() {
        this.setChildState1("model", { IsDisabled: false }, () => this.saveTrigger());
    }

    private async disableTrigger() {
        this.setChildState1("model", { IsDisabled: true }, () => this.saveTrigger());
    }

    private async deleteTrigger(model: TriggerResource) {
        await this.doBusyTask(async () => {
            await repository.ProjectTriggers.del(model);
            const redirectTo = this.props.triggerActionCategory === TriggerActionCategory.Deployment ? routeLinks.project(this.state.project).triggers : routeLinks.project(this.state.project).operations.triggers;
            this.setState({ redirectTo });
        });
        return true;
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: EditScheduledTriggerModeProps & EditScheduledTriggerRouteProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

const EnhancedScheduledTrigger = connect(mapGlobalStateToProps)(withProjectContext(EditScheduledTrigger));

export default EnhancedScheduledTrigger;
