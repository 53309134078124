/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { logger } from "@octopusdeploy/logging";
import { lastAccessedGitRef } from "app/areas/projects/context/LastAccessedGitRef";
import * as React from "react";
import { createContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { generatePath } from "react-router-dom";
import { ProjectContextRepository } from "~/client/repositories/projectContextRepository";
import type { GitRefResource, ProjectResource, ProjectSummary, ValidateGitRefV2Response } from "~/client/resources";
import { HasVersionControlledPersistenceSettings, OctopusError, ValidateGitRefV2ResponseType } from "~/client/resources";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { IsDefaultBranch, toGitBranch } from "~/client/resources/versionControlledResource";
import { client, repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import type { BranchSpecifier } from "../components/ProjectsRoutes/BranchSpecifier";
import { ShouldUseDefaultBranch, UseDefaultBranch } from "../components/ProjectsRoutes/BranchSpecifier";

type ProjectContextProviderProps = {
    doBusyTask: DoBusyTask;
    projectIdOrSlug: string;
    gitRef: BranchSpecifier;
    children: (props: ProjectContextProps) => React.ReactNode;
};

export interface ProjectContextState {
    model: Readonly<ProjectResource>;
    summary: Readonly<ProjectSummary>;
    gitRef: Readonly<GitRefResource> | undefined;
    projectContextRepository: ProjectContextRepository;
    isDefaultBranch: boolean | undefined;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitVariablesHasError?: Boolean;
    gitVariablesErrorMessages: string[];
}

export interface ProjectContextActions extends ProjectLayoutActions {
    refreshModel: () => Promise<boolean>;
}

export type ProjectContextProps = ReturnType<typeof useProjectLayoutSetup>;
export const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

function modifyStateForWhenBranchNotLoaded(value: ProjectContextProps, branch: BranchSpecifier): ProjectContextProps {
    // Switching branches, we load the new branch resource asynchronously whereas the page (e.g. list of runbooks) tries
    // to reload its data. As the branch has not yet been loaded, runbooks get loaded from the old branch. We explicitly
    // set the branch to undefined here to force ProjectLayout to not render content till the branch data has been loaded.
    if (branch !== UseDefaultBranch && branch !== value.state.gitRef?.Name) {
        return {
            ...value,
            state: {
                ...value.state,
                gitRef: undefined,
            },
        };
    }
    return value;
}

const ProjectContextProvider: React.FC<ProjectContextProviderProps> = (props) => {
    const value = useProjectLayoutSetup(props.doBusyTask, props.projectIdOrSlug, props.gitRef);
    return <ProjectContext.Provider value={value}>{props.children(modifyStateForWhenBranchNotLoaded(value, props.gitRef))}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
    return useRequiredContext(ProjectContext, "Project");
};

export const useOptionalProjectContext = () => {
    return React.useContext(ProjectContext);
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProjectContextState>>) => {
    return {
        onProjectUpdated: async (project: ProjectResource, gitRef: GitRefResource | string | undefined) => {
            try {
                const summary = await repository.Projects.getSummary(project, gitRef);
                setState((current) => ({ ...current, model: project, summary, isDefaultBranch: IsDefaultBranch(project, GetCanonicalName(gitRef)) }));
            } catch (ex) {
                logger.error(ex, "Error getting summary for {project}", { project, gitRef });
                const emptySummary = { HasRunbooks: false, HasDeploymentProcess: false };
                setState((current) => ({ ...current, model: project, summary: emptySummary, isDefaultBranch: IsDefaultBranch(project, GetCanonicalName(gitRef)) }));
            }
        },
        onVersionControlEnabled: async (project: ProjectResource) => {
            if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
            await lastAccessedGitRef.save(project, project.PersistenceSettings.DefaultBranch);
            const branch = await repository.Projects.getBranch(project, project.PersistenceSettings.DefaultBranch);
            setState((current) => ({ ...current, gitRef: branch, projectContextRepository: new ProjectContextRepository(client, project, branch), isDefaultBranch: IsDefaultBranch(project, GetCanonicalName(branch)) }));
        },
        onBranchSelected: async (project: ProjectResource, gitRef: GitRef) => {
            await lastAccessedGitRef.save(project, gitRef);
            const gitRefResource = await repository.Projects.getGitRef(project, gitRef);
            setState((current) => ({ ...current, gitRef: gitRefResource, projectContextRepository: new ProjectContextRepository(client, project, gitRefResource), isDefaultBranch: IsDefaultBranch(project, GetCanonicalName(gitRefResource)) }));
        },
    };
};

function GetCanonicalName(branch: GitRefResource | string | undefined): string | undefined {
    if (typeof branch === "string") {
        return branch;
    } else {
        return branch?.CanonicalName;
    }
}

const useProjectLayoutState = () => {
    return React.useState<ProjectContextState>({
        model: null!,
        summary: null!,
        projectContextRepository: null!,
        gitRef: undefined,
        isDefaultBranch: undefined,
        gitRefValidationError: undefined,
        gitVariablesErrorMessages: [],
    });
};

const invokeBusyWithResponse = async <T,>(action: () => Promise<T>, doBusyTask: DoBusyTask) => {
    const result = action();
    await doBusyTask(() => result);
    return result;
};

const useSaveRecentAccessedProjectIdEffect = (projectId: string | null) => {
    React.useEffect(() => {
        if (projectId) {
            RecentProjects.getInstance()
                .UpdateAccessedProjectIntoLocalStorage(projectId)
                .catch((error) => logger.error(error, "Unknown error saving recently accessed project id {projectId}", { projectId }));
        }
    }, [projectId]);
};

type ProjectLayoutActions = ReturnType<typeof getStateUpdaters>;

const subscribeKey = "ProjectContextState";

const useProjectLayoutSetup = (doBusyTask: DoBusyTask, projectIdOrSlug: string, branchSpecifier: BranchSpecifier) => {
    const [state, setState] = useProjectLayoutState();
    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const match = useRouteMatch()!;
    const location = useLocation();
    const history = useHistory();

    const canChangeBranch = React.useCallback(() => match.params.hasOwnProperty("branchName"), [match]);

    const changeGitRef = React.useCallback(
        (gitRef: string) => {
            if (!canChangeBranch()) return;

            // Using branchName for backwards compatibility
            const newUrl = generatePath(match.path, { ...match.params, branchName: gitRef });
            const newPath = newUrl + location.pathname.substr(match.url.length);

            // If the path hasn't changed, then don't bother redirecting, we might end up in a redirect loop
            if (newPath === location.pathname) return;

            history.push({ ...location, pathname: newPath });
        },
        [canChangeBranch, match, location, history]
    );

    const setGitError = React.useCallback(
        (validationResult: ValidateGitRefV2Response) => {
            setState((current) => ({
                ...current,
                gitRefValidationError: validationResult,
            }));
        },
        [setState]
    );

    const clearGitError = React.useCallback(() => {
        setState((current) => ({
            ...current,
            gitRefValidationError: undefined,
        }));
    }, [setState]);

    const refreshAndGetModel = React.useCallback(
        (gitRef?: GitRef) =>
            invokeBusyWithResponse(async () => {
                const project = await repository.Projects.get(projectIdOrSlug);
                let gitRefResource: GitRefResource | undefined = undefined;

                clearGitError();

                if (HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
                    // If a git ref was specified, use that
                    // Otherwise, defer to the branch specifier or last accessed git ref
                    const gitRefToValidate = gitRef ? gitRef : ShouldUseDefaultBranch(branchSpecifier) ? lastAccessedGitRef.get(project) : branchSpecifier;

                    const validationResult = await repository.Projects.validateGitRef(project, gitRefToValidate);
                    if (validationResult.Type !== ValidateGitRefV2ResponseType.Success) {
                        setGitError(validationResult);
                    }

                    gitRefResource = validationResult.GitRef;
                    if (gitRefResource) {
                        await lastAccessedGitRef.save(project, gitRefResource.CanonicalName);
                    }
                }

                setState((current) => ({
                    ...current,
                    gitRef: gitRefResource,
                    projectContextRepository: new ProjectContextRepository(client, project, gitRefResource),
                }));

                await updaters.onProjectUpdated(project, gitRefResource);
                return project;
            }, doBusyTask),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [doBusyTask, projectIdOrSlug, clearGitError, setGitError, setState, updaters, branchSpecifier]
    );

    const refreshModel = React.useCallback((gitRef?: GitRef) => refreshAndGetModel(gitRef).then((x) => true), [refreshAndGetModel]);

    const refreshGitVariableErrors = async () => {
        if (!HasVersionControlledPersistenceSettings(state.model.PersistenceSettings)) {
            logger.verbose("This project is not version controlled, so there can not be Git variable errors.");

            setState((current) => ({ ...current, gitVariablesHasError: false, gitVariablesErrorMessages: [] }));
            return;
        }

        if (!state.model.PersistenceSettings.ConversionState.VariablesAreInGit) {
            logger.verbose("This project does not have variables in git, so there can not be Git variable errors.");

            setState((current) => ({ ...current, gitVariablesHasError: false, gitVariablesErrorMessages: [] }));
            return;
        }

        try {
            const defaultBranchGitRef = toGitBranch(state.model.PersistenceSettings.DefaultBranch);
            logger.verbose("Determining the Git ref for the default branch {DefaultBranch}.", { DefaultBranch: defaultBranchGitRef });
            const defaultBranchGitRefResource = await repository.Projects.getGitRef(state.model, defaultBranchGitRef);
            logger.verbose("The default branch Git ref is '{GitRef}'. Checking for Git variable errors.", { GitRef: defaultBranchGitRefResource.Name });
            const defaultBranchRepository = new ProjectContextRepository(client, state.model, defaultBranchGitRefResource);

            await defaultBranchRepository.Variables.get();

            logger.verbose("No Git variable errors found.");
        } catch (exception) {
            if (OctopusError.isOctopusError(exception)) {
                logger.error("A Git variable error was found: '{ErrorMessage}'.", { ErrorMessage: exception.ErrorMessage });
                const errorMessages = [exception.ErrorMessage];

                // Add any additional details about the error. This is often where the really useful stuff is.
                if (exception.Errors) {
                    exception.Errors.forEach((error: string) => {
                        if (error) {
                            errorMessages.push(error);
                        }
                    });
                }

                setState((current) => ({ ...current, gitVariablesHasError: true, gitVariablesErrorMessages: errorMessages }));
            } else {
                logger.error("An error occurred while checking for Git variable errors: '{ErrorMessage}'.", { ErrorMessage: exception });
                setState((current) => ({ ...current, gitVariablesHasError: true, gitVariablesErrorMessages: [exception] }));
            }
        }
    };

    React.useEffect(
        () =>
            client.subscribe((event) => {
                if (event.type === "ProjectModified") {
                    setState((previous) => {
                        if (previous.model && previous.model.Id === event.project.Id) {
                            return { ...previous, model: event.project };
                        } else {
                            return previous;
                        }
                    });
                }
            }),
        [setState]
    );

    React.useEffect(() => {
        // eslint-disable-next-line: no-floating-promises
        refreshAndGetModel();
    }, [refreshAndGetModel]);

    useSaveRecentAccessedProjectIdEffect(state.model && state.model.Id);

    const supportedActions = {
        refreshModel,
        changeGitRef,
        refreshGitVariableErrors,
        ...updaters,
    };

    return {
        actions: supportedActions,
        state,
        setState,
    };
};

type ProjectContextConsumerProps = Parameters<typeof ProjectContext.Consumer>[0];
const ProjectContextConsumer: React.SFC<ProjectContextConsumerProps> = ({ children }) => {
    const context = useProjectContext();
    return <React.Fragment>{children(context)}</React.Fragment>;
};

export { ProjectContextProvider, ProjectContextConsumer };
