/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { ActionExecutionLocation, GetNamedPackageReferences, GetPrimaryPackageReference, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference } from "~/client/resources";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { KubernetesRawYamlProperties } from "~/components/Actions/kubernetes/kubernetesProperties";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { isContainerImageRegistry } from "../../../client/resources/feedResource";
import { PackageAcquisitionLocation } from "../../../client/resources/packageAcquisitionLocation";
import { PackageSelectionMode } from "../../../client/resources/packageReference";
import ListTitle from "../../../primitiveComponents/dataDisplay/ListTitle";
import RadioButton from "../../../primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "../../../primitiveComponents/form/RadioButton/RadioButtonGroup";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper";
import ActionButton from "../../Button";
import { default as CodeEditor, TextFormat } from "../../CodeEditor/CodeEditor";
import DialogOpener from "../../Dialog/DialogOpener";
import OpenDialogButton from "../../Dialog/OpenDialogButton";
import ExternalLink from "../../Navigation/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import SourceCodeDialog from "../../SourceCodeDialog/SourceCodeDialog";
import { Note, Summary } from "../../form";
import ExpandableFormSection, { CardFill } from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionWithFeeds } from "../commonActionHelpers";
import type { ScriptPackageProperties, ScriptPackageReference } from "../script/ScriptPackageReferenceDialog";
import { ScriptPackageReferenceDialog } from "../script/ScriptPackageReferenceDialog";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";

class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {}

interface KubernetesDeployRawYamlActionEditState {
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
}

class KubernetesDeployRawYamlActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return <div>Deploy raw YAML resources to Kubernetes</div>;
    }
}

type KubernetesDeployRawYamlActionEditProps = ActionEditProps<KubernetesRawYamlProperties, ScriptPackageProperties>;
type KubernetesDeployRawYamlActionEditInternalProps = KubernetesDeployRawYamlActionEditProps & ActionWithFeeds;

class KubernetesDeployRawYamlActionEditInternal extends BaseComponent<KubernetesDeployRawYamlActionEditInternalProps, KubernetesDeployRawYamlActionEditState> {
    constructor(props: KubernetesDeployRawYamlActionEditInternalProps) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
            this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "Inline" }, true);
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const packageReferences = GetNamedPackageReferences(this.props.packages);
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());

        const editPackageReferenceDialog = (
            <DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog
                    packageReference={this.state.editPackageReference!}
                    runOn={this.props.runOn}
                    // The docker images will be acquired by the k8s cluster, not by Octopus or targets
                    feeds={this.props.feeds
                        .filter((f) => isContainerImageRegistry(f.FeedType))
                        .map((f) => {
                            f.PackageAcquisitionLocationOptions = [PackageAcquisitionLocation.NotAcquired];
                            return f;
                        })}
                    localNames={localNames}
                    projectId={this.props.projectId!}
                    onChange={(packageReference) => this.savePackageReference(packageReference)}
                    refreshFeeds={this.loadFeeds}
                    parameters={this.props.parameters}
                />
            </DialogOpener>
        );

        return (
            <div>
                {editPackageReferenceDialog}
                <ExpandableFormSection
                    errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.KubernetesContainers.CustomResourceYaml"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="YAML Source"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.summaryRawYaml()}
                    help={"Select the source of the Kubernetes resources."}
                >
                    <Note>Kubernetes resources can be entered as source-code, or contained in a package.</Note>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Script.ScriptSource"]} onChange={(val) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                        <RadioButton value={"Inline"} label="Source code" />
                        <RadioButton value={"Package"} label="File inside a package" />
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && (
                        <div>
                            <br />
                            {this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] && (
                                <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} allowFullScreen={false} readOnly={true} />
                            )}
                            <div>
                                <OpenDialogButton
                                    label={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] ? "Edit Source Code" : "Add Source Code"}
                                    wideDialog={true}
                                    renderDialog={(openProps) => (
                                        <SourceCodeDialog
                                            open={openProps.open}
                                            close={openProps.closeDialog}
                                            value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]}
                                            autocomplete={[]}
                                            saveDone={(value) => {
                                                this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: value });
                                            }}
                                            language={TextFormat.YAML}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Package"
                            summary={CommonSummaryHelper.packageSummary(pkg, this.props.feeds)}
                            help={"Choose the package that contains the Kubernetes resource YAML."}
                        >
                            <PackageSelector
                                packageId={pkg?.PackageId}
                                feedId={pkg?.FeedId}
                                onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                                onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                                packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                                feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                                projectId={this.props.projectId}
                                feeds={this.props.feeds}
                                localNames={this.props.localNames}
                                refreshFeeds={this.loadFeeds}
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]}
                                onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]: x })}
                                label="Kubernetes YAML file name"
                                error={this.props.getFieldError("Octopus.Action.KubernetesContainers.CustomResourceYamlFileName")}
                            />
                            <Note>
                                The relative path to the YAML file that contains the Kubernetes resources.
                                <br />
                                e.g. <code>MyPod.yaml</code> or <code>Resources\MyPod.yaml</code>
                            </Note>
                        </ExpandableFormSection>
                    </div>
                )}
                <ExpandableFormSection
                    title={"Referenced Packages"}
                    isExpandedByDefault={this.props.expandedByDefault}
                    errorKey="Octopus.Action.Script.Packages"
                    summary={this.packageReferenceSummary()}
                    help={"Add packages to be referenced by your scripts at execution-time"}
                >
                    <Note>
                        Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <PackageReferenceList
                        listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()} />]}
                        data={packageReferences}
                        onRow={(p) => this.packageReferenceListItem(p)}
                        onRowTouch={(pkg) => this.editPackageReference(pkg)}
                        onRemoveRow={(pkg) => this.removePackageReference(pkg)}
                    />
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })} />
            </div>
        );
    }

    savePackageReference = (packageReference: ScriptPackageReference) => {
        const packageReferences = [...this.props.packages];
        if (!this.state.editPackageReferenceIndex) {
            packageReferences.push(packageReference);
        } else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }

        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    };

    packageVariableNames = (): string[] => {
        return _.flatten(
            GetNamedPackageReferences(this.props.packages).map((pkg) => [
                `Octopus.Action.Package[${pkg.Name}].PackageId`,
                `Octopus.Action.Package[${pkg.Name}].FeedId`,
                `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
                `Octopus.Action.Package[${pkg.Name}].Path`,
            ])
        );
    };

    resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        });
    };

    addPackageReference = () => {
        const additionalPackage: ScriptPackageReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            // The packages are docker images to be acquired by the cluster, not the target
            AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
            Properties: {
                Extract: "false",
            },
        };

        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null! });
    };

    editPackageReference = (pkg: ScriptPackageReference) => {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    };

    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };

    packageReferenceListItem = (pkg: ScriptPackageReference) => {
        return (
            <ProcessFeedLookup feedId={pkg.FeedId}>
                {(feed) => (
                    <div>
                        <ListTitle>{pkg.Name}</ListTitle>
                        <div>
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                                <React.Fragment>
                                    <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                                </React.Fragment>
                            )}
                            {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                        </div>
                    </div>
                )}
            </ProcessFeedLookup>
        );
    };

    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);

        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }

        return Summary.summary(`${namedPackageReferences.length} package references`);
    };

    private summaryRawYaml = () => {
        const source = this.props.properties["Octopus.Action.Script.ScriptSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Resource source not specified");
    };

    private onChangeTemplateSource(value: string) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
            ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: "",
        });

        // If the inline option is selected, we clear out the package selection
        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds));
        }
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
}

export function KubernetesDeployRawYamlActionEdit(props: React.PropsWithChildren<KubernetesDeployRawYamlActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();

    return <KubernetesDeployRawYamlActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} />;
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployRawYaml",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployRawYamlActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployRawYamlActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
});
