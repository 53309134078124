import type { ProjectStatusResponse } from "~/client/resources/index";
import { repository } from "~/clientInstance";
import { RefreshLoop } from "~/utils/RefreshLoop/refresh-loop";

class OnboardingLoader {
    private static subscriberId: number = 0;
    private readonly projectId: string;
    private readonly spaceId: string;

    constructor(projectId: string, spaceId: string) {
        this.projectId = projectId;
        this.spaceId = spaceId;
    }

    private subscribers: {
        [subscriberId: string]: (status: ProjectStatusResponse) => void;
    } = {};

    private readonly loop = new RefreshLoop(
        async (isLoopStillRunning) => {
            const status = await this.loadData();
            if (status.HasSuccessfulDeploymentsInSpace && isLoopStillRunning()) {
                this.loop.stop();
                this.triggerSubscribers(status); // Trigger subscribers one last time so they can refresh if necessary.
            } else {
                this.triggerSubscribers(status);
            }
        },
        (hidden) => (hidden ? 60000 : 2000)
    );

    subscribe = (callback: (status: ProjectStatusResponse) => void): (() => void) => {
        const subscriberId = ++OnboardingLoader.subscriberId;
        this.subscribers[subscriberId] = callback;

        if (Object.getOwnPropertyNames(this.subscribers).length === 1) {
            this.loop.start();
        }

        return () => {
            this.unsubscribe(subscriberId);
        };
    };

    private unsubscribe = (subscriberId: number) => {
        delete this.subscribers[subscriberId];
        if (Object.getOwnPropertyNames(this.subscribers).length === 0) {
            this.loop.stop();
        }
    };

    public loadData = async () => {
        const data = await repository.Projects.getProjectStatus(this.projectId, this.spaceId);

        return data;
    };

    private triggerSubscribers = (projectStatus: ProjectStatusResponse) => {
        const subscriberIds = Object.getOwnPropertyNames(this.subscribers);
        subscriberIds.forEach((s, _, subscribers) => {
            try {
                this.subscribers[s](projectStatus);
            } catch (ex) {
                //Ignore on purpose
            }
        }, this);
    };
}

export default OnboardingLoader;
