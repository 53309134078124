/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { logger } from "@octopusdeploy/logging";
import loadjs from "loadjs";
import type { ReactNode } from "react";
import type { DynamicExtensionsFeaturesMetadataResource } from "~/client/resources/dynamicExtensionsFeaturesMetadataResource";
import type { DynamicExtensionsScriptsResource } from "~/client/resources/dynamicExtensionsScriptsResource";
import { client, repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { OctopusHttpRequestEvent, OctopusHttpResponseEvent, OctopusHttpErrorResponseEvent } from ".";
import octopusDynamicExtensions, { OctopusEventName } from ".";

class DynamicExtensionsLoader extends DataBaseComponent<{}, {}> {
    componentDidMount() {
        // eslint-disable-next-line: no-floating-promises
        this.initialize();
    }

    render(): ReactNode {
        return null;
    }

    public async initialize() {
        try {
            const scripts = await repository.DynamicExtensions.getScripts();
            const metadata = await repository.DynamicExtensions.getFeaturesMetadata();
            await this.importOctopusDynamicExtensions(scripts, metadata);
        } catch (ex) {
            logger.error(ex, "Error loading Octopus dynamic extensions settings");
        }
    }

    private async importOctopusDynamicExtensions(scripts: DynamicExtensionsScriptsResource, metadata: DynamicExtensionsFeaturesMetadataResource) {
        try {
            if (!window.hasOwnProperty("DynamicExtensions") && scripts.Scripts.length > 0) {
                octopusDynamicExtensions.setConfiguration(scripts, metadata);

                client.setOnRequestCallback((details) => {
                    const e = {
                        correlationId: details.correlationId,
                        method: details.method,
                        url: details.url,
                    };
                    octopusDynamicExtensions.publishEvent<OctopusHttpRequestEvent>(OctopusEventName.HttpRequest, e);
                });
                client.setOnResponseCallback((details) => {
                    const e = {
                        correlationId: details.correlationId,
                        method: details.method,
                        url: details.url,
                        statusCode: details.statusCode,
                    };
                    octopusDynamicExtensions.publishEvent<OctopusHttpResponseEvent>(OctopusEventName.HttpResponse, e);
                });
                client.setOnErrorResponseCallback((details) => {
                    const e = {
                        correlationId: details.correlationId,
                        method: details.method,
                        url: details.url,
                        statusCode: details.statusCode,
                        errorMessage: details.errorMessage,
                        errors: details.errors,
                    };
                    octopusDynamicExtensions.publishEvent<OctopusHttpErrorResponseEvent>(OctopusEventName.HttpErrorResponse, e);
                });

                scripts.Scripts.map((script) => {
                    loadjs([script.Url], "dynamicExtensions", {
                        success() {
                            logger.info("Finished loading {script}", { script });
                        },
                        error(pathsNotFound) {
                            logger.error("Can't load {pathsNotFound}", { pathsNotFound });
                        },
                        before(path, scriptEl) {
                            if (script.SriHash) {
                                (scriptEl as unknown as HTMLScriptElement).integrity = script.SriHash;
                                (scriptEl as unknown as HTMLScriptElement).crossOrigin = "anonymous";
                            }
                            return true;
                        },
                    });
                });

                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).DynamicExtensions = octopusDynamicExtensions.createScriptAdapter();
            }
        } catch (err) {
            // Silently fail.
            logger.error(err, "Octopus dynamic extensions failed to import");
        }
    }
}

export default DynamicExtensionsLoader;
