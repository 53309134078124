/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import DialogContent from "@material-ui/core/DialogContent";
import * as React from "react";
import EventListener from "react-event-listener";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import Callout, { CalloutType } from "../../primitiveComponents/dataDisplay/Callout";
//We use the dialog content here, because we are using the dialog directly without a layout. Layouts already have padding applied.

interface Props {
    message: React.ReactNode;
    onContinueNavigating(): void;
    onCancelNavigation(): void;
    onSaveClick(isConfirmNavigate?: boolean): Promise<boolean>;
    saveLabel?: string;
}

interface State {
    show: boolean;
    success: boolean;
}

class ConfirmNavigate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: true,
            success: true,
        };
    }

    continueNavigating = () => {
        this.setState({
            show: false,
        });
        this.props.onContinueNavigating();
    };

    cancelNavigation = () => {
        this.setState({
            show: false,
        });
        this.props.onCancelNavigation();
    };

    handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 27 /*esc*/) {
            this.cancelNavigation();
        }
    };

    render() {
        const saveLabel = this.props.saveLabel ?? "Save Changes";

        const actions = [
            <ActionButton style={{ marginRight: "5px" }} key="SaveChanges" label={saveLabel} type={ActionButtonType.Secondary} onClick={this.onSaveClick} />,
            <ActionButton style={{ marginRight: "5px" }} key="discard" label="Discard Changes" type={ActionButtonType.Secondary} onClick={() => this.continueNavigating()} />,
            <ActionButton key="cancel" label="Cancel" type={ActionButtonType.Primary} keyboardFocused={true} onClick={() => this.cancelNavigation()} />,
        ];
        return (
            <Dialog title="Unsaved Changes" actions={actions} open={this.state.show}>
                <EventListener target="document" onKeyDown={this.handleKeyDown} />
                <DialogContent>
                    {this.props.message}
                    {!this.state.success && (
                        <Callout title="Errors found" type={CalloutType.Danger}>
                            Please cancel this dialog to review the errors (or discard your changes).
                        </Callout>
                    )}
                </DialogContent>
            </Dialog>
        );
    }

    private onSaveClick = async () => {
        this.setState({ show: false });
        const success: boolean = await this.props.onSaveClick(true);
    };
}

export default ConfirmNavigate;
