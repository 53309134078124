/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cloneDeep, sortBy } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticTrackedActionDispatcher, AnalyticViewDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticViewDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { CreateGitCredentialResponse, UsernamePasswordGitCredentialDetailsResource, GitCredentialResource, GitCredentialUsage, GitCredentialUsageProject } from "~/client/resources";
import { GitCredentialAuthenticationType, Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import Markdown from "~/components/Markdown";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import SimpleDataTable from "~/components/SimpleDataTable";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, MarkdownEditor, Sensitive, Note } from "~/components/form";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";

interface GitCredentialProps extends RouteComponentProps<{ gitCredentialId: string }> {
    create?: boolean;
}

interface GitCredentialPropsInternal extends GitCredentialProps {
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchView: AnalyticViewDispatcher;
}

interface GitCredentialState extends FormBaseComponentState<GitCredentialResource> {
    deleted: boolean;
    newId?: string;
    usage?: GitCredentialUsage;
}

class GitCredentialInternal extends FormBaseComponent<GitCredentialPropsInternal, GitCredentialState, GitCredentialResource> {
    private gitCredentialId: string;

    constructor(props: GitCredentialPropsInternal) {
        super(props);
        this.gitCredentialId = this.props.match.params.gitCredentialId;
        this.state = {
            model: null!,
            cleanModel: null!,
            deleted: false,
            usage: undefined,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }

    async load() {
        if (this.props.create) {
            const gitCredential: GitCredentialResource = {
                Id: null!,
                SpaceId: "",
                Name: "",
                Description: "",
                Details: {
                    // This is not a great way to do this, works fine for now because there
                    // is only 1 type, but maybe look at how the version control settings does
                    // this and might give us some hints.
                    Type: GitCredentialAuthenticationType.UsernamePassword,
                    Username: null!,
                    Password: null!,
                },
                Links: null!,
            };
            this.setState({
                model: gitCredential,
                cleanModel: cloneDeep(gitCredential),
            });
        } else {
            const gitCredential = await repository.GitCredentials.get(this.gitCredentialId);
            this.setState({
                model: gitCredential,
                cleanModel: cloneDeep(gitCredential),
            });
        }
    }

    descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description} />) : Summary.placeholder("No credential description provided");
    }

    credentialSummary() {
        return Summary.summary("Username and password");
    }

    usageSummary() {
        if (!this.state.usage) {
            return null;
        }

        const visibleCount = this.state.usage.Projects.length;
        const otherCount = this.state.usage.OtherProjects;
        const totalCount = visibleCount + otherCount;

        if (totalCount > 0) {
            return Summary.summary(
                <span>
                    This credential is being used in <b>{totalCount}</b> project{totalCount > 1 ? "s" : ""}
                </span>
            );
        }

        return Summary.placeholder("This credential is not being used in any projects");
    }

    usageHelp() {
        if (!this.state.usage) {
            return null;
        }

        const totalCount = this.state.usage.Projects.length + this.state.usage.OtherProjects;

        if (totalCount > 0) {
            return `This credential being used in the following project${totalCount > 1 ? "s" : ""}`;
        }

        return "This credential is not being used in any projects";
    }

    usageAdditionalInformation(usage?: GitCredentialUsage) {
        if (!usage) {
            return null;
        }

        if (usage.OtherProjects > 0) {
            if (usage.Projects.length > 0) {
                const single = usage.Projects.length > 1;

                const isAre = single ? "is" : "are";
                const projectProjects = single ? "project" : "projects";

                return (
                    <p>
                        There {isAre} {usage.OtherProjects} additional {projectProjects} (that you don't have permission to see) also using this credential.
                    </p>
                );
            }

            return <p>There are {usage.OtherProjects} projects (that you don't have permission to see) also using this credential.</p>;
        }

        return null;
    }

    setDetailsForUsernamePassword<K extends keyof UsernamePasswordGitCredentialDetailsResource>(state: Pick<UsernamePasswordGitCredentialDetailsResource, K>) {
        this.setChildState2("model", "Details", state);
    }

    handleSave = async () => {
        await this.doBusyTask(async () => {
            let id = this.gitCredentialId;
            const isNew = this.state.model.Id === null;

            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Git Credential",
            };

            await this.props.trackAction("Save Git Credential", ev, async () => {
                if (isNew) {
                    const response: CreateGitCredentialResponse = await repository.GitCredentials.create(this.state.model);
                    id = response.Id;
                } else {
                    await repository.GitCredentials.modify(this.state.model);
                }

                const newModel = await repository.GitCredentials.get(id);

                this.setState({
                    ...this.state,
                    model: newModel,
                    cleanModel: cloneDeep(newModel),
                    newId: isNew ? id : null!,
                    deleted: false,
                });
            });
        });

        return true;
    };

    handleDeleteConfirm = async () => {
        await repository.GitCredentials.del(this.state.model);
        this.setState(() => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    loadUsage = async () => {
        if (this.state.usage || this.props.create) {
            return;
        }
        await this.doBusyTask(
            async () => {
                const usage = await repository.GitCredentials.usage(this.state.model);
                usage.Projects = sortBy(usage.Projects, (p) => p.Name);

                this.setState({
                    usage,
                });
            },
            { timeOperationOptions: timeOperationOptions.for("Usage") }
        );
    };

    render() {
        const title = this.props.create ? "Create Git Credential" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const saveText = this.state.newId ? "Git credential created" : "Git credential updated";
        const overFlowActions = [OverflowMenuItems.deleteItemDefault("Git credential", this.handleDeleteConfirm, { permission: Permission.GitCredentialEdit })];

        return (
            <LibraryLayout>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Git Credentials"}
                    breadcrumbPath={routeLinks.library.gitCredentials.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: Permission.GitCredentialEdit }}
                    onSaveClick={this.handleSave}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.gitCredentials.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.library.gitCredentials.credential(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <UrlNavigationTabsContainer defaultValue={"details"}>
                                <TabItem label="Details" value="details" onActive={() => this.props.dispatchView("View Git Credential Details", { resource: "Git Credential" })}>
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Enter a name for your Git credential")}
                                        help="Enter a name for your Git credential"
                                    >
                                        <Text
                                            value={this.state.model.Name}
                                            onChange={(Name) => this.setModelState({ Name })}
                                            label="Git credential name"
                                            validate={required("Enter a Git credential name")}
                                            error={this.getFieldError("Name")}
                                            autoFocus
                                        />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your Git credential">
                                        <MarkdownEditor value={this.state.model.Description} label="Git credential description" onChange={(Description) => this.setModelState({ Description })} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="details" title="Credentials" summary={this.credentialSummary()} help="Enter the username and password">
                                        <Text
                                            key="Username"
                                            value={this.state.model.Details.Username}
                                            onChange={(Username) => this.setDetailsForUsernamePassword({ Username })}
                                            label="Username"
                                            error={this.getFieldError("Username")}
                                            validate={required("Enter authentication details.")}
                                            disabled={!!this.state.busy}
                                        />
                                        <Sensitive
                                            key="Password"
                                            value={this.state.model.Details.Password}
                                            onChange={(Password) => this.setDetailsForUsernamePassword({ Password })}
                                            label="Password or Personal Access Token"
                                            error={this.getFieldError("Password")}
                                            disabled={!!this.state.busy}
                                        />
                                    </ExpandableFormSection>
                                </TabItem>
                                {!this.props.create && (
                                    <TabItem
                                        label="Usage"
                                        value="usage"
                                        onActive={() => {
                                            this.props.dispatchView("View Git Credential Usage", { resource: "Git Credential" });
                                            this.loadUsage();
                                        }}
                                    >
                                        {this.state.usage && (
                                            <ExpandableFormSection
                                                key="usageInProjects"
                                                errorKey="usageInProjects"
                                                title="Projects"
                                                expandable={this.state.usage && this.state.usage.Projects.length > 0}
                                                summary={this.usageSummary()}
                                                help={this.usageHelp()}
                                            >
                                                <SimpleDataTable<GitCredentialUsageProject>
                                                    data={this.state.usage?.Projects}
                                                    headerColumns={["Project Name", "Repository URL"]}
                                                    onRow={(usageEntry) => [<InternalLink to={routeLinks.project(usageEntry.Slug).root}>{usageEntry.Name}</InternalLink>, usageEntry.RepositoryUrl]}
                                                />
                                                <Note>{this.usageAdditionalInformation(this.state.usage)}</Note>
                                            </ExpandableFormSection>
                                        )}
                                    </TabItem>
                                )}
                            </UrlNavigationTabsContainer>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </LibraryLayout>
        );
    }
}

export function GitCredential(props: GitCredentialProps) {
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchView = useAnalyticViewDispatch();

    return <GitCredentialInternal {...props} trackAction={trackAction} dispatchView={dispatchView} />;
}

export default GitCredential;
