import * as React from "react";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { getGitRefType, GitRefType, isGitBranch, toGitRefShort } from "~/client/resources/versionControlledResource";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { GitBranchIcon, GitCommitIcon, GitTagIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
import styles from "./style.module.less";

interface GitRefDisplayProps {
    currentHead?: GitRef;
    isDefaultBranch?: boolean;
    pullRequestLink?: string | undefined;
    onPullRequestLinkClick?: () => void;
}

export function GitRefDisplay(props: GitRefDisplayProps) {
    const gitRefName = props.currentHead ? toGitRefShort(props.currentHead) : "(none)";
    const gitRefType: GitRefType = props.currentHead ? getGitRefType(props.currentHead) : GitRefType.Branch;
    return (
        <div className={styles.currentBranch}>
            <span>Current {GetGitRefTypeDisplayName(gitRefType)}:</span>
            <span className={styles.iconContainer}>{GetGitIcon(gitRefType)}</span>
            <span className={styles.currentBranchValue}>
                {gitRefName} {props.isDefaultBranch ? " (default)" : ""}
            </span>
            <FeatureToggleVisibility toggle={"BranchProtectionsFeatureToggle"}>
                {isGitBranch(props.currentHead) && !props.isDefaultBranch && props.pullRequestLink && (
                    <span className={styles.pullRequestLink}>
                        <ExternalLink href={props.pullRequestLink} onClick={props.onPullRequestLinkClick} trackAnalytics={false}>
                            Create pull request
                        </ExternalLink>
                    </span>
                )}
            </FeatureToggleVisibility>
        </div>
    );
}

function GetGitIcon(gitRefType: GitRefType): JSX.Element {
    if (gitRefType === GitRefType.Commit) {
        return <GitCommitIcon fontSize={"inherit"} />;
    } else if (gitRefType === GitRefType.Tag) {
        return <GitTagIcon fontSize={"inherit"} />;
    }

    return <GitBranchIcon fontSize={"inherit"} />;
}

function GetGitRefTypeDisplayName(gitRefType: GitRefType): string {
    if (gitRefType === GitRefType.Commit) {
        return "commit";
    } else if (gitRefType === GitRefType.Tag) {
        return "tag";
    }

    return "branch";
}
