import type { ActionTemplateResource } from "~/client/resources/actionTemplateResource";
import type { CommunityActionTemplateResource } from "~/client/resources/communityActionTemplateResource";
import type { Client } from "../client";

export class CommunityActionTemplateRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    get(id: string) {
        const allArgs = { ...{}, ...{ id } };
        return this.client.get<CommunityActionTemplateResource>(this.client.getLink("CommunityActionTemplates"), allArgs);
    }
    install(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.post<ActionTemplateResource>(communityActionTemplate.Links["Installation"]);
    }
    updateInstallation(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.put<ActionTemplateResource>(communityActionTemplate.Links["Installation"]);
    }
}
