import * as React from "react";
import type { SFC } from "react";
import type { ArtifactResource } from "~/client/resources/artifactResource";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import styles from "./style.module.less";

const ArtifactLink: SFC<{ artifact: ArtifactResource }> = (props) => (
    <ExternalLink href={repository.resolve(props.artifact.Links["Content"])} showIcon={false} key={props.artifact.Id} openInSelf={true} className={styles.fontSizeSmall} trackAnalytics={false}>
        {props.artifact.Filename}
    </ExternalLink>
);

ArtifactLink.displayName = "ArtifactLink";

export default ArtifactLink;
