import React from "react";
import type { GitRefResource } from "~/client/resources/index";
import { isProtectedBranch } from "~/client/resources/index";
import { isGitCommit, isGitTag, toGitCommitShort } from "~/client/resources/versionControlledResource";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";

type Props = {
    gitRef?: GitRefResource | undefined;
};

const CannotCommitCallout = ({ gitRef }: Props) => {
    const branchProtectionsAreEnabled = useEnabledFeatureToggle("BranchProtectionsFeatureToggle");
    if (!gitRef || !branchProtectionsAreEnabled) {
        return <></>;
    }

    const renderWithTypeName = (title: string, message: string) => (
        <Callout title={title} type={CalloutType.Information} canClose={false}>
            {message}
        </Callout>
    );

    const canonicalName = gitRef.CanonicalName;
    if (isProtectedBranch(gitRef)) {
        return renderWithTypeName(`${canonicalName} is a protected branch`, "Protected branches cannot be modified.");
    } else if (isGitTag(canonicalName)) {
        return renderWithTypeName(`${canonicalName} is a tag`, "Tags cannot be modified.");
    } else if (isGitCommit(canonicalName)) {
        return renderWithTypeName(`${toGitCommitShort(canonicalName)} is a commit`, "Commits cannot be modified.");
    } else {
        return <></>;
    }
};

export default CannotCommitCallout;
