import type { ContainerImageReference } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, RuntimeContainerImageSelection } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { ContainerImageSelectorComponent } from "@octopusdeploy/step-ui";
import React from "react";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { convertFromRuntimeContainerImageSelection, convertToRuntimeContainerImageSelection } from "~/components/StepPackageEditor/Inputs/Components/ContainerImageSelector/ContainerImageConverters";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { FeedType } from "../../../../../client/resources/feedResource";
import type { StepInputDependencies } from "../../../StepInputDependencies";
import { isProjectStepInputDependencies } from "../../../StepInputDependencies";

export function getErrorPathsForContainerImageReferences(content: ContainerImageSelectorComponent): PathToInput[] {
    const inputPath = getPathToInput(content.input);
    return [getInputPathForContainerImageSelectionProperty(inputPath, "imageName"), getInputPathForContainerImageSelectionProperty(inputPath, "feedId")];
}

function getInputPathForContainerImageSelectionProperty(pathToInput: PathToInput, propertyName: keyof RuntimeContainerImageSelection) {
    return [...pathToInput, propertyName];
}

export function getContainerImageSelectorSummary<StepInputs>(component: ContainerImageSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const runtimePackageSelection = convertToRuntimeContainerImageSelection(inputValue);
        if (runtimePackageSelection.feedId === undefined || runtimePackageSelection.imageName === undefined) {
            return "empty";
        }
        return { isDefaultValue: false, value: runtimePackageSelection.imageName };
    }
    // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
    throw new Error("Package selection cannot be bound");
}

interface ContainerImageSelectorProps<StepInputs> {
    configuredStepUIProps: ContainerImageSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: StepInputDependencies;
    getFieldError: (path: PathToInput) => string;
}

export function ContainerImageSelector<StepInputs>(props: ContainerImageSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, ContainerImageReference>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);

    // todo-step-ui add support for parameterised package names
    if (isNotBoundValue(inputValue)) {
        const containerImageSelection = convertToRuntimeContainerImageSelection(inputValue);

        return (
            <PackageSelector
                packageId={containerImageSelection.imageName}
                feedId={containerImageSelection.feedId}
                onPackageIdChange={(imageName) => {
                    const updatedContainerImageSelection: RuntimeContainerImageSelection = { ...containerImageSelection, imageName }; // todo-step-ui: fill in reference
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeContainerImageSelection(updatedContainerImageSelection));
                    props.setInputs(updatedInputs);
                }}
                onFeedIdChange={(feedId) => {
                    const updatedContainerImageSelection: RuntimeContainerImageSelection = { ...containerImageSelection, feedId }; // todo-step-ui: fill in reference
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeContainerImageSelection(updatedContainerImageSelection));
                    props.setInputs(updatedInputs);
                }}
                projectId={isProjectStepInputDependencies(props.dependencies) ? props.dependencies.projectId : undefined}
                feeds={props.dependencies.feeds}
                localNames={isProjectStepInputDependencies(props.dependencies) ? undefined : props.dependencies.localNames}
                refreshFeeds={props.dependencies.refreshFeeds}
                feedIdError={props.getFieldError(getInputPathForContainerImageSelectionProperty(inputPath, "feedId"))}
                packageIdError={props.getFieldError(getInputPathForContainerImageSelectionProperty(inputPath, "imageName"))}
                feedType={[FeedType.Docker, FeedType.AwsElasticContainerRegistry]}
                //We will need to add additional errors here once we support all the other things e.g. package parameters and selection mode for example
            />
        );
    } else {
        // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
        throw new Error("Package selection can't be bound");
    }
}
