/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import type { SvgIcon } from "@material-ui/core";
import DynamicEnvironmentIcon from "@material-ui/icons/AcUnit";
import SvgTenantIcon from "@material-ui/icons/AccountCircle";
import SvgChannelIcon from "@material-ui/icons/CallSplit";
import SvgTeamIcon from "@material-ui/icons/Group";
import SvgSpaceIcon from "@material-ui/icons/GroupWork";
import SvgRoleIcon from "@material-ui/icons/LocalOffer";
import WarningIcon from "@material-ui/icons/PriorityHigh";
import moment from "moment";
import type { CSSProperties } from "react";
import * as React from "react";
import type {
    ApiKeyResource,
    CertificateResource,
    CommunicationStyleResource,
    MachineModelHealthStatusResource,
    NamedResource,
    RunbookResource,
    ProjectedTeamReferenceDataItem,
    SpaceResource,
    TeamMembership,
    TenantResource,
    WorkerPoolResource,
    IId,
} from "~/client/resources";
import { ProcessType } from "~/client/resources";
import type { DocumentTypeResource, EventAgentResource, EventCategoryResource, EventGroupResource } from "~/client/resources/eventResource";
import type { NamedReferenceItem } from "~/client/resources/namedReferenceItem";
import type { ProjectGroupResource } from "~/client/resources/projectGroupResource";
import type { TeamNameResource, TeamResource } from "~/client/resources/teamResource";
import type { UserRoleResource } from "~/client/resources/userRoleResource";
import { WorkerPoolType } from "~/client/resources/workerPoolsSupportedTypesResouce";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme, withTheme } from "~/components/Theme";
import type { EnvironmentResource } from "../../client/resources";
import type { DeploymentActionPackageResource } from "../../client/resources/deploymentActionPackageResource";
import { displayName } from "../../client/resources/deploymentActionPackageResource";
import {
    DeploymentProcessIcon,
    DynamicWorkerPoolIcon,
    EnvironmentIcon,
    ExcludedEnvironmentIcon,
    ExcludedMachineIcon,
    MachineIcon,
    ProjectIcon,
    RunbookProcessIcon,
    ShellNameIcon,
    UnavailableMachineIcon,
    UnhealthyMachineIcon,
    WorkerPoolIcon,
} from "../../primitiveComponents/dataDisplay/Icon/OctopusIcon"; // TODO Need to use OctopusIcon moving forward. For now, using this approach
import type { DistributiveOmit } from "../../utils/distributiveMappedTypes";
import { LookupResourceChipComponent } from "../LookupResourceChip/LookupResourceChip";
import { useSpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import Chip from "./Chip";
import type { DeletableChipProps } from "./DeletableChip";
import DeletableChip from "./DeletableChip";
//TODO: Material icons should be coming from ThirdPartyIcon, we are keeping these direct imports here for now.
import { IconStyledForChip } from "./IconStyledForChips";
import type { NavigationChipProps } from "./NavigationChip";
import type { CommonChipProps } from "./types";

export type CommonOrDeletableChipProps = (CommonChipProps | DeletableChipProps) & Partial<NavigationChipProps>;

export function isDeletableChip(chipProps: CommonChipProps): chipProps is DeletableChipProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (chipProps as DeletableChipProps).onRequestDelete !== undefined && (chipProps as DeletableChipProps).deleteButtonAccessibleName !== undefined;
}

type IconStyles = Pick<CSSProperties, "margin" | "fill" | "width" | "height" | "backgroundColor">;

type ChipOrDeletableChipProps = {
    text: string;
    icon?: React.ReactElement;
} & CommonOrDeletableChipProps;

function ChipOrDeletableChip(props: ChipOrDeletableChipProps): React.ReactElement | null {
    const history = useSpaceAwareNavigation();
    const theme = useOctopusTheme();

    const commonChipProps = getCommonChipProps(props);

    if (isDeletableChip(props)) {
        return (
            <DeletableChip onRequestDelete={props.onRequestDelete} deleteButtonAccessibleName={props.deleteButtonAccessibleName} {...commonChipProps}>
                {props.text}
            </DeletableChip>
        );
    }
    const to = props.to;
    return (
        <Chip {...commonChipProps} onClick={to ? () => history.redirect(to) : undefined}>
            {props.text}
        </Chip>
    );
}

function getCommonChipProps(props: CommonOrDeletableChipProps & Partial<NavigationChipProps>): CommonChipProps {
    if (isDeletableChip(props)) {
        return getCommonChipPropsForDeletableChip(props);
    }

    const { to, ...commonProps } = props;

    return commonProps;
}

function getCommonChipPropsForDeletableChip(props: DeletableChipProps & Partial<NavigationChipProps>): CommonChipProps {
    const { deleteButtonAccessibleName, onRequestDelete, to, ...commonProps } = props;
    return commonProps;
}

export enum ChipIcon {
    Project,
    Machine,
    ExcludedMachine,
    Environment,
    ExcludedEnvironment,
    Role,
    ShellName,
    Tenant,
    Channel,
    Team,
    Step,
    StepAction,
    ProjectGroup,
    CommunicationStyle,
    EventCategory,
    EventGroup,
    EventAgent,
    MachineModelHealthStatus,
    DocumentType,
    LibraryVariableSet,
    Space,
    Runbook,
    DeploymentProcess,
    Warning,
}

// NOTE:
// This is used to show the user only what they can see, it may be filtered away by permissions
export function matchesToChips<T extends IId>(set: T[], selected: string[], createChip: (x: T) => JSX.Element): JSX.Element[] {
    return set.filter((item) => selected && selected.indexOf(item.Id) !== -1).map((item) => createChip(item));
}

export function channelChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ChannelChip channelName={x.Name} key={x.Id} />);
}

export function environmentChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EnvironmentChip environmentName={x.Name} key={x.Id} />);
}

export function workerPoolChipList(set: WorkerPoolResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <WorkerPoolChip workerPoolName={x.Name} key={x.Id} workerPoolType={x.WorkerPoolType} />);
}

// NOTE:
// We have some inconsistency in the application where on summaries we filter away what the user cannot see due to permissions
// eg environments, but if they expand the multiselect we show them missing chips
// we should aim to move to this approach in `environmentChipListIncludingMissing` and SHOW them Ids and Names
// it will require a new API (as not to break other things)
// the objective is to  drive people to Spaces for isolation instead of using the permissions system awkwardly
export function environmentChipListIncludingMissing(set: EnvironmentResource[], selectedIds: string[]) {
    const EnvironmentLookupChipInternal = LookupResourceChipComponent<EnvironmentResource>();

    return selectedIds.map((id) => <EnvironmentLookupChipInternal lookupCollection={set} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />);
}

export function tenantChipList(set: TenantResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <TenantChip tenantName={x.Name} key={x.Id} />);
}

// Same applies environmentChipListIncludingMissing
// future goal to replace all the variants with this approach
export function tenantChipListIncludingMissing(set: TenantResource[], selectedIds: string[]) {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();

    return selectedIds.map((id) => <TenantLookupChipInternal lookupCollection={set} key={id} lookupId={id} type={ChipIcon.Tenant} chipRender={(item) => <TenantChip tenantName={item.Name} />} />);
}

export function projectGroupChipList(set: ProjectGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ProjectGroupChip projectGroup={x} key={x.Id} />);
}

export function projectChipList(set: { Id: string; Name: string }[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ProjectChip projectName={x.Name} key={x.Id} />);
}

export function documentChipList(set: DocumentTypeResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <DocumentTypeChip documentType={x} key={x.Id} />);
}

export function eventCategoryList(set: EventCategoryResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventCategoryChip eventCategory={x} key={x.Id} />);
}

export function eventGroupList(set: EventGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventGroupChip eventGroup={x} key={x.Id} />);
}

export function eventAgentList(set: EventAgentResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventAgentChip eventAgent={x} key={x.Id} />);
}

export function spaceChipList(set: SpaceResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <SpaceChip space={x} key={x.Id} />);
}

type MissingOrGhostChipProps = { lookupId: string; type?: ChipIcon; text?: string; description?: string } & CommonOrDeletableChipProps;

const MissingChip: React.FC<MissingOrGhostChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { lookupId, type, text, description, ...rest } = props;
    const chipText = text ?? "Missing Resource";

    // There are cases where this isn't right, but will just have 1 message to simplify
    // e.g. Variable Snapshots will show this for deleted environments, it's not an issue in that case, but good to show this.
    const chipDescription =
        description ??
        `The ${ChipIcon[type!]} document '${lookupId}' referenced by this record is no longer available or ` +
            "you do not have permissions to see this resource. Please check with your Octopus Administrator regarding your " +
            "permissions. If you believe the resource is missing (and this is not permissions-related), please let Octopus " +
            "support know so that we can prevent this from happening in the future.";

    const renderChip = (svg: typeof SvgIcon) => (
        <ChipOrDeletableChip
            text={chipText}
            icon={<IconStyledForChip iconComponent={svg} iconColor={theme.whiteConstant} backgroundColor={theme.dangerConstant} />}
            backgroundColor={theme.chipBackground}
            description={chipDescription}
            labelColor={theme.chipText}
            {...rest}
        />
    );

    //TODO: We should re-evaluate the use of an enum here and find an alternate way to do this as it forces us to be exhausitve, when we really shouldn't be. A shell name icon for example makes 0 sense as a missing chip.
    switch (type) {
        case ChipIcon.Project:
            return renderChip(ProjectIcon);
        case ChipIcon.Machine:
            return renderChip(MachineIcon);
        case ChipIcon.ExcludedMachine:
            return renderChip(ExcludedMachineIcon);
        case ChipIcon.Environment:
            return renderChip(EnvironmentIcon);
        case ChipIcon.ExcludedEnvironment:
            return renderChip(ExcludedEnvironmentIcon);
        case ChipIcon.Role:
            return renderChip(SvgRoleIcon);
        case ChipIcon.ShellName:
            return renderChip(ShellNameIcon);
        case ChipIcon.Tenant:
            return renderChip(SvgTenantIcon);
        case ChipIcon.Channel:
            return renderChip(SvgChannelIcon);
        case ChipIcon.Team:
            return renderChip(SvgTeamIcon);
        case ChipIcon.Space:
            return renderChip(SvgSpaceIcon);
        case ChipIcon.Runbook:
            return renderChip(RunbookProcessIcon);
        case ChipIcon.DeploymentProcess:
            return renderChip(DeploymentProcessIcon);
        case ChipIcon.Step:
        case ChipIcon.StepAction:
        case ChipIcon.ProjectGroup:
        case ChipIcon.CommunicationStyle:
        case ChipIcon.EventCategory:
        case ChipIcon.EventGroup:
        case ChipIcon.EventAgent:
        case ChipIcon.MachineModelHealthStatus:
        case ChipIcon.DocumentType:
            return <ChipOrDeletableChip text={chipText} backgroundColor={theme.dangerBackground} labelColor={theme.dangerText} description={description} {...rest} />;
        case ChipIcon.Warning:
        default:
            return renderChip(WarningIcon);
    }
};

export const GhostChip: React.FC<MissingOrGhostChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { lookupId, type, text, description, ...rest } = props;
    const chipDescription = description ?? `The reference '${lookupId}' could not be found. Please check the specified name and permissions for the resource.`;

    const renderChip = (svg: typeof SvgIcon) => (
        <ChipOrDeletableChip
            text={text ?? props.lookupId}
            icon={<IconStyledForChip iconComponent={svg} iconColor={theme.alert} backgroundColor="transparent" />}
            backgroundColor={theme.transparent}
            borderColor={theme.divider}
            description={chipDescription}
            labelColor={theme.chipText}
            variant={"outlined"}
            {...rest}
        />
    );

    switch (type) {
        case ChipIcon.Project:
            return renderChip(ProjectIcon);
        case ChipIcon.Machine:
            return renderChip(MachineIcon);
        case ChipIcon.ExcludedMachine:
            return renderChip(ExcludedMachineIcon);
        case ChipIcon.Environment:
            return renderChip(EnvironmentIcon);
        case ChipIcon.ExcludedEnvironment:
            return renderChip(ExcludedEnvironmentIcon);
        case ChipIcon.Role:
            return renderChip(SvgRoleIcon);
        case ChipIcon.ShellName:
            return renderChip(ShellNameIcon);
        case ChipIcon.Tenant:
            return renderChip(SvgTenantIcon);
        case ChipIcon.Channel:
            return renderChip(SvgChannelIcon);
        case ChipIcon.Team:
            return renderChip(SvgTeamIcon);
        case ChipIcon.Space:
            return renderChip(SvgSpaceIcon);
        case ChipIcon.Runbook:
            return renderChip(RunbookProcessIcon);
        case ChipIcon.DeploymentProcess:
            return renderChip(DeploymentProcessIcon);
        case ChipIcon.Step:
        case ChipIcon.StepAction:
        case ChipIcon.ProjectGroup:
        case ChipIcon.CommunicationStyle:
        case ChipIcon.EventCategory:
        case ChipIcon.EventGroup:
        case ChipIcon.EventAgent:
        case ChipIcon.MachineModelHealthStatus:
        case ChipIcon.DocumentType:
            return <ChipOrDeletableChip text={props.lookupId} backgroundColor={theme.alertBackground} labelColor={theme.alertText} description={description} {...rest} />;
        case ChipIcon.Warning:
        default:
            return renderChip(WarningIcon);
    }
};

const hasDeleteChipPropCallback = (props: CommonOrDeletableChipProps): props is CommonChipProps & Pick<DeletableChipProps, "onRequestDelete"> => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = props as DeletableChipProps;
    return typeof converted.onRequestDelete === "function";
};

type ContextualMissingChipProps = DistributiveOmit<MissingOrGhostChipProps, "lookupId"> & {
    lookupKey: string;
};

export const ContextualMissingChip: React.FC<ContextualMissingChipProps> = ({ lookupKey, ...rest }) => {
    const ChipComponent = MissingChip;
    const propsToSpread = hasDeleteChipPropCallback(rest) ? { ...rest, deleteButtonAccessibleName: "Delete Missing Resource" } : rest;

    return <ChipComponent lookupId={lookupKey} {...propsToSpread} />;
};

export default ContextualMissingChip;

type ProcessChipProps = { processType: ProcessType | undefined; name: string } & CommonOrDeletableChipProps;

const ProcessChip: React.FC<ProcessChipProps> = ({ processType, name, ...rest }) => {
    switch (processType) {
        case ProcessType.Deployment:
            return <ChipOrDeletableChip text={name} icon={<IconStyledForChip iconComponent={DeploymentProcessIcon} />} description={name} {...rest} />;
        case ProcessType.Runbook:
            return <ChipOrDeletableChip text={name} icon={<IconStyledForChip iconComponent={RunbookProcessIcon} />} description={`Runbook: ${name}`} {...rest} />;
    }

    return null;
};

const ProjectChip: React.FC<{ projectName: string } & CommonOrDeletableChipProps> = (props) => {
    const { projectName, ...rest } = props;
    return <ChipOrDeletableChip text={projectName} icon={<IconStyledForChip iconComponent={ProjectIcon} />} description={`Project: ${projectName}`} {...rest} />;
};

const ProjectGroupChip: React.FC<{ projectGroup: ProjectGroupResource } & CommonOrDeletableChipProps> = (props) => {
    const { projectGroup, ...rest } = props;
    const description = "Project group: " + projectGroup.Name;
    return <ChipOrDeletableChip text={projectGroup.Name} description={description} {...rest} />;
};

const RunbookChip: React.FC<{ runbook: RunbookResource } & CommonOrDeletableChipProps> = (props) => {
    const { runbook, ...rest } = props;
    return <ChipOrDeletableChip text={runbook.Name} icon={<IconStyledForChip iconComponent={RunbookProcessIcon} />} description={`Runbook: ${runbook.Name}`} {...rest} />;
};

const MachineModelHealthStatusChip: React.FC<
    {
        healthStatus: MachineModelHealthStatusResource;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { healthStatus, ...rest } = props;
    const description = "Machine health: " + healthStatus.Name;
    return <ChipOrDeletableChip text={healthStatus.Name} description={description} {...rest} />;
};

const EndpointCommunicationStyleChip: React.FC<
    {
        commStyle: CommunicationStyleResource;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { commStyle, ...rest } = props;
    const description = "Communications style: " + commStyle.Name;
    return <ChipOrDeletableChip text={commStyle.Name} description={description} {...rest} />;
};

const DeploymentTargetTypeChip: React.FC<
    {
        endpointRegistrationName: string;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { endpointRegistrationName, ...rest } = props;
    const description = "Deployment target type: " + endpointRegistrationName;
    return <ChipOrDeletableChip text={endpointRegistrationName} description={description} {...rest} />;
};

const DeploymentActionChip: React.FC<
    {
        stepName: string;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { stepName, ...rest } = props;
    const description = "Step: " + stepName;
    return <ChipOrDeletableChip text={stepName} description={description} {...rest} />;
};

const EnvironmentChip: React.FC<
    {
        environmentName: string;
        isExcluded?: boolean;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { environmentName, isExcluded, ...rest } = props;
    return <ChipOrDeletableChip text={environmentName} icon={<IconStyledForChip iconComponent={isExcluded ? ExcludedEnvironmentIcon : EnvironmentIcon} />} description={`Environment: ${environmentName}`} markAsRemoved={isExcluded} {...rest} />;
};

const DynamicEnvironmentChip: React.FC<
    {
        environmentName: string;
        isNew?: boolean;
        description?: string;
        isExcluded?: boolean;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { environmentName, isExcluded, description, isNew, ...rest } = props;
    const text = isNew ? `${environmentName} (new)` : environmentName;
    return <ChipOrDeletableChip text={text} icon={<IconStyledForChip iconComponent={DynamicEnvironmentIcon} />} description={description ?? `Dynamic Environment: ${environmentName}`} markAsRemoved={isExcluded} {...rest} />;
};

const WorkerPoolChip: React.FC<
    {
        workerPoolName: string;
        isExcluded?: boolean;
        workerPoolType: WorkerPoolType;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { workerPoolName, isExcluded, workerPoolType, ...rest } = props;
    const icon = workerPoolType === WorkerPoolType.Static ? WorkerPoolIcon : DynamicWorkerPoolIcon;
    return (
        <ChipOrDeletableChip
            text={workerPoolName}
            icon={<IconStyledForChip iconComponent={isExcluded ? ExcludedEnvironmentIcon : icon} />}
            description={workerPoolType === WorkerPoolType.Static ? `Worker pool: ${workerPoolName}` : `Dynamic worker pool: ${workerPoolName}`}
            markAsRemoved={isExcluded}
            {...rest}
        />
    );
};

const MachineChip: React.FC<
    {
        machineName: string;
        isExcluded?: boolean;
        isDisable?: boolean;
        isUnhealthy?: boolean;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { machineName, isExcluded, isDisable, isUnhealthy, ...rest } = props;
    const theme = useOctopusTheme();

    let icon: typeof SvgIcon;

    if (isExcluded || isDisable) {
        icon = UnavailableMachineIcon;
    } else if (isUnhealthy) {
        icon = UnhealthyMachineIcon;
    } else {
        icon = MachineIcon;
    }

    return (
        <ChipOrDeletableChip
            text={machineName}
            icon={<IconStyledForChip iconComponent={icon} />}
            description={`Machine: ${machineName}`}
            markAsRemoved={isExcluded}
            backgroundColor={isDisable || isUnhealthy ? theme.dangerBackground : theme.chipBackground}
            labelColor={isDisable || isUnhealthy ? theme.dangerText : theme.chipText}
            {...rest}
        />
    );
};

const TenantChip: React.FC<{ tenantName: string } & CommonOrDeletableChipProps> = (props) => {
    const { tenantName, ...rest } = props;
    return <ChipOrDeletableChip text={tenantName} icon={<IconStyledForChip iconComponent={SvgTenantIcon} />} description={`Tenant: ${tenantName}`} {...rest} />;
};

const TeamChip: React.FC<{ team: TeamResource | TeamNameResource | ProjectedTeamReferenceDataItem | TeamMembership } & CommonOrDeletableChipProps & { descriptionPostfix?: string }> = (props) => {
    const { team, ...rest } = props;

    const teamName = "TeamName" in team ? team.TeamName : team.Name;
    const desc = !rest.descriptionPostfix ? "Team: " + teamName : "Team: " + teamName + rest.descriptionPostfix;
    return <ChipOrDeletableChip text={teamName} icon={<IconStyledForChip iconComponent={SvgTenantIcon} />} description={desc} {...rest} />;
};

const ChannelChip: React.FC<{ channelName: string } & CommonOrDeletableChipProps> = (props) => {
    const { channelName, ...rest } = props;
    return <ChipOrDeletableChip text={channelName} icon={<IconStyledForChip iconComponent={SvgChannelIcon} />} description={`Channel: ${channelName}`} {...rest} />;
};

const RoleChip: React.FC<{ role: string } & CommonOrDeletableChipProps> = (props) => {
    const { role, ...rest } = props;
    return <ChipOrDeletableChip text={role} icon={<IconStyledForChip iconComponent={SvgRoleIcon} />} description={`Role: ${role}`} {...rest} />;
};

const ShellNameChip: React.FC<{ shellName: string } & CommonOrDeletableChipProps> = (props) => {
    const { shellName, ...rest } = props;
    return <ChipOrDeletableChip text={shellName} icon={<IconStyledForChip iconComponent={ShellNameIcon} />} description={`Shell: ${shellName}`} {...rest} />;
};

const CertificateExpiryChip: React.FC<{ certificate: CertificateResource } & CommonOrDeletableChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { certificate, ...rest } = props;
    const now = moment();
    const certificateExpiry = moment(certificate.NotAfter);
    const prefix = certificateExpiry.isAfter(now) ? "Expires " : "Expired ";
    const expiry = prefix + certificateExpiry.fromNow();
    let color = theme.success;
    if (certificateExpiry.isBefore(now)) {
        color = theme.danger;
    } else if (certificateExpiry.isBefore(now.add(20, "days"))) {
        color = theme.alert;
    }
    const description = "Certificate expiry: " + expiry;
    return (
        <Chip description={description} backgroundColor={"#00000000"} borderColor={color} labelColor={color} {...rest}>
            {expiry}
        </Chip>
    );
};

const ApiKeyExpiryChip: React.FC<{ apiKey: ApiKeyResource } & CommonOrDeletableChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { apiKey, ...rest } = props;
    let color = theme.success;
    let expiry = "Does not expire";
    if (apiKey.Expires) {
        const apiKeyExpiry = moment(apiKey.Expires);
        const now = moment();
        if (apiKeyExpiry.isBefore(now)) {
            color = theme.danger;
        } else if (apiKeyExpiry.isBefore(now.clone().add(20, "days"))) {
            color = theme.alert;
        }
        const prefix = apiKeyExpiry.isAfter(now) ? "Expires " : "Expired ";
        expiry = prefix + apiKeyExpiry.fromNow();
    }
    const description = "API Key expiry: " + expiry;
    return (
        <Chip description={description} backgroundColor={"#00000000"} borderColor={color} labelColor={color} {...rest}>
            {expiry}
        </Chip>
    );
};

const StepChip: React.FC<{ stepName: string } & CommonOrDeletableChipProps> = (props) => {
    const { stepName, ...rest } = props;
    const description = "Step: " + stepName;
    return <ChipOrDeletableChip text={stepName} description={description} {...rest} />;
};

const DeploymentActionPackageChip: React.FC<{ actionPackage: DeploymentActionPackageResource } & CommonOrDeletableChipProps> = (props) => {
    const { actionPackage, ...rest } = props;
    const description = !actionPackage.PackageReference ? `Step ${actionPackage.DeploymentAction}` : `Package ${actionPackage.PackageReference} from step ${actionPackage.DeploymentAction}`;
    return <ChipOrDeletableChip text={displayName(actionPackage)} description={description} {...rest} />;
};

const UserRoleChip: React.FC<{ userRole: UserRoleResource } & CommonOrDeletableChipProps> = (props) => {
    const { userRole, ...rest } = props;
    return <ChipOrDeletableChip text={userRole.Name} {...rest} />;
};

const ExternalSecurityGroupChip: React.FC<{ group: NamedReferenceItem } & CommonOrDeletableChipProps> = (props) => {
    const { group, ...rest } = props;
    const fullName = group.DisplayIdAndName ? `${group.DisplayName} (${group.Id})` : group.DisplayName;
    return <ChipOrDeletableChip text={group.DisplayName} description={`Indirectly assigned via ${fullName}`} {...rest} />;
};

const FilterTextChip: React.FC<{ filterText: string } & CommonOrDeletableChipProps> = (props) => {
    const { filterText, ...rest } = props;
    return <ChipOrDeletableChip text={filterText} {...rest} />;
};

const EventCategoryChip: React.FC<{ eventCategory: EventCategoryResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return <ChipOrDeletableChip description={description} text={eventCategory.Name} {...rest} />;
};

const EventCategoryPreviewChip: React.FC<{ eventCategory: EventCategoryResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return <ChipOrDeletableChip text={eventCategory.Name} description={description} {...rest} />;
};

const EventAgentChip: React.FC<{ eventAgent: EventAgentResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventAgent, ...rest } = props;
    const description = "Event agent: " + eventAgent.Name;
    return <ChipOrDeletableChip text={eventAgent.Name} description={description} {...rest} />;
};

const DocumentTypeChip: React.FC<{ documentType: DocumentTypeResource } & CommonOrDeletableChipProps> = (props) => {
    const { documentType, ...rest } = props;
    const description = "Document type: " + documentType.Name;
    return <ChipOrDeletableChip text={documentType.Name} description={description} {...rest} />;
};

const EventGroupChip: React.FC<{ eventGroup: EventGroupResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventGroup, ...rest } = props;
    const description = "Event group: " + eventGroup.Name;
    return <ChipOrDeletableChip text={eventGroup.Name} description={description} {...rest} />;
};

const DisabledChip: React.FC<CommonOrDeletableChipProps> = (props) =>
    withTheme((theme) => <ChipOrDeletableChip text={"Disabled"} {...props} borderColor={theme.disabledButtonBorder} labelColor={theme.disabledButtonText} backgroundColor={theme.transparent} />);

const DefaultOptionChip: React.FC<CommonOrDeletableChipProps> = (props) => {
    return <ChipOrDeletableChip text={"Default"} {...props} />;
};

const LookupTenantChip: React.FC<{ lookupTenants: TenantResource[]; id: string } & CommonOrDeletableChipProps> = (props) => {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();
    return <TenantLookupChipInternal lookupCollection={props.lookupTenants} key={props.id} lookupId={props.id} type={ChipIcon.Tenant} chipRender={(item) => <TenantChip tenantName={item.Name} to={props.to} />} />;
};

const SpaceChip: React.FC<{ space: SpaceResource; description?: string } & CommonOrDeletableChipProps> = (props) => {
    const { space, description, ...rest } = props;
    return <ChipOrDeletableChip text={space.Name} icon={<IconStyledForChip iconComponent={SvgSpaceIcon} />} description={description || space.Description} {...rest} />;
};

const DefaultSpaceChip: React.FC<CommonOrDeletableChipProps> = (props) => {
    return <ChipOrDeletableChip text={"Default"} {...props} description="Default Space" />;
};

const EarlyAccessChip: React.FC<CommonChipProps> = (props) =>
    withTheme((theme: OctopusTheme) => (
        <Chip backgroundColor={theme.alertHighlight} borderColor={theme.alert} labelColor={theme.alert} {...props} description={"Early access features are still in development and should not be used in critical projects."}>
            EAP
        </Chip>
    ));

const RunbookSnapshotPublishedChip: React.FC<CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.success} labelColor={theme.paper0} {...props} description={"This published snapshot is ready to run."}>
            Published
        </Chip>
    ));

const NewFeatureChip: React.FC<CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.featureBackground} borderColor={theme.cyanConstant} labelColor={theme.featureText} {...props} description="New Feature">
            NEW
        </Chip>
    ));

const WarningChip: React.FC<{ description: string; title: string } & CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.alertBackground} borderColor={theme.alertBorder} labelColor={theme.alertHeaderText} {...props} description={props.description}>
            {props.title}
        </Chip>
    ));

const DangerChip: React.FC<{ description: string; title: string } & CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.dangerBackground} borderColor={theme.dangerBorder} labelColor={theme.dangerHeaderText} {...props} description={props.description}>
            {props.title}
        </Chip>
    ));

export {
    MissingChip,
    ProjectChip,
    RunbookChip,
    ProjectGroupChip,
    EnvironmentChip,
    WorkerPoolChip,
    RoleChip,
    ShellNameChip,
    TenantChip,
    TeamChip,
    ChannelChip,
    MachineChip,
    CertificateExpiryChip,
    ApiKeyExpiryChip,
    MachineModelHealthStatusChip,
    EndpointCommunicationStyleChip,
    DeploymentTargetTypeChip,
    StepChip,
    DeploymentActionPackageChip,
    UserRoleChip,
    ExternalSecurityGroupChip,
    FilterTextChip,
    EventCategoryChip,
    EventCategoryPreviewChip,
    EventAgentChip,
    DocumentTypeChip,
    EventGroupChip,
    DisabledChip,
    DefaultOptionChip,
    LookupTenantChip,
    SpaceChip,
    DefaultSpaceChip,
    EarlyAccessChip,
    NewFeatureChip,
    DeploymentActionChip,
    RunbookSnapshotPublishedChip,
    ProcessChip,
    WarningChip,
    DangerChip,
    DynamicEnvironmentChip,
};
