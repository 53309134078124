import { isEqual } from "lodash";
import * as React from "react";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import ErrorPanel from "~/components/ErrorPanel";
import { Navbar } from "~/components/Navbar";
import { IconsDevToolsTabRegistration } from "~/components/StandardLayout/IconsDevToolsTabRegistration";
import Sticky from "~/components/Sticky/Sticky";
import SystemMessagesBanner from "~/components/SystemMessagesBanner/SystemMessagesBanner";
import type { UnhandledErrorState } from "~/components/UnhandledError/reducers";
import { zIndexStickies } from "~/theme";
import { client } from "../../clientInstance";
import RootRoutes from "../RootRoutes";
import { FeaturesDevToolsTabRegistration } from "./FeaturesDevToolsTabRegistration";
import SpaceLoader from "./SpaceLoader";

interface StandardLayoutProps {
    versionText: string;
    unhandledError: UnhandledErrorState;
    onErrorClose(): void;
}

export default class StandardLayoutInternal extends React.Component<StandardLayoutProps> {
    // @Performance - HashRouter was causing re-renders when query-strings were changing.
    // We should only be re-rendering our layout if these top-level props change.
    shouldComponentUpdate(nextProps: StandardLayoutProps) {
        const versionTextChanged = nextProps.versionText !== this.props.versionText;
        const unhandledErrorChanged = !isEqual(nextProps.unhandledError, this.props.unhandledError);
        const onErrorCloseChanged = !isEqual(nextProps.onErrorClose, this.props.onErrorClose);
        if (versionTextChanged || unhandledErrorChanged || onErrorCloseChanged) {
            return true;
        }
        return false;
    }

    render() {
        const serverInfo = client.tryGetServerInformation();
        const version = serverInfo ? serverInfo.version : undefined;
        return (
            <div>
                <FeaturesDevToolsTabRegistration />
                <IconsDevToolsTabRegistration />
                <SpaceLoader
                    render={(spaceContext) => (
                        <React.Fragment>
                            <Sticky innerZ={zIndexStickies} enableTransforms={false}>
                                <div id="toppanel">
                                    <AnalyticLinkProvider location="Main Navigation">
                                        <Navbar spaceContext={spaceContext} />
                                    </AnalyticLinkProvider>
                                    {this.props.unhandledError && (
                                        <AnalyticLinkProvider location="Main Navigation Error">
                                            <ErrorPanel
                                                message={`An unexpected error occurred in Octopus v${version}: ${this.props.unhandledError.message}`}
                                                errors={this.props.unhandledError.errors}
                                                fullWidth={true}
                                                canClose={true}
                                                onErrorClose={this.onErrorClose}
                                            />
                                        </AnalyticLinkProvider>
                                    )}
                                    <SystemMessagesBanner />
                                </div>
                            </Sticky>

                            <RootRoutes spaceContext={spaceContext} />
                        </React.Fragment>
                    )}
                />
            </div>
        );
    }

    private onErrorClose = () => {
        this.props.onErrorClose();
    };
}
