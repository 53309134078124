/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import ClearIcon from "@material-ui/icons/Clear";
import cn from "classnames";
import React from "react";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon/index";
import styles from "~/areas/tasks/components/Task/taskqueue.module.less";
import type { TaskResource, TaskState } from "~/client/resources/index";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";

interface TaskQueueFilterProps {
    tasks: TaskResource[];
    isSelected: boolean;
    stateToFilter: TaskState | Interruption;
    selectState(state: TaskState | Interruption | null): void;
}

export enum Interruption {
    ManualIntervention = "Awaiting Manual Intervention",
}

export type TaskQueueFilterState = TaskState | Interruption | null;

export function filterStatePredicate(filterState: TaskState | Interruption | null): (t: TaskResource) => boolean {
    if (!filterState) {
        return (_) => true;
    }

    if (filterState === Interruption.ManualIntervention) {
        return (t) => t.HasPendingInterruptions;
    }

    return (t) => !t.HasPendingInterruptions && t.State === filterState;
}

export default (props: TaskQueueFilterProps) => {
    const { tasks, isSelected, stateToFilter, selectState } = props;

    const filteredTasks = tasks.filter(filterStatePredicate(stateToFilter));
    const count = filteredTasks.length;

    if (count === 0) {
        return null;
    }

    const sampleTask = filteredTasks[0];
    const taskIcon = <TaskStatusIcon smallIcon={true} item={sampleTask} iconOnly={true} />;
    const closeIcon = <ClearIcon />;

    const toggleState = () => selectState(isSelected ? null : stateToFilter);

    return (
        <ActionButton className={cn(styles.taskQueueFilter, isSelected ? styles.selected : null)} onClick={toggleState} type={ActionButtonType.Ternary} label="">
            <div className={cn(styles.filterContainer)}>
                {taskIcon}
                <span style={{ marginLeft: "0.25rem" }}>
                    {count} {stateToFilter}
                </span>
                {isSelected ? <span className={cn(styles.closeButton)}>{closeIcon}</span> : null}
            </div>
        </ActionButton>
    );
};
