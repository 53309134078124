/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { flatMap } from "lodash";
import type { SFC } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import type { FilteredVariable, ValueWithSource, VariableWithSource } from "~/areas/variables/VariableDisplayer";
import { mergeAndSortVariables, VariableDisplayer } from "~/areas/variables/VariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "~/areas/variables/convertVariableResourcesToVariablesWithSource";
import type { ChannelResource, DeploymentProcessResource, DeploymentTargetResource, EnvironmentResource, LifecycleResource, ProjectResource, ResourceCollection, TenantResource } from "~/client/resources";
import { Permission, TenantedDeploymentMode } from "~/client/resources";
import type { VariableResource } from "~/client/resources/variableResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import { SimpleList } from "~/components/List";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout, { SidebarSide } from "~/components/SidebarLayout/SidebarLayout";
import { Select } from "~/components/form";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import VariablePreviewSummary from "./VariablePreviewSummary";
import styles from "./style.module.less";

interface VariableListProps {
    variables: VariableResource[];
}

class VariableList extends SimpleList<VariableResource> {}

const Variables: SFC<VariableListProps> = (props) => {
    const onRow = (variable: VariableResource) => [<b>{variable.Name}</b>, variable.Value];
    return <VariableList items={props.variables} onRow={onRow} empty={<span>No variables have been added</span>} />;
};

Variables.displayName = "Variables";

interface VariablePreviewState extends DataBaseComponentState {
    variableNameFilter: string;
    environmentId?: string;
    targetId?: string;
    channelId?: string;
    tenantId?: string;
    actionId?: string;
    machineId?: string;
    project?: ProjectResource;
    environments?: Item[];
    targets?: DeploymentTargetResource[];
    channels?: Item[];
    tenants?: Item[];
    actions?: Item[];
    machines?: Item[];
    variables?: VariableSetResource;
    showOctopus: boolean;
    filterEmptyValues: boolean;
}

type VariablePreviewProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;

class VariablePreviewInternal extends DataBaseComponent<VariablePreviewProps, VariablePreviewState> {
    constructor(props: VariablePreviewProps) {
        super(props);
        this.state = {
            showOctopus: false,
            filterEmptyValues: false,
            variableNameFilter: null!,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps: VariablePreviewProps) {
        if (prevProps.projectContext.state.gitRef !== this.props.projectContext.state.gitRef) {
            await this.loadVariables();
        }
    }

    render() {
        if (!this.state.variables || !this.state.project) {
            return <PaperLayout title={"Deployment Variable Preview"} busy={true} fullWidth={true} errors={this.errors} />;
        }

        const summary = (
            <VariablePreviewSummary
                tenantedDeploymentMode={this.state.project && this.state.project.TenantedDeploymentMode}
                environments={this.state.environments!}
                environmentId={this.state.environmentId!}
                tenants={this.state.tenants!}
                tenantId={this.state.tenantId!}
                actions={this.state.actions!}
                actionId={this.state.actionId!}
                channels={this.state.channels!}
                channelId={this.state.channelId!}
                machines={this.state.machines!}
                machineId={this.state.machineId!}
            />
        );

        const sidebar = this.state.variables && (
            <div className={styles.scopeRow}>
                <h4>Select Deployment Scenario</h4>
                <Select label="Environment" items={this.state.environments!} allowClear={false} value={this.state.environmentId} onChange={this.handleEnvironmentChanged} />
                {this.state.tenants && this.state.tenants.length > 0 && (
                    <Select
                        label="Tenant"
                        items={this.state.tenants}
                        allowClear={this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted}
                        value={this.state.tenantId}
                        onChange={this.handleTenantChanged}
                        placeholder="Untenanted"
                    />
                )}
                <PermissionCheck permission={Permission.ProcessView} project={this.state.project.Id} wildcard={true}>
                    <Select label="Deployment step" items={this.state.actions!} allowClear={false} value={this.state.actionId} onChange={this.handleActionChanged} />
                    {this.state.channels && this.state.channels.length > 1 && <Select label="Channel" items={this.state.channels} allowClear={false} value={this.state.channelId} onChange={this.handleChannelChanged} />}
                </PermissionCheck>
                <Select label="Deployment target" items={this.state.machines!} allowFilter={true} allowClear={true} value={this.state.machineId} onChange={this.handleMachineChanged} placeholder="Select deployment target" />
                <div className={styles.showAll}>
                    <Checkbox label="Show system variables" value={this.state.showOctopus} onChange={this.handleShowOctopusChanged} />
                    <Checkbox label="Filter empty values" value={this.state.filterEmptyValues} onChange={this.handleFilterEmptyValuesChanged} />
                </div>
            </div>
        );

        const variableSections = mergeAndSortVariables(this.getVariables(), this.state.variables.ScopeValues);
        const filteredVariableSections = variableSections.map((v) => {
            return this.mapToFilteredVariable(v);
        });

        return (
            <PaperLayout title={"Deployment Variable Preview"} breadcrumbTitle={this.state.project.Name} busy={this.state.busy} fullWidth={true} errors={this.errors} statusSection={<ProjectStatus doBusyTask={this.doBusyTask} />}>
                <div className={styles.filterTextBox}>
                    <FilterSearchBox placeholder="By variable name" value={this.state.variableNameFilter} onChange={this.handleFilterChanged} />
                </div>

                {summary}

                <SidebarLayout sideBar={sidebar} side={SidebarSide.Left} extendContentToEdges={true} extendSidebarToEdges={true} hideTopDivider={true} hideSidebarDivider={false} overflowXHidden={true}>
                    <VariableDisplayer
                        availableScopes={this.state.variables && this.state.variables.ScopeValues}
                        variableSections={[filteredVariableSections]}
                        doBusyTask={this.doBusyTask}
                        hideSource={true}
                        hideScope={false}
                        isDisplayingFullWidth={false}
                    />
                </SidebarLayout>
            </PaperLayout>
        );
    }

    private mapToFilteredVariable(variable: VariableWithSource): FilteredVariable {
        return {
            name: variable.name,
            variableMessages: null!,
            values: variable.values.map((x) => {
                const value = x as ValueWithSource;
                return {
                    ...value,
                    messages: null!,
                };
            }),
        };
    }

    private handleFilterChanged = (variableNameFilter: string) => {
        this.setState({ variableNameFilter });
    };

    private async loadEnvironmentsFromLifecycle(lifecycle: LifecycleResource) {
        const environmentIds = flatMap(lifecycle.Phases, (phase) => {
            return phase.AutomaticDeploymentTargets.concat(phase.OptionalDeploymentTargets);
        });

        const args = environmentIds.length === 0 ? undefined : { ids: environmentIds };
        const environments = await (isAllowed({ permission: Permission.EnvironmentView, environment: "*" }) ? repository.Environments.all(args) : Promise.resolve<EnvironmentResource[]>([]));

        return environments;
    }

    private async loadData() {
        await this.doBusyTask(
            async () => {
                const { model: project, projectContextRepository } = this.props.projectContext.state;
                const gitRef = this.props.projectContext.state.gitRef?.CanonicalName;

                const hasProcessViewPermissions = isAllowed({
                    permission: Permission.ProcessView,
                    project: project.Id,
                    tenant: "*",
                });

                const [deploymentProcess, channels, lifecycle, tenants] = await Promise.all([
                    hasProcessViewPermissions ? projectContextRepository.DeploymentProcesses.get() : Promise.resolve<DeploymentProcessResource>(null!),
                    hasProcessViewPermissions ? repository.Projects.getChannels(project, 0, 1000) : Promise.resolve<ResourceCollection<ChannelResource>>(null!),
                    repository.Lifecycles.get(project.LifecycleId),
                    isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ projectId: project.Id }) : Promise.resolve<TenantResource[]>([]),
                ]);

                const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);

                const tenantId = tenants && tenants.length > 0 ? tenants[0].Id : null!;
                const environmentId = environments && environments.length > 0 ? environments[0].Id : null!;
                const actionId = deploymentProcess && deploymentProcess.Steps.length > 0 ? deploymentProcess.Steps[0].Actions[0].Id : null!;
                const channelId = channels && channels.Items.length > 0 ? channels.Items[0].Id : null!;

                const variablesPromise = repository.Variables.preview(project.Id, null!, actionId, environmentId, null!, channelId, tenantId, gitRef);
                const machinesPromise = this.loadMachinesForEnvironment(environmentId!);
                const [variables, machines] = await Promise.all([variablesPromise, machinesPromise]);
                const actions = variables.ScopeValues.Actions.map((a) => ({ text: a.Name, value: a.Id }));

                this.setState({
                    project,
                    environments: environments.map((e) => ({ text: e.Name, value: e.Id })),
                    environmentId,
                    channels: channels.Items.map((c) => ({ text: c.Name, value: c.Id })),
                    channelId,
                    tenants: tenants.map((t) => ({ text: t.Name, value: t.Id })),
                    tenantId,
                    machines,
                    actions,
                    actionId,
                    variables,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    private loadMachinesForEnvironment = async (environmentId: string) => {
        if (!environmentId || !isAllowed({ permission: Permission.MachineView, wildcard: true })) {
            return [];
        }
        const machines = await repository.Machines.list({ environmentIds: environmentId, take: 999 });
        return machines.Items.map((m) => ({ text: m.Name, value: m.Id }));
    };

    private handleEnvironmentChanged = async (environmentId: string | undefined) => {
        await this.doBusyTask(async () => {
            const machines = await this.loadMachinesForEnvironment(environmentId!);
            const machine = machines.find((m) => m.value === this.state.machineId)!;
            const machineId = machine ? machine.value : null!;
            this.setState(
                {
                    environmentId,
                    machines,
                    machineId,
                },
                () => this.loadVariables()
            );
        });
    };

    private handleMachineChanged = (machineId: string | undefined) => {
        this.setState({ machineId }, () => this.loadVariables());
    };

    private handleChannelChanged = async (channelId: string | undefined) => {
        await this.doBusyTask(async () => {
            const channel = await repository.Channels.getFromProject(this.state.project!, channelId!);
            const lifecycle = await repository.Lifecycles.get(channel.LifecycleId!);
            const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);
            // clear the existing environment selection if it's no longer available in the drop down
            const environmentId = environments.some((x) => x.Id === this.state.environmentId) ? this.state.environmentId : null!;
            this.setState(
                {
                    environments: environments.map((e) => ({ text: e.Name, value: e.Id })),
                    channelId,
                    environmentId,
                },
                () => this.loadVariables()
            );
        });
    };

    private handleTenantChanged = (tenantId: string | undefined) => {
        this.setState({ tenantId }, () => this.loadVariables());
    };

    private handleActionChanged = (actionId: string | undefined) => {
        this.setState({ actionId }, () => this.loadVariables());
    };

    private handleFilterEmptyValuesChanged = (showEmptyValues: boolean) => {
        this.setState({ filterEmptyValues: showEmptyValues });
    };

    private handleShowOctopusChanged = (showOctopus: boolean) => {
        this.setState({ showOctopus });
    };

    private getVariables = () => {
        function isEmpty(value?: string | null) {
            return value ? false : true;
        }

        if (!this.state.variables) {
            return [];
        }

        // We don't show the source on the variable preview page. We use this source
        // in the call to convertVariableResourcesToVariablesWithSource so that it
        // has _a_ source, but it's setting everything to project which is not correct.
        const source = {
            projectName: this.state.project!.Name!,
            projectId: this.state.project!.Id!,
        };

        const filtered = this.state.variables.Variables.filter((v) => !this.state.filterEmptyValues || isEmpty(v.Value))
            .filter((v) => this.state.showOctopus || !v.Name.startsWith("Octopus."))
            .filter((v) => !this.state.variableNameFilter || this.state.variableNameFilter.length === 0 || v.Name.toLowerCase().includes(this.state.variableNameFilter.toLowerCase()));
        return convertVariableResourcesToVariablesWithSource(filtered, source);
    };

    private loadVariables = async () => {
        const gitRef = this.props.projectContext.state.gitRef?.CanonicalName;

        await this.doBusyTask(
            async () => {
                const variables = await repository.Variables.preview(this.state.project!.Id, null!, this.state.actionId!, this.state.environmentId!, this.state.machineId!, this.state.channelId!, this.state.tenantId!, gitRef);
                const actions = variables.ScopeValues.Actions.map((a) => ({ text: a.Name, value: a.Id }));
                this.setState({
                    variables,
                    actions,
                });
            },
            { timeOperationOptions: timeOperationOptions.forRefresh() }
        );
    };
}

export default withProjectContext(VariablePreviewInternal);
