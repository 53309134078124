import type { IconSvgResource, IconMetadataResource, IconApiResource } from "~/client/resources";
import type { Client } from "../client";

export class IconRepository {
    constructor(private readonly client: Client) {}

    async getIcons(): Promise<IconSvgResource[]> {
        const allIconsLink = this.client.getSystemLink((g) => g.LogoIcons);
        const data: IconApiResource = await this.client.get<IconApiResource>(allIconsLink);
        return data.icons;
    }

    async getIconMetadata(): Promise<IconMetadataResource> {
        const iconCategoriesLink = this.client.getSystemLink((g) => g.LogoIconCategories);
        return await this.client.get<IconMetadataResource>(iconCategoriesLink);
    }
}
