import React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { DialogWithHelpPanelFrame } from "~/components/DialogWithHelpPanel/DialogWithHelpPanel";
import CreateEnvironmentsDialogLayout from "~/components/ProjectBasedActivation/CreateEnvironmentsDialogLayout";
import addEnvironmentsImage from "./assets/img-environments.svg";

interface CreateEnvironmentsPageProps {
    close: () => void;
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchAction: AnalyticActionDispatcher;
}

export class CreateEnvironmentsPage extends DataBaseComponent<CreateEnvironmentsPageProps, {}> {
    private async saveEnvironment(name: string, order: number) {
        return await repository.Environments.create(
            {
                Name: name,
                Description: "",
                UseGuidedFailure: false,
                AllowDynamicInfrastructure: true,
                SortOrder: order,
                ExtensionSettings: [],
            },
            {}
        );
    }

    private async saveEnvironments(names: Array<string>) {
        this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Add,
                resource: "Environment",
                data: {
                    target: names.join(";"),
                },
            };

            await this.props.trackAction("First Environments Created", ev, async (cb: AnalyticErrorCallback) => {
                await Promise.all(names.map((name, index) => this.saveEnvironment(name, index)));
            });
        });
    }

    render() {
        const { close } = this.props;
        const helpPanelContent = (
            <span>
                Octopus organizes the servers and services where you deploy your software into <strong>environments</strong>. They represent the stages of your deployment pipeline, known in Octopus as a Lifecycle.
            </span>
        );

        const onToggleHelp = () => {
            this.props.dispatchAction("Toggle Create Environments Dialog Help Section", { action: Action.Toggle, resource: "Project" });
        };

        return (
            <DialogWithHelpPanelFrame helpPanelImage={addEnvironmentsImage} helpPanelContent={helpPanelContent} helpPanelAltImageText={"Create Environments Image"} onToggleHelp={onToggleHelp}>
                <CreateEnvironmentsDialogLayout
                    onSkipClick={() => {
                        this.props.dispatchAction("Skip First Environments Step", { action: Action.Cancel, resource: "Environment" });
                        close();
                        return true;
                    }}
                    onSaveClick={async (environments: Array<string>) => {
                        await this.saveEnvironments(environments);
                        close();
                        return true;
                    }}
                />
            </DialogWithHelpPanelFrame>
        );
    }
}
