/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type { ReferenceDataItem } from "~/client/resources/referenceDataItem";
import { ChipIcon, DeploymentActionPackageChip, MissingChip } from "~/components/Chips";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import type { DeploymentActionPackageResource } from "../../client/resources/deploymentActionPackageResource";
import { displayName } from "../../client/resources/deploymentActionPackageResource";
import Lookup from "../Lookup";
import { MultiSelect } from "./MultiSelect";

interface DeploymentActionPackageMultiSelectProps extends FormFieldProps<string[]> {
    items: DeploymentActionPackageReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

export class DeploymentActionPackageReferenceDataItem implements ReferenceDataItem {
    Id: string;
    Name: string;
    ActionPackage: DeploymentActionPackageResource;

    constructor(actionPackage: DeploymentActionPackageResource, id: string) {
        this.Id = id;
        this.Name = displayName(actionPackage);
        this.ActionPackage = actionPackage;
    }
}

const DeploymentActionPackageTypedMultiSelect = MultiSelect<DeploymentActionPackageReferenceDataItem>();

const DeploymentActionPackageMultiSelect: React.FC<DeploymentActionPackageMultiSelectProps> = (props) => {
    const chipRenderer = (r: DeploymentActionPackageReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element: ReferenceDataItem) => element.Id}
                render={(item: ReferenceDataItem) => {
                    const deploymentActionPackageReferenceItem = item as DeploymentActionPackageReferenceDataItem;
                    return <DeploymentActionPackageChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} actionPackage={deploymentActionPackageReferenceItem.ActionPackage} />;
                }}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.StepAction} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <DeploymentActionPackageTypedMultiSelect fieldName="package steps" renderChip={chipRenderer} {...props} />;
};

export default DeploymentActionPackageMultiSelect;
