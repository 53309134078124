/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import { Fade } from "@material-ui/core";
import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { loadLibraryVariableSetVariables } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectContextProps, ProjectContextState } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { ProjectContextRepository } from "~/client/repositories/projectContextRepository";
import type { GitBranchResource, IProcessResource, ModifyDeploymentProcessCommand, ProjectResource } from "~/client/resources";
import { canCommitTo, GetPrimaryPackageReference, HasVariablesInGit, isProtectedBranch, Permission, ProcessType } from "~/client/resources";
import type { ActionTemplateSearchResource } from "~/client/resources/actionTemplateSearchResource";
import type { FeedResource } from "~/client/resources/feedResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import { client, repository, session } from "~/clientInstance";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import BaseComponent from "~/components/BaseComponent/index";
import BusyIndicator from "~/components/BusyIndicator";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { NoResults } from "~/components/NoResults/NoResults";
import { Section } from "~/components/Section/Section";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import PageTitleHelper from "~/utils/PageTitleHelper";
import { noOp } from "~/utils/noOp";
import { hasPermission } from "../../../../components/PermissionCheck/PermissionCheck";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import type { RunbookContextProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import type { GetCommitButtonProps } from "../VersionControl/CommitButton";
import { GetCommitButton } from "../VersionControl/CommitButton";
import {
    assembleExistingAction,
    assembleNewAction,
    assembleParentStep,
    getCommonOverflowMenuItems,
    isRunOnServerOrWorkerPool,
    isVersionControlledProcess,
    loadAvailableWorkerPools,
    MergedProcessResult,
    mergeProcesses,
    NoMergeRequiredResult,
    processScopedEditPermission,
    whereToRun,
} from "./Common/CommonProcessHelpers";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import type { ProcessContextProps } from "./Contexts/ProcessContext";
import { loadProcess, useProcessContext } from "./Contexts/ProcessContext";
import type { BoundErrorActionsType, ProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { ProcessErrorActions, useProcessErrorActions, useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import type { ProcessQueryStringContextProps } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { BoundWarningActionsType } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessWarningActions, useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { getAllActions, hasSteps } from "./Contexts/processModelSelectors";
import { EnhancedProcessContextFormPaperLayout } from "./CustomPaperLayouts/ProcessContextFormPaperLayout";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
import { EnhancedActionTemplateSelectionPage, EnhancedProcessActionDetailsPage, EnhancedProcessParentStepDetailsPage } from "./Pages";
import ProcessSidebarLayout from "./ProcessSidebarLayout";
import type { ProcessStepsLayoutLoaderLookupData } from "./ProcessStepsLayoutLoader";
import type { ProcessStepActionState, ProcessStepLookupState } from "./ProcessStepsLayoutTypes";
import ProcessesMergedDialog from "./ProcessesMergedDialog";
import type { AssembledAction, ProcessFilter, StoredAction, StoredStep } from "./types";
import { EnvironmentOption, ExecutionLocation } from "./types";

export enum ProcessPageIntent {
    Unknown = "Unknown",
    ChooseStepTemplates = "ChooseStepTemplates",
    ChooseChildStepTemplates = "ChooseChildStepTemplates",
    CreateNewAction = "CreateNewAction",
    CreateNewChildAction = "CreateNewChildAction",
    ViewAction = "ViewAction",
    ViewParentStep = "ViewParentStep",
}

export function isIntentToCreateNew(intent: ProcessPageIntent) {
    return intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction;
}

function intentFromFilter(filter: ProcessFilter): ProcessPageIntent {
    if (filter.new && filter.actionType) {
        if (filter.parentStepId) {
            return ProcessPageIntent.CreateNewChildAction;
        }
        return ProcessPageIntent.CreateNewAction;
    }
    if (filter.stepTemplates) {
        return ProcessPageIntent.ChooseStepTemplates;
    }
    if (filter.childStepTemplates && filter.parentStepId) {
        return ProcessPageIntent.ChooseChildStepTemplates;
    }
    if (filter.actionId) {
        return ProcessPageIntent.ViewAction;
    }
    if (filter.parentStepId) {
        return ProcessPageIntent.ViewParentStep;
    }
    return ProcessPageIntent.Unknown;
}

export interface ProcessStepActionData {
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    step: StoredStep;
    action: StoredAction;
}

export interface ProcessParentStepData {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    errors?: Errors | undefined;
}

interface ProcessStepsLayoutProps {
    lookups: ProcessStepsLayoutLoaderLookupData;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
}

interface ProcessPageState {
    actionData: ProcessStepActionData | null;
    parentStepData: ProcessParentStepData | null;
    redirectTo?: string;
    commitMessage: CommitMessageWithDetails;
    currentActionName: string;
    currentStepName: string;
    disableDirtyFormChecking?: boolean;
    project: ProjectResource | null;
}

type ProcessStepsLayoutInternalProps = {
    lookups: ProcessStepsLayoutLoaderLookupData;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
    projectContext: ProjectContextProps;
    processContext: ProcessContextProps;
    runbookContext: RunbookContextProps | undefined;
    processErrorActions: BoundErrorActionsType;
    processErrorSelectors: ProcessErrorSelectors;
    processWarningActions: BoundWarningActionsType;
    processQueryStringContext: ProcessQueryStringContextProps;
    feeds: FeedResource[];
    actionTemplates: ActionTemplateSearchResource[];
    trackAction: AnalyticTrackedActionDispatcher;
    branchProtectionsAreEnabled: boolean;
};

const defaultCommitMessage = "Update deployment process";

const loadingIndicatorForStep: JSX.Element = (
    <Section>
        <BusyIndicator inline={true} show={true} />
    </Section>
);

function checkCloudConnectionsPermissionsForUser(): boolean {
    const requiredPermissions = [Permission.VariableView, Permission.VariableViewUnscoped, Permission.VariableEditUnscoped, Permission.LibraryVariableSetView];
    if (requiredPermissions.every((permission) => hasPermission(permission))) return true;
    return false;
}

export async function getProjectVariables(projectContextState: ProjectContextState): Promise<VariableSetResource | undefined> {
    if (!checkCloudConnectionsPermissionsForUser()) return undefined;
    const project = projectContextState.model;

    const variableSet = await projectContextState.projectContextRepository.Variables.get();

    if (HasVariablesInGit(project.PersistenceSettings)) {
        //Not sure if this is how I should deal with this
        const sensitiveVariableSet = await projectContextState.projectContextRepository.Variables.getSensitive();
        variableSet.Variables = variableSet.Variables.concat(sensitiveVariableSet.Variables);
        return variableSet;
    } else {
        return variableSet;
    }
}

export async function getLibraryVariables(projectContextState: ProjectContextState): Promise<LoadedLibraryVariableSets[] | undefined> {
    if (!checkCloudConnectionsPermissionsForUser()) return undefined;
    return await loadLibraryVariableSetVariables(projectContextState.model);
}

// This class is based off BaseComponent because it is required to ensure the asynchronous calls
// to setState don't result in a console error like "Warning: Can't perform a React state update on an unmounted component. ..."
class ProcessStepsLayout extends BaseComponent<ProcessStepsLayoutInternalProps, ProcessPageState> {
    private isAddingStep = false;
    private isLoadingDataForActionId: string | undefined = undefined;
    private isLoadingDataForParentStepId: string | undefined = undefined;

    private openCommitDialog?: () => void;

    constructor(props: ProcessStepsLayoutInternalProps) {
        super(props);
        this.state = {
            actionData: null,
            parentStepData: null,
            commitMessage: { summary: "", details: "" },
            currentActionName: "",
            currentStepName: "",
            project: null,
        };
    }

    async componentDidMount() {
        if (!session.featureToggles?.includes("ProjectBasedActivationFeatureToggle")) return;

        await this.props.doBusyTask(async () => {
            const { model: project } = this.props.projectContext.state;

            this.setState({
                project,
            });
        });
    }

    // markse: @Performance - I've tried splitting this up into a shouldComponentUpdate and having the guard conditions in there to save renders. Doing this does
    // save some re-renders, but makes the code much less readable. I decided to let readability win here, in order to get DX adoption. But splitting
    // this up is an option if we want to investigate some perf gains.
    async componentDidUpdate() {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return;
        }

        const queryFilter = this.props.processQueryStringContext.state.queryFilter;
        if (!this.canSafelyNavigateToFilter(queryFilter)) {
            logger.warn("Failed to find action by ID in context, attempting to find action based on name.");

            // Something about the ID has likely changed server-side.
            // We'll attempt a lookup by name, then redirect to the ID.
            const foundByName = this.needToRedirectToStepBasedOnName(
                queryFilter,
                this.state.currentStepName,
                this.state.currentActionName,
                (actionId: string) => {
                    this.props.processQueryStringContext.actions.showProcessAction(actionId);
                },
                (parentStepId: string) => {
                    this.props.processQueryStringContext.actions.showProcessParentStep(parentStepId);
                }
            );
            if (foundByName) return;

            logger.warn("Failed to find action by ID or Name, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return;
        }

        const currentIntent = intentFromFilter(queryFilter);
        switch (currentIntent) {
            case ProcessPageIntent.ViewAction:
                {
                    const { actionId, actionType, templateId } = queryFilter;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForActionId === actionId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = this.filtersAreAlignedWithActionData(actionId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }

                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForActionId = actionId;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                        } finally {
                            this.isLoadingDataForActionId = undefined;

                            // Set the current step/action name (as these get a default when we assembleNewAction).
                            let currentStepName = this.state.currentStepName;
                            let currentActionName = this.state.currentActionName;
                            if (actionData) {
                                const actionId = actionData.action.Id;
                                const { selectors } = this.props.processContext;
                                const action = selectors.getActionById(actionId);
                                const step = selectors.getStepById(action.ParentId);
                                currentStepName = step.Name;
                                currentActionName = action.Name;
                            }

                            this.setState({ actionData, parentStepData: null, currentStepName, currentActionName });
                        }
                    });
                }
                break;
            case ProcessPageIntent.ViewParentStep:
                {
                    const parentStepId = queryFilter.parentStepId;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForParentStepId === parentStepId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = !parentStepId || this.filtersAreAlignedWithParentStepData(parentStepId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }

                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForParentStepId = parentStepId;
                        let parentStepData: ProcessParentStepData | null = null;
                        try {
                            parentStepData = await this.loadParentStepData();
                        } finally {
                            this.isLoadingDataForParentStepId = undefined;
                            this.setState({ parentStepData, actionData: null });
                        }
                    });
                }
                break;
            case ProcessPageIntent.CreateNewChildAction:
            case ProcessPageIntent.CreateNewAction:
                {
                    if (this.isAddingStep) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isAddingStep = true;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            const { actionId, actionType, templateId } = queryFilter;
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                            if (actionData && actionData.step && actionData.action) {
                                const step = actionData.step;
                                const action = actionData.action;
                                if (currentIntent === ProcessPageIntent.CreateNewAction) {
                                    this.props.processContext.actions.addStep(step, action);
                                } else if (currentIntent === ProcessPageIntent.CreateNewChildAction && queryFilter.parentStepId) {
                                    this.props.processContext.actions.addChildAction(queryFilter.parentStepId, actionData.action);
                                }
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            } else {
                                throw Error("Failed to create step or action.");
                            }
                        } finally {
                            this.isAddingStep = false;
                            if (actionData && actionData.action) {
                                const action = actionData.action;
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            } else {
                                this.setState({ parentStepData: null, actionData: null });
                            }
                        }
                    });
                }
                break;
        }
    }

    filtersAreAlignedWithActionData = (actionId: string | undefined): boolean => {
        const { actionData: currentActionData } = this.state;
        return !!actionId && !!currentActionData && currentActionData.action.Id === actionId;
    };

    filtersAreAlignedWithParentStepData = (parentStepId: string): boolean => {
        const { parentStepData: currentParentStepData } = this.state;
        return !!parentStepId && !!currentParentStepData && currentParentStepData.step.Id === parentStepId;
    };

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const processContext = this.props.processContext;
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        const { selectors, actions } = processContext;
        const processType = selectors.getProcessType();
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, processType);
        const actionLabel = isVersionControlled ? "Commit" : "Save";

        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData() || !this.canSafelyNavigateToFilter(this.props.processQueryStringContext.state.queryFilter)) {
            return (
                <EnhancedProcessContextFormPaperLayout
                    disableDirtyFormChecking={this.state.disableDirtyFormChecking}
                    busy={true}
                    doBusyTask={this.props.doBusyTask}
                    disableAnimations={true}
                    model={undefined}
                    cleanModel={undefined}
                    onSaveClick={noOp}
                    saveButtonLabel={actionLabel}
                />
            );
        }

        const runbook = this.props.runbookContext?.state?.runbook;
        const overflowMenuItems = getCommonOverflowMenuItems(
            projectContextRepository,
            project,
            this.props.projectContext.state.gitRef,
            runbook,
            processType,
            selectors,
            actions,
            this.props.projectContext.actions,
            this.props.processErrorActions,
            this.props.processWarningActions,
            this.redirectToList
        );

        const intent = intentFromFilter(this.props.processQueryStringContext.state.queryFilter);
        const filter = this.props.processQueryStringContext.state.queryFilter;
        let layout: React.ReactNode = null;
        if ((intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction) && filter.new && filter.actionType) {
            layout = <ProcessPaperLayout processType={processType} busy={true} />;
        } else if (intent === ProcessPageIntent.ChooseStepTemplates && filter.stepTemplates) {
            layout = this.renderActionTemplateSelectionPage();
        } else if (intent === ProcessPageIntent.ChooseChildStepTemplates && filter.childStepTemplates && filter.parentStepId) {
            layout = this.renderActionTemplateSelectionPage(filter.parentStepId);
        } else if (intent === ProcessPageIntent.ViewAction && filter.actionId) {
            if (filter.actionId === this.state.actionData?.action.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            } else {
                layout = loadingIndicatorForStep;
            }
        } else if (intent === ProcessPageIntent.ViewParentStep && filter.parentStepId) {
            if (filter.parentStepId === this.state.parentStepData?.step.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            } else {
                layout = loadingIndicatorForStep;
            }
        } else {
            logger.info("Failed to determine layout for intent {intent}. Assuming no results.", { intent });
            layout = (
                <>
                    <Section>
                        <Callout type={CalloutType.Information} title={"Step could not be found"}>
                            {project.IsVersionControlled ? (
                                <span>The requested step could not be found on this branch. Please select from the available steps or review your current branch selection.</span>
                            ) : (
                                <span>The requested step could not be found. Please select from the available steps.</span>
                            )}
                        </Callout>
                    </Section>
                    <NoResults />
                </>
            );
        }

        const innerLayout = (
            <ProcessSidebarLayout
                render={() => {
                    const animationReloadKey = !!filter.actionId ? filter.actionId : filter.parentStepId;
                    return (
                        <Fade in={true} mountOnEnter unmountOnExit key={animationReloadKey}>
                            <div>{layout}</div>
                        </Fade>
                    );
                }}
            />
        );

        return (
            <ProcessErrorActions>
                {(errorActions) => (
                    <ProcessWarningActions>
                        {(warningActions) => {
                            const saveProcess = async (isNavigationConfirmation: boolean, onSavedCallback: () => void, newBranch?: GitBranchResource, commitMessage?: CommitMessageWithDetails) => {
                                let isValid = true;
                                const cm = commitMessage ?? this.state.commitMessage;

                                if (isNavigationConfirmation && isVersionControlled && this.openCommitDialog) {
                                    await this.openCommitDialog();
                                } else {
                                    await this.props.doBusyTask(async () => {
                                        const allActions = getAllActions(this.props.processContext.state.model)();
                                        const toTemplate = (a: StoredAction) => {
                                            return this.props.actionTemplates.find((t) => t.Type == a.ActionType);
                                        };
                                        const ev: ActionEvent = {
                                            action: isVersionControlled ? Action.Commit : Action.Save,
                                            resource: this.props.processContext.state.processType === ProcessType.Runbook ? "Runbook" : "Deployment Process",
                                            data: { stepTemplate: JSON.stringify(allActions.map((a) => toTemplate(a)?.Name)) },
                                            isDefaultBranch: this.props.projectContext.state.isDefaultBranch,
                                            commitMessage: cm.summary.length > 0,
                                            isProtectedBranch: isVersionControlled ? isProtectedBranch(this.props.projectContext.state.gitRef) : undefined,
                                            commitBranch: isVersionControlled ? (newBranch ? "New branch" : "Same branch") : undefined,
                                        };

                                        await this.props.trackAction("Save Deployment Process", ev, async (cb: AnalyticErrorCallback) => {
                                            //TODO: this merge conflict logic can be moved into a side effect in the reducer if we
                                            if (!processContext.state.model.process?.Id) {
                                                throw Error("Failed to find processId");
                                            }

                                            //TODO: markse - Move this into an onSave method that we can test in isolation.
                                            const { cleanModelProcess: clientCleanProcessResource, modelProcess: clientProcessResource } = processContext.selectors.getProcessesForMerge();
                                            this.applyCommonLogicToProcessResource(clientProcessResource, processContext.selectors.getActionPlugin);
                                            this.applyCommonLogicToProcessResource(clientCleanProcessResource, processContext.selectors.getCleanActionPlugin);
                                            const branchRepository = newBranch ? new ProjectContextRepository(client, this.props.projectContext.state.model, newBranch) : projectContextRepository;

                                            const serverProcessResource = await loadProcess(branchRepository, processContext.selectors.getProcessType(), processContext.state.model.process.Id);
                                            const mergeResult = mergeProcesses(clientCleanProcessResource, clientProcessResource, serverProcessResource);

                                            const command: ModifyDeploymentProcessCommand = { ...mergeResult.value, Links: { ...serverProcessResource.Links } };
                                            command.ChangeDescription = getFormattedCommitMessage(cm, defaultCommitMessage);

                                            if (mergeResult instanceof NoMergeRequiredResult) {
                                                const result = await processContext.actions.saveOnServer(
                                                    branchRepository,
                                                    command,
                                                    (errors) => {
                                                        errorActions.setErrors(errors, processContext.selectors);
                                                        cb(errors);
                                                        isValid = false;
                                                        // The save action will give us errors only, clear any warnings.
                                                        warningActions.clearWarnings();
                                                    },
                                                    async () => {
                                                        errorActions.clearErrors();
                                                        warningActions.clearWarnings();
                                                        //all is well, let's update project context
                                                        await this.props.projectContext.actions.refreshModel();
                                                    }
                                                );

                                                if (result !== null) {
                                                    this.setState({ commitMessage: { summary: "", details: "" } });
                                                }
                                            } else if (mergeResult instanceof MergedProcessResult) {
                                                processContext.actions.conflictDetected(serverProcessResource, mergeResult.value);
                                            }
                                        });

                                        // Only update the project summary if the deployment process has changed
                                        if (this.props.processContext.state.processType !== ProcessType.Runbook) {
                                            await this.props.projectContext.actions.onProjectUpdated(this.props.projectContext.state.model, this.props.projectContext.state.gitRef);
                                        }

                                        if (onSavedCallback) {
                                            onSavedCallback();
                                        }
                                    });

                                    if (isValid && !hasSteps(this.props.processContext.state.model)()) {
                                        const redirectTo = routeLinks.project(this.props.projectContext.state.model.Slug).deployments.process.root;
                                        this.setState({ redirectTo });
                                    }

                                    return isValid;
                                }
                            };

                            const onNewBranchCreating = async (branchName: string, source?: string) => {
                                const ev: ActionEvent = {
                                    action: Action.Commit,
                                    resource: "Branch",
                                    data: { source: source ?? "Commit changes dialog" },
                                };

                                let newBranchResource: GitBranchResource | null = null;

                                await this.props.trackAction("Create branch", ev, async (cb: AnalyticErrorCallback) => {
                                    try {
                                        newBranchResource = await this.props.projectContext.state.projectContextRepository.Branches.createBranch(
                                            this.props.projectContext.state.model,
                                            branchName,
                                            this.props.projectContext.state.gitRef?.CanonicalName ?? ""
                                        );
                                    } catch (ex) {
                                        this.setState({ disableDirtyFormChecking: false });
                                        cb(ex);
                                        throw ex;
                                    }
                                });

                                if (newBranchResource) {
                                    this.setState({ disableDirtyFormChecking: true });
                                    if (await saveProcess(false, noOp, newBranchResource)) {
                                        this.props.projectContext.actions.changeGitRef(branchName);
                                    }
                                    this.setState({ disableDirtyFormChecking: false });
                                }
                            };

                            const commitButtonProps = { ...this.getCommitButtonProps(), onNewBranchCreating };

                            return (
                                <EnhancedProcessContextFormPaperLayout
                                    model={selectors.getModel()}
                                    cleanModel={selectors.getCleanModel()}
                                    busy={this.props.busy}
                                    doBusyTask={this.props.doBusyTask}
                                    errors={this.props.errors} // We have to pass errors here for our ConfirmNavigate to function correctly.
                                    hideErrors={true} // We have custom error handling for our process form.
                                    savePermission={{
                                        permission: processScopedEditPermission(processContext.selectors.getProcessType()),
                                        project: project.Id,
                                        wildcard: true,
                                    }}
                                    hideExpandAll={true} // We position these manually due to a custom process layout.
                                    saveButtonLabel={actionLabel}
                                    dirtyTrackingKey="Process"
                                    onSaveClick={saveProcess}
                                    overFlowActions={overflowMenuItems}
                                    disableScrollToActiveError={true} // We have custom error handling for our process form.
                                    disableAnimations={true}
                                    customPrimaryAction={isVersionControlled ? (primaryActionProps) => <GetCommitButton {...commitButtonProps} actionButtonProps={primaryActionProps} /> : undefined}
                                    saveButtonBusyLabel={isVersionControlled ? "Committing" : "Saving"}
                                    confirmNavigateSaveLabel={`${actionLabel} Changes` + (isVersionControlled ? "..." : "")}
                                    disableDirtyFormChecking={this.state.disableDirtyFormChecking}
                                    onCreateBranch={async (branchName) => {
                                        await onNewBranchCreating(branchName, "Branch switcher");
                                    }}
                                    statusSection={<ProjectStatus numberOfSteps={processContext.state.model.steps.allIds.length} doBusyTask={this.props.doBusyTask} />}
                                >
                                    <ProcessesMergedDialog
                                        open={selectors.isMerging() && !selectors.isMergeDialogClosed()}
                                        onClose={actions.mergeDialogClosed}
                                        onDiscard={actions.discardedChanges}
                                        onMerge={actions.mergedProcess}
                                        onAcceptClientChanges={actions.acceptedClientChanges}
                                    />
                                    {selectors.isProcessMerged() && (
                                        <Callout title="Action Required" type={CalloutType.Warning}>
                                            This process has been merged with the server process but has not been saved. Please review the process before saving.
                                        </Callout>
                                    )}
                                    {innerLayout}
                                </EnhancedProcessContextFormPaperLayout>
                            );
                        }}
                    </ProcessWarningActions>
                )}
            </ProcessErrorActions>
        );
    }

    private getCommitButtonProps(): Omit<GetCommitButtonProps, "actionButtonProps"> {
        return {
            project: this.props.projectContext.state.model,
            gitRef: this.props.projectContext.state.gitRef?.CanonicalName,
            canCommitToGitRef: !this.props.branchProtectionsAreEnabled || canCommitTo(this.props.projectContext.state.gitRef),
            defaultCommitMessage: defaultCommitMessage,
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: "Commit message for saving the deployment process",
            commitDetailsAccessibleName: "Commit details for saving the deployment process",
            commitButtonAccessibleName: "Commit changes to the deployment process",
            onInitializing: (openDialog) => (this.openCommitDialog = openDialog),
        };
    }

    private loadingIndicatorForStep = () => {
        return (
            <Section>
                <BusyIndicator inline={true} show={true} />
            </Section>
        );
    };

    private redirectToList = () => {
        const type = this.props.processContext.selectors.getProcessType();
        if (type === ProcessType.Deployment) {
            const redirectTo = routeLinks.project(this.props.projectContext.state.model.Slug).deployments.process.root;
            this.setState({ redirectTo });
        } else {
            const runbook = this.props.runbookContext?.state.runbook;
            if (runbook) {
                const redirectTo = routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbook(runbook.Id).runbookProcess.runbookProcess(runbook.RunbookProcessId).root;
                this.setState({ redirectTo });
            }
        }
    };

    private canSafelyNavigateToFilter(filter: ProcessFilter): boolean {
        // Check if this action actually exists in our context (the user may have refreshed the screen and not yet saved, so our filter and context are out of sync).

        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;
        if (actionId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetActionById(actionId)) {
            return false;
        }
        if (parentStepId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetStepById(parentStepId)) {
            return false;
        }
        return true;
    }

    private needToRedirectToStepBasedOnName(filter: ProcessFilter, currentStepName: string, currentActionName: string, onFoundAction: (actionId: string) => void, onFoundParentStep: (parentStepId: string) => void): boolean {
        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;

        if (actionId && currentActionName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToAction = this.props.processContext.selectors.tryGetActionByName(currentActionName);
            if (shouldRedirectToAction) {
                onFoundAction(shouldRedirectToAction.Id);
                return true;
            }
        }

        if (parentStepId && currentStepName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToParentStep = this.props.processContext.selectors.tryGetStepByName(currentStepName);
            if (shouldRedirectToParentStep) {
                onFoundParentStep(shouldRedirectToParentStep.Id);
                return true;
            }
        }

        return false;
    }

    // @Cleanup: markse - This was common logic we were previously applying at the point of saving a single action. Flagging for later review.
    // For multi-step editing, we now loop over all actions and apply to each. Now sure how I feel about this happening right at the point of save.
    private applyCommonLogicToProcessResource(process: IProcessResource, getPluginForAction: (actionId: string) => ActionPlugin) {
        const availableWorkerPools = loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary);

        process.Steps.forEach((step) => {
            step.Actions.forEach((action) => {
                const plugin = getPluginForAction(action.Id);
                const runOn = whereToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin, this.props.isBuiltInWorkerEnabled);
                if (runOn) {
                    if (!isRunOnServerOrWorkerPool(runOn)) {
                        action.Container = { FeedId: null, Image: null };
                    } else {
                        if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                            step.Properties["Octopus.Action.TargetRoles"] = "";
                        }
                        if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolId = null!;
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolVariable = null!;
                        }
                        action.Container = runOn.container;
                    }
                }

                if (!action.Name || action.Name.length === 0) {
                    const primaryPackage = GetPrimaryPackageReference(action.Packages);
                    if (primaryPackage) {
                        action.Name = primaryPackage.PackageId;
                    }
                }
            });
        });
    }

    private loadActionData = async (actionId: string | undefined, actionType: string | undefined, templateId: string | undefined, intent: ProcessPageIntent): Promise<ProcessStepActionData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }

        let plugin: ActionPlugin | null = null;
        if (actionId) {
            plugin = this.props.processContext.selectors.getActionPlugin(actionId);
        } else if (actionType) {
            plugin = await pluginRegistry.getAction(actionType);
        }

        if (!plugin) {
            throw new Error("Failed to load plugin.");
        }

        const { feeds, actionTemplates } = this.props;
        const processContextSelectors = this.props.processContext.selectors;

        const isNew = isIntentToCreateNew(intent);
        let result: AssembledAction;
        if (isNew) {
            if (!actionType) {
                throw Error("No action type was provided");
            }
            result = await assembleNewAction(actionType, plugin, actionTemplates, templateId, feeds);
        } else {
            if (!actionId) {
                throw Error("Missing action id");
            }
            result = assembleExistingAction(actionId, processContextSelectors, actionTemplates, feeds);
        }

        PageTitleHelper.setPageTitle(result.pageTitle);

        if (!result.action) {
            logger.error("Failed to load action data", { result });
            throw new Error("Expecting an action to exist.");
        }

        const stepLookups = await this.loadLookupData(result.action);
        const stepOther: ProcessStepActionState = {
            actionTypeName: result.actionTypeName,
            pageTitle: result.pageTitle,
            isBuiltInWorkerEnabled: this.props.isBuiltInWorkerEnabled,
            environmentOption: (result.action.Environments || []).length > 0 ? EnvironmentOption.Include : (result.action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            runOn: result.action && plugin ? whereToRun(!!result.step.Properties["Octopus.Action.TargetRoles"], result.action, stepLookups.availableWorkerPools, plugin, this.props.isBuiltInWorkerEnabled) : null,
        };

        return { stepLookups, stepOther, step: result.step, action: result.action };
    };

    private loadParentStepData = async (): Promise<ProcessParentStepData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }

        const { parentStepId } = this.props.processQueryStringContext.state.queryFilter;
        if (!parentStepId) {
            throw new Error("Failed to find parentStepId");
        }

        const processContextSelectors = this.props.processContext.selectors;
        const result = assembleParentStep(parentStepId, processContextSelectors);
        PageTitleHelper.setPageTitle(result.pageTitle);

        const stepLookups = await this.loadLookupData(null);
        const stepNumber = this.props.processContext.selectors.getStepNumber(result.step.Id);
        const isFirstStep = this.props.processContext.selectors.isFirstStep(result.step.Id);

        return { stepNumber: stepNumber.toString(), step: result.step, machineRoles: stepLookups.machineRoles, isFirstStep };
    };

    private hasLoadedContexts(): boolean {
        const processContextHasLoaded = this.props.processContext.selectors.hasValidProcess();
        const projectContextHasLoaded = !!this.props.projectContext.state.model;
        const processQueryStringContextHasLoaded = !!this.props.processQueryStringContext.state.queryFilter;
        return processContextHasLoaded && projectContextHasLoaded && processQueryStringContextHasLoaded;
    }

    private hasLoadedNecessaryLookupData(): boolean {
        const { actionTemplates, feeds } = this.props;
        return actionTemplates && actionTemplates.length > 0 && feeds && feeds.length > 0;
    }

    private async loadLookupData(action: StoredAction | null): Promise<ProcessStepLookupState> {
        let actionTemplate;
        const projectVariablesPromise = getProjectVariables(this.props.projectContext.state);
        const libraryVariableSetsPromise = getLibraryVariables(this.props.projectContext.state);

        if ((this.props.processQueryStringContext.state.queryFilter.templateId || (action && action.Properties["Octopus.Action.Template.Id"])) && !hasPermission(Permission.ActionTemplateView)) {
            actionTemplate = { type: "No Permission" } as const;
        } else if (this.props.processQueryStringContext.state.queryFilter.templateId) {
            actionTemplate = await repository.ActionTemplates.get(this.props.processQueryStringContext.state.queryFilter.templateId);
        } else if (action && action.Properties["Octopus.Action.Template.Id"]) {
            actionTemplate = await repository.ActionTemplates.get(action.Properties["Octopus.Action.Template.Id"].toString());
        } else {
            actionTemplate = null;
        }

        const projectVariables = await projectVariablesPromise;
        const libraryVariableSets = await libraryVariableSetsPromise;

        const lookups: ProcessStepLookupState = {
            environments: Object.values(this.props.lookups.environmentsById),
            machineRoles: this.props.lookups.machineRoles,
            availableWorkerPools: loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary),
            tagIndex: this.props.lookups.tagIndex,
            actionTemplate,
            channels: this.props.lookups.channelsById ? Object.values(this.props.lookups.channelsById) : [],
            projectVariables,
            libraryVariableSets,
        };

        return lookups;
    }

    private renderProcessStepDetailsPage = (intent: ProcessPageIntent) => {
        const { processContext, processErrorSelectors } = this.props;
        const processType = processContext.selectors.getProcessType();

        if (this.state.actionData && this.state.actionData.stepOther && this.state.actionData.stepLookups && this.state.actionData.action) {
            const actionId = this.state.actionData.action.Id;
            const { selectors } = this.props.processContext;
            const isNew = isIntentToCreateNew(intent) || selectors.isNewAction(actionId);
            const errors = processErrorSelectors.getActionFieldErrors(actionId, selectors);

            // TODO: Review this pattern with frontend. Do not copy.
            // From hereon, we reference action/step via the selectors, and actionData.action is now essentially stale / out of sync.
            // This is an unfortunately side-effect of managing both CreateNewAction and ViewAction under the one layout. There is a
            // handoff between the two intentions, so we use actionData as a middle-man/dumping-ground until we have the necessary information
            // in context.
            const action = selectors.getActionById(actionId);
            const cleanAction = selectors.tryGetCleanActionById(actionId);
            const step = selectors.getStepById(action.ParentId);
            return (
                <EnhancedProcessActionDetailsPage
                    doBusyTask={this.props.doBusyTask}
                    step={step}
                    busy={this.props.busy}
                    action={action}
                    cleanAction={cleanAction}
                    setCurrentActionName={(actionName) => {
                        this.setState({ currentActionName: actionName });
                    }}
                    setCurrentStepName={(stepName) => {
                        this.setState({ currentStepName: stepName });
                    }}
                    stepLookups={this.state.actionData.stepLookups}
                    stepOther={this.state.actionData.stepOther}
                    processType={processType}
                    isNew={isNew}
                    errors={errors}
                    refreshStepLookups={async () => {
                        await this.props.doBusyTask(async () => {
                            // This line is required to ensure that all other setState()
                            // calls have been completed before we load lookup data.
                            await new Promise((resolve) => setTimeout(resolve));
                            const stepLookups = await this.loadLookupData(selectors.getActionById(actionId));
                            const actionData = this.state.actionData;
                            if (actionData) {
                                actionData.stepLookups = stepLookups;
                                this.setState({ actionData });
                            }
                        });
                    }}
                />
            );
        } else if (this.state.parentStepData && this.state.parentStepData.step) {
            const { selectors } = this.props.processContext;
            const stepId = this.state.parentStepData.step.Id;
            const step = selectors.getStepById(stepId);
            const cleanStep = selectors.tryGetCleanStepById(stepId);
            const isNew = isIntentToCreateNew(intent);
            const errors = processErrorSelectors.getStepFieldErrors(stepId);

            return (
                <EnhancedProcessParentStepDetailsPage
                    processType={processType}
                    stepNumber={this.state.parentStepData.stepNumber}
                    step={step}
                    cleanStep={cleanStep}
                    currentStepName={this.state.currentStepName}
                    setCurrentStepName={(stepName) => {
                        this.setState({ currentStepName: stepName });
                    }}
                    machineRoles={this.state.parentStepData.machineRoles}
                    isFirstStep={this.state.parentStepData.isFirstStep}
                    isNew={isNew}
                    errors={errors}
                />
            );
        }
        return <ProcessPaperLayout processType={processType} busy={true} />;
    };

    private renderActionTemplateSelectionPage = (parentStepId?: string) => {
        const { busy, errors } = this.props;
        const processType = this.props.processContext.selectors.getProcessType();
        return <EnhancedActionTemplateSelectionPage processType={processType} parentStepId={parentStepId} busy={busy} errors={errors} />;
    };
}

const EnhancedProcessStepsLayout: React.FC<ProcessStepsLayoutProps> = (props) => {
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const processErrorActions = useProcessErrorActions();
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningActions = useProcessWarningActions();
    const processQueryStringContext = useProcessQueryStringContext();
    const runbookContext = useOptionalRunbookContext();
    const feeds = useFeedsFromContext();
    const actionTemplates = useActionTemplatesFromContext();
    const trackAction = useAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const branchProtectionsAreEnabled = useEnabledFeatureToggle("BranchProtectionsFeatureToggle");

    return (
        <ProcessStepsLayout
            isBuiltInWorkerEnabled={props.isBuiltInWorkerEnabled}
            busy={props.busy}
            errors={props.errors}
            lookups={props.lookups}
            doBusyTask={props.doBusyTask}
            feeds={feeds}
            actionTemplates={actionTemplates}
            projectContext={projectContext}
            processContext={processContext}
            processErrorActions={processErrorActions}
            processWarningActions={processWarningActions}
            processQueryStringContext={processQueryStringContext}
            runbookContext={runbookContext}
            trackAction={trackAction}
            processErrorSelectors={processErrorSelectors}
            branchProtectionsAreEnabled={branchProtectionsAreEnabled}
        />
    );
};

export default EnhancedProcessStepsLayout;
