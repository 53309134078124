/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import * as React from "react";
import BuiltinActionTemplateList from "~/areas/library/components/ActionTemplates/BuiltinActionTemplateList";
import type { ActionTemplateSearchResource } from "~/client/resources/actionTemplateSearchResource";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "../../../../routeLinks";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";

interface BuiltinTemplatesState extends DataBaseComponentState {
    templates: ActionTemplateSearchResource[];
    isLoaded: boolean;
    filterText?: string;
}

class BuiltinTemplates extends DataBaseComponent<{}, BuiltinTemplatesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            templates: null!,
            isLoaded: false,
            filterText: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.search();
            this.setState({
                templates: templates.filter((t) => t.IsBuiltIn),
                isLoaded: true,
            });
        });
    }

    render() {
        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Choose Step Template" breadcrumbTitle={"Step Templates"} breadcrumbPath={routeLinks.library.stepTemplates.root} fullWidth={true} flatStyle={true} busy={this.state.busy} errors={this.errors}>
                    {this.state.isLoaded && (
                        <div>
                            <FilterSearchBox
                                placeholder="Filter by name, category or description..."
                                onChange={(value) => this.setState({ filterText: value.toLowerCase() })}
                                fullWidth={true}
                                autoFocus={true}
                                containerClassName={styles.filterSearchBoxContainer}
                            />
                            <BuiltinActionTemplateList
                                templates={this.state.templates}
                                onPostSelectionUrlRequested={(template) => routeLinks.library.stepTemplates.new(template as ActionTemplateSearchResource | string)}
                                filter={this.state.filterText}
                            />
                        </div>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }
}

export default BuiltinTemplates;
