import type { Client } from "~/client";
import type { ProjectExportRequest } from "~/client/resources/projectExportRequest";
import type { ProjectExportResponse } from "~/client/resources/projectExportResponse";
import type { ProjectImportFile } from "~/client/resources/projectImportFile";
import type { ProjectImportFileListResponse } from "~/client/resources/projectImportFileListResponse";
import type { ProjectImportPreviewRequest } from "~/client/resources/projectImportPreviewRequest";
import type { ProjectImportPreviewResponse } from "~/client/resources/projectImportPreviewResponse";
import type { ProjectImportRequest } from "~/client/resources/projectImportRequest";
import type { ProjectImportResponse } from "~/client/resources/projectImportResponse";

export class ImportExportActions {
    protected client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    export(exportRequest: ProjectExportRequest): Promise<ProjectExportResponse> {
        return this.client.post(this.client.getLink("ExportProjects"), exportRequest);
    }

    import(importRequest: ProjectImportRequest): Promise<ProjectImportResponse> {
        return this.client.post(this.client.getLink("ImportProjects"), importRequest);
    }

    preview(importRequest: ProjectImportPreviewRequest): Promise<ProjectImportPreviewResponse> {
        return this.client.post(this.client.getLink("ProjectImportPreview"), importRequest);
    }

    files(): Promise<ProjectImportFileListResponse> {
        return this.client.get(this.client.getLink("ProjectImportFiles"));
    }

    upload(pkg: File): Promise<ProjectImportFile> {
        const fd = new FormData();
        fd.append("fileToUpload", pkg);
        return this.client.post<ProjectImportFile>(this.client.getLink("ProjectImportFiles"), fd);
    }
}
