import { ConfigurationRepository } from "~/client/repositories/configurationRepository";
import type { AuditStreamConfigurationResource } from "~/client/resources/auditStreamConfigurationResource";
import type { AuditStreamLicenseCheckResource } from "~/client/resources/auditStreamLicenseCheckResource";
import type { Client } from "../client";

export class AuditStreamConfigurationRepository extends ConfigurationRepository<AuditStreamConfigurationResource> {
    constructor(client: Client) {
        super("AuditStreamConfiguration", client);
    }

    getAuditStreamLicenseCheck(): Promise<AuditStreamLicenseCheckResource> {
        return this.client.get("~/bff/audit-stream/license-check");
    }

    async requestTrial() {
        return await this.client.post("~/bff/audit-stream/request-audit-stream-trial");
    }
}
