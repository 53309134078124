import type { NavigationBarItemData } from "@octopusdeploy/design-system-components";
import { NavigationBar } from "@octopusdeploy/design-system-components";
import type { History, Location } from "history";
import { createLocation } from "history";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { client } from "~/clientInstance";
import type { MenuNode, MenuNodeItem } from "../LinksMenu/MenuNode";
import { isMenuItem } from "../LinksMenu/MenuNode";
import { isUrlActive } from "../Navigation/isUrlActive";
import { resolveStringPathWithSpaceId } from "../Navigation/resolvePathWithSpaceId";

interface DesignSystemNavigationBarProps {
    items: MenuNode[];
}

export function NavigationBarUplift({ items }: DesignSystemNavigationBarProps) {
    const history = useHistory();
    const location = useLocation();
    return <NavigationBar items={getNavigationItemsForDesignSystem(items, history, location)} />;
}

function getNavigationItemsForDesignSystem(menuNodes: MenuNode[], history: History<unknown>, location: Location<unknown>) {
    // TODO: Currently the NavigationBar does not support menu groups
    const menuItems: MenuNodeItem[] = menuNodes.filter(isMenuItem);

    return menuItems.map((menuItem) => {
        const currentUrl = location.pathname;
        const menuItemUrl = resolveStringPathWithSpaceId(menuItem.url, client.spaceId);
        const menuItemHref = history.createHref(createLocation(menuItemUrl));

        const isActive = isUrlActive(currentUrl, client.spaceId, menuItemUrl, menuItem.exact);

        const navigationItem: NavigationBarItemData = {
            label: menuItem.text,
            href: menuItemHref,
            isActive,
        };

        if (menuItem.accessoryView) {
            navigationItem.dropdown = {
                accessibleName: `${menuItem.text} dropdown`,
            };
        }

        return navigationItem;
    });
}
